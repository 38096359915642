import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import ToggleSwitch from "../ToggleSwitch";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface PermissionModalProps {
  show: any;
  clickHandler: any;
  userIds: string;
  userName: string;
  userData: any;
  getUserList: () => Promise<void>;
  setPermission: Dispatch<SetStateAction<boolean>>;
}

const PermissionModal = ({
  setPermission,
  show,
  clickHandler,
  userIds,
  userName,
  userData,
  getUserList,
}: PermissionModalProps) => {
  const [permissionData, setPermissionData] = useState({
    allowMultilogin: false,
    betAllow: true,
    fancybetAloow: true,
    userLock: false,
    closeUserAccount: false,
    sportSetting: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [loginPasswordError, setLoginPasswordError] = useState<string>("");
  const [updateData, setUpdateData] = useState<string>("");

  const updatePermissionData = (
    field: keyof typeof permissionData,
    value: boolean
  ) => {
    setPermissionData((prevData) => ({ ...prevData, [field]: value }));
  };

  const getMultiLogin = async () => {
    const { response } = await authServices.getMultiLogin({ user_id: userIds });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData("allowMultilogin", !permissionData.allowMultilogin);
      getUserList();
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const getBetLock = async (password: string) => {
    const { response } = await authServices.getBetlock({
      user_id: userIds,
      master_password: password,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData("betAllow", !permissionData.betAllow);
      getUserList();
      setPermission(!show);
      setShowPasswordModal(!showPasswordModal);
      setPassword("");
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const getUserLock = async (password:string) => {
    const { response } = await authServices.getUserLock({
      user_id: userIds,
      action: permissionData?.userLock ? 0 : 1,
      pass_type:"PASSWORD",
      password:password
    });

    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData("userLock", !permissionData.userLock);
      getUserList();
      setPermission(!show);
      setShowPasswordModal(!showPasswordModal);
      setPassword("");
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const getFancybetLock = async (password:string) => {
    const { response } = await authServices.getFancyBetlock({
      user_id: userIds,
      is_child_lock: 1,
      master_password:password,
      pass_type:"PASSWORD"
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData("fancybetAloow", false);
      getUserList();
      setShowPasswordModal(!showPasswordModal)
      setPermission(!show);
      setPassword("");
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const getFancyBetUnlock = async () => {
    const { response } = await authServices.getFancyBetUnlock({
      user_id: userIds,
      is_child_lock: 0,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData("fancybetAloow", true);
      getUserList();
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const getCloseAcc = async (password:string) => {
    const { response } = await authServices.getCloseAcc({
      user_id: userIds,
      action: userData?.self_close_account === 0 ? 1 : 0,
      password,
      pass_type:"PASSWORD"
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData(
        "closeUserAccount",
        !permissionData.closeUserAccount
      );
      setShowPasswordModal(!showPasswordModal)
      getUserList();
      setPassword("");
      setPermission(false);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const getEventLock = async () => {
    const { response } = await authServices.getEventLock({
      user_id: userIds,
      check_event_limit: !permissionData.sportSetting,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updatePermissionData("sportSetting", !permissionData.sportSetting);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  useEffect(() => {
    if (userData) {
      setPermissionData({
        allowMultilogin: userData?.is_multi_login_allow !== 0,
        betAllow:
          userData?.parent_lock_betting === 0 &&
          userData?.self_lock_betting === 0,
        fancybetAloow: userData?.self_lock_fancy_bet === 0,
        userLock:
          userData?.parent_lock_user !== 0 || userData?.self_lock_user !== 0,
        closeUserAccount: userData?.self_close_account !== 0,
        sportSetting: userData?.check_event_limit,
      });
    }
  }, [userData]);

  const handleLock = () => {
    if (permissionData.fancybetAloow) {
      setShowPasswordModal(!showPasswordModal);
      setPermission(!show);
      setUpdateData("fancylock");
      //   getFancybetLock();
    } else {
      getFancyBetUnlock();
    }
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setPassword(value);
    setLoginPasswordError(
      value.trim() === "" ? "Password cannot be empty." : ""
    );
  };
  const handleBetLock = () => {
    setShowPasswordModal(!showPasswordModal);
    setUpdateData("betlock");
    setPermission(!show);
  };
  const handleLockUser = () => {
    setShowPasswordModal(!showPasswordModal);
    setUpdateData("lockuser");
    setPermission(!show);
  };
  const handleCloseUer = () => {
    setShowPasswordModal(!showPasswordModal);
    setUpdateData("closeuser");
    setPermission(!show);
  };

  const validateAndSubmit = (): boolean => {
    if (!password.trim()) {
      setLoginPasswordError("Password cannot be empty.");
      return false;
    }
    setLoginPasswordError("");
    return true;
  };

  const handleUpdate = () => {
    if (validateAndSubmit()) {
      if ("betlock" === updateData) {
        getBetLock(password);
      }else if("lockuser" === updateData){
        getUserLock(password)
      }else if("closeuser" === updateData){
        getCloseAcc(password)
      }else{
        getFancybetLock(password)
      }
    }
  };
  const handleCancelUpdata = () => {
    setShowPasswordModal(!showPasswordModal);
    setPermission(!show);
    setPassword("");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setPermission(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Permission Of {userName}</span>
          <Button variant="light btn-sm" onClick={() => setPermission(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <Row className="gap-3">
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  checked={permissionData.allowMultilogin}
                  id="MultiLogin"
                  onChange={getMultiLogin}
                />
                <Form.Label
                  className="form-check-label ms-2"
                  htmlFor="MultiLogin"
                >
                  Allow MultiLogin
                </Form.Label>
              </div>
            </Col>
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  checked={permissionData.betAllow}
                  id="BetAllow"
                  onChange={() => handleBetLock()}
                />
                <Form.Label
                  className="form-check-label ms-2"
                  htmlFor="BetAllow"
                >
                  Bet Allow
                </Form.Label>
              </div>
            </Col>
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  checked={permissionData.fancybetAloow}
                  id="FancyBetAllow"
                  onChange={handleLock}
                />
                <Form.Label
                  className="form-check-label ms-2"
                  htmlFor="FancyBetAllow"
                >
                  Fancy Bet Allow
                </Form.Label>
              </div>
            </Col>
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  checked={permissionData.userLock}
                  id="LockUser"
                  onChange={handleLockUser}
                />
                <Form.Label
                  className="form-check-label ms-2"
                  htmlFor="LockUser"
                >
                  Lock User
                </Form.Label>
              </div>
            </Col>
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  checked={permissionData.closeUserAccount}
                  id="CloseUser"
                  onChange={handleCloseUer}
                />
                <Form.Label
                  className="form-check-label ms-2"
                  htmlFor="CloseUser"
                >
                  Close User Account
                </Form.Label>
              </div>
            </Col>
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  checked={permissionData.sportSetting}
                  id="SportsSettings"
                  onChange={getEventLock}
                />
                <Form.Label
                  className="form-check-label ms-2"
                  htmlFor="SportsSettings"
                >
                  Sports Settings
                </Form.Label>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="light" onClick={() => setPermission(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        className="zoomIn "
        scrollable
      >
        <Modal.Body className="fs-md">
          <Row className="gap-3">
            <div className="form-floating mt-3">
              <Form.Control
                isInvalid={!!loginPasswordError}
                name="currentPassword"
                type={showPassword ? "text" : "password"}
                placeholder="Logged In Password"
                value={password}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Enter Logged In Password</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={`${
                    showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                  } align-middle`}
                ></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                {loginPasswordError}
              </Form.Control.Feedback>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="light" onClick={() => handleCancelUpdata()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdate()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PermissionModal;