import { useState, useEffect } from "react";
import { Form, Row, Col, Card, Button } from "react-bootstrap";
import { Dispatch, FC, SetStateAction } from "react";

interface Props {
  userData: DataRes | undefined;
  setUserPayload: Dispatch<
    SetStateAction<{
      partnership: number;
      domain: string;
      domain_name: string;
      name: string;
      user_name: string;
      password: string;
      user_type_id: string;
      parent_id: string;
      point: number;
      exposure_limit: number;
      belongs_to_credit_reference: number;
      credit_reference: number;
      belongs_to: string;
      title: string;
      session_commission: number;
      match_commission: number;
      master_password: string;
    }>
  >;
  userPayload: {
    partnership: number;
    domain: string;
    domain_name: string;
    name: string;
    user_name: string;
    password: string;
    user_type_id: string;
    parent_id: string;
    point: number;
    exposure_limit: number;
    belongs_to_credit_reference: number;
    credit_reference: number;
    belongs_to: string;
    title: string;
    session_commission: number;
    match_commission: number;
    master_password: string;
  };
  errors: { [key: string]: string };
}

const CommissionSetting: FC<Props> = ({
  userData,
  setUserPayload,
  userPayload,
  errors,
}) => {
  const [error, setError] = useState<string | null>(null);

  const [matchCommissionInput, setMatchCommissionInput] = useState(
    userPayload.match_commission.toString()
  );
  const [sessionCommissionInput, setSessionCommissionInput] = useState(
    userPayload.session_commission.toString()
  );

  useEffect(() => {
    setMatchCommissionInput(userPayload.match_commission.toString());
    setSessionCommissionInput(userPayload.session_commission.toString());
  }, [userPayload]);

  const updateMatchCommission = (amount: number) => {
    setError(null);
    const newValue = Math.max(0, userPayload.match_commission + amount);
    setUserPayload((prev) => ({ ...prev, match_commission: newValue }));
    setMatchCommissionInput(newValue.toString());
  };

  const updateSessionCommission = (amount: number) => {
    setError(null);
    const newValue = Math.max(0, userPayload.session_commission + amount);
    setUserPayload((prev) => ({ ...prev, session_commission: newValue }));
    setSessionCommissionInput(newValue.toString());
  };

  const handleMatchCommissionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "") {
      setMatchCommissionInput("");
      setError("Match commission is required");
    } else {
      const parsedValue = parseInt(value, 10);
      setUserPayload((prev) => ({ ...prev, match_commission: parsedValue }));
      setMatchCommissionInput(parsedValue.toString());
      if (!isNaN(parsedValue)) {
        if (parsedValue >= 100) {
          setError("Match commission must be less than or equal to 100");
        } else {
          setError(null);
        }
      }
    }
  };

  const handleSessionCommissionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "") {
      setSessionCommissionInput("");
      setError("Session commission is required");
    } else {
      const parsedValue = parseInt(value, 10);
      setUserPayload((prev) => ({ ...prev, session_commission: parsedValue }));
      setSessionCommissionInput(parsedValue.toString());
      if (!isNaN(parsedValue)) {
        if (parsedValue > 100) {
          setError("Session commission must be less than or equal to 100");
        } else {
          setError(null);
        }
      }
    }
  };

  const handleKeyDown = (e: any, type: "match" | "session") => {
    const invalidKeys = ["-", "e", "E", "+", "."];

    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }

    if (
      type === "match" &&
      matchCommissionInput.length >= 3 &&
      e.key !== "Backspace"
    ) {
      e.preventDefault();
    }

    if (
      type === "session" &&
      sessionCommissionInput.length >= 3 &&
      e.key !== "Backspace"
    ) {
      e.preventDefault();
    }
  };

  return (
    <Card>
      <Card.Body>
        <Form action="#">
          <Row className="g-3">
            <h5 className="text-primary mb-0">Commission Setting</h5>

            <Col>
              <div className="fs-md mb-2">Market Commission</div>
              <div className="input-step step-primary">
                <Button
                  className="minus"
                  onClick={() => updateMatchCommission(-1)}
                >
                  -
                </Button>
                <Form.Control
                  type="number"
                  className="product-quantity"
                  value={matchCommissionInput}
                  min="0"
                  max="100"
                  name="match_commission"
                  onChange={handleMatchCommissionChange}
                  onKeyDown={(e) => handleKeyDown(e, "match")}
                />
                <Button
                  className="plus"
                  onClick={() => updateMatchCommission(1)}
                >
                  +
                </Button>
              </div>
              {error && error?.includes("Match commission") && (
                <Col>
                  <div className="text-danger">{error}</div>
                </Col>
              )}
            </Col>

            <Col>
              <div className="fs-md mb-2">Session Commission</div>
              <div className="input-step step-primary">
                <Button
                  className="minus"
                  onClick={() => updateSessionCommission(-1)}
                >
                  -
                </Button>
                <Form.Control
                  name="session_commission"
                  type="number"
                  className="product-quantity"
                  value={sessionCommissionInput}
                  min="0"
                  max="100"
                  onChange={handleSessionCommissionChange}
                  onKeyDown={(e) => handleKeyDown(e, "session")}
                />
                <Button
                  className="plus"
                  onClick={() => updateSessionCommission(1)}
                >
                  +
                </Button>
              </div>

              {error && error?.includes("Session commission") && (
                <Col>
                  <div className="text-danger">{error}</div>
                </Col>
              )}
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CommissionSetting;
