import ToggleSwitch from "Common/ToggleSwitch";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface RootObject {
  user_name: string;
  parent_id: null;
  data: Data;
  status: boolean;
}

interface Data {
  users: User[];
  metadata: Metadata;
}

interface Metadata {
  total: Total[];
  own: Total[];
  parent: any[];
  teams: string[];
  columns: string[];
  total_exposure: Totalexposure[];
}

interface Totalexposure {
  market_id: string;
  selection_id: number;
  selection_name: string;
  sort_priority: number;
  win_loss: number;
  win_loss_total_exposure: number;
}

interface Total {
  selection_id: number;
  selection_name: string;
  win_loss: number;
}

interface User {
  user_id: string;
  user_type_id: number;
  user_name: string;
  domain_name: string;
  england: number;
  england_total_exposure: number;
  australia: number;
  australia_total_exposure: number;
}

interface BookModalProps {
  show: any;
  clickHandler: any;
  marketIds: string;
  setFancy: Dispatch<SetStateAction<boolean>>;
  fancy: boolean;
  marketName: string;
  screenWidth: number
}

const BookModal = ({
  show,
  clickHandler,
  marketIds,
  setFancy,
  fancy,
  marketName,
  screenWidth
}: BookModalProps) => {
  // const [showOn, setShowOn] = useState(false);
  const [bookData, setBookData] = useState<RootObject>();
  const [BookFancyData, setBookFancyData] = useState<any>();
  const [userData, setUserData] = useState<string>("");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      setUserData(userId);
    }

  }, [userId, show]);

  const getMarketPosition = async (user_id: string) => {
    const { response } = await authServices.getMarketPosition({
      user_id,
      market_id: marketIds,
    });
    if (response) {
      if (response?.data) {
        setBookData(response);
      } else if (!response?.status) {
        snackbarUtil.error(response?.msg);
      }
    }
  };

  useEffect(() => {
    if (marketIds) {
      getMarketPosition(userData);
    }

  }, [userId, marketIds]);

  const normalizeTeamName = (team: string): string => {
    return team.toLowerCase().replace(/ /g, "_");
  };

  useEffect(() => {
    if (!bookData?.data) return;
    const mergedData = bookData.data.users.map((user: any) => {
      const mergedUser = { ...user };

      bookData.data.metadata.teams.forEach((team: string) => {
        const normalizedTeam = normalizeTeamName(team);
        const teamScore = user[normalizedTeam];
        const teamExposure = user[`${normalizedTeam}_total_exposure`];
        mergedUser[team] = {
          win_loss: teamScore || 0,
          total_exposure: teamExposure || 0,
        };

        delete mergedUser[normalizedTeam];
        delete mergedUser[`${normalizedTeam}_total_exposure`];
      });

      return mergedUser;
    });

    setBookFancyData(mergedData);

  }, [bookData]);

  const handleParentShere = (ids: string | null | undefined) => {
    if (ids) {
      setUserData(ids);
      getMarketPosition(ids);
    }
  };

  const getWinLossByTeam = (team: string, dataArray: Total[]): number => {
    const teamData = dataArray.find((item) => item.selection_name === team);
    return teamData ? teamData.win_loss : 0;
  };

  const handleClose = () => {
    clickHandler();
    setUserData("");
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      className="zoomIn"
      scrollable
      centered={screenWidth < 800}
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <div className="d-flex align-items-center flex-wrap">
          <i
            className="ri-arrow-left-line me-2 cursor-pointer"
            onClick={() => handleParentShere(bookData?.parent_id)}
          ></i>{" "}
          Market Position{" "}
          <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">({marketName})</span>
          <ToggleSwitch
            On="S"
            Off="F"
            id="fancyId"
            onChange={() => setFancy(!fancy)}
            checked={fancy}
          />
        </div>
        <Button variant="light btn-sm" onClick={handleClose}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-1 fs-md">
        <div className="table-responsive">
          <Table className="align-middle table-striped table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col">Domain Name</th>
                <th scope="col">Account</th>
                {bookData?.data?.metadata?.teams?.map((team, index) => (
                  <th key={index} scope="col">
                    {team}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {BookFancyData?.map((user: any, id: number) => (
                <tr key={id}>
                  <td>{user?.domain_name}</td>
                  <td>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleParentShere(user?.user_id)}
                    >
                      <Button
                        variant="subtle-primary"
                        className="rounded-pill p-1"
                        style={{ width: "30px" }}
                      >
                        {user?.user_type_id == 1 ? "C" : "A"}
                      </Button>
                      <span className="text-primary ms-2">
                        {user?.user_name}
                      </span>
                    </div>
                  </td>
                  {bookData?.data?.metadata?.teams?.map((team, index) => {
                    const teamData = user[team];
                    return (
                      <td
                        key={index}
                        className={
                          teamData?.win_loss < 0 || teamData?.total_exposure < 0
                            ? "text-danger"
                            : "text-secondary"
                        }
                      >
                        {fancy
                          ? Number(teamData?.win_loss)?.toFixed(2)
                          : Number(teamData?.total_exposure)?.toFixed(2)}
                      </td>
                    );
                  })}
                </tr>
              ))}

              {fancy ? (
                <tr>
                  <td></td>
                  <td className="fw-bold">Total</td>
                  {bookData?.data?.metadata?.teams?.map((team, index) => {
                    const totalWinLoss = getWinLossByTeam(
                      team,
                      bookData.data.metadata.total
                    );
                    return (
                      <td
                        key={index}
                        className={
                          totalWinLoss < 0 ? "text-danger" : "text-secondary"
                        }
                      >
                        {totalWinLoss?.toFixed(2)}
                      </td>
                    );
                  })}
                </tr>
              ) : (
                <tr>
                  <td></td>
                  <td className="fw-bold">Total</td>
                  {bookData?.data?.metadata?.teams?.map(
                    (team: string, index: number) => {
                      // Calculate grand total for each team across all users
                      const grandTotal = BookFancyData?.reduce(
                        (acc: number, user: any) => {
                          const teamData = user[team];
                          const value = fancy
                            ? Number(teamData?.win_loss)
                            : Number(teamData?.total_exposure);
                          return acc + (value || 0);
                        },
                        0
                      );

                      return (
                        <td
                          key={index}
                          className={
                            grandTotal < 0 ? "text-danger" : "text-secondary"
                          }
                        >
                          {grandTotal && grandTotal?.toFixed(2)}
                        </td>
                      );
                    }
                  )}
                </tr>
              )}

              {fancy && (
                <tr>
                  <td></td>
                  <td className="fw-bold">Own</td>
                  {bookData?.data?.metadata?.teams?.map((team, index) => {
                    const ownWinLoss = getWinLossByTeam(
                      team,
                      bookData.data.metadata.own
                    );
                    return (
                      <td
                        key={index}
                        className={
                          ownWinLoss < 0 ? "text-danger" : "text-secondary"
                        }
                      >
                        {ownWinLoss?.toFixed(2)}
                      </td>
                    );
                  })}
                </tr>
              )}

              {fancy &&
                bookData?.data?.metadata?.parent &&
                bookData?.data?.metadata?.parent?.length > 0 && (
                  <tr>
                    <td></td>
                    <td className="fw-bold">Parent</td>
                    {bookData?.data?.metadata?.teams?.map(
                      (team: any, index: any) => {
                        const ownWinLoss = getWinLossByTeam(
                          team,
                          bookData.data.metadata.parent
                        );
                        return (
                          <td
                            key={index}
                            className={
                              ownWinLoss < 0 ? "text-danger" : "text-secondary"
                            }
                          >
                            {ownWinLoss?.toFixed(2)}
                          </td>
                        );
                      }
                    )}
                  </tr>
                )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BookModal;
