import React, { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";



interface Series {
    is_active: boolean;
    name: string;
    series_id: string
    sport_id: string
}

const Navdata = () => {
    const [isDashboard, setIsDashboard] = useState(false);
    const [isCricket, setIsCricket] = useState(false);
    const [isTennis, setIsTennis] = useState(false);
    const [isSoccer, setIsSoccer] = useState(false);
    const [isDownline, setIsDownline] = useState(false);
    const [isSuper, setIsSuper] = useState(false);
    const [isReports, setIsReports] = useState(false);
    const [isB2c, setIsB2c] = useState(false);
    const [sportSeriesData, setSportSeriesData] = useState<Series[]>([]);
    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id: any = item.getAttribute("sub-items");
                var menusId = document.getElementById(id);
                if (menusId) {
                    (menusId.parentElement as HTMLElement).classList.remove("show");
                }
            });
            e.target.classList.add("active");
        }
    }

    const fetchSeries = async (sport_id: any) => {
        if (!sport_id) return;
        try {
            const { response } = await authServices.getSeriesData({ sport_id, active_only: true });
            if (response?.status) {
                setSportSeriesData(response?.data);
            } else {
                setSportSeriesData([]);
            }
        } catch (error) {
            console.error("Error fetching series data:", error);
        }
    };

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');

        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Cricket') {
            setIsCricket(false);
        }
        if (iscurrentState !== 'Tennis') {
            setIsTennis(false);
        }
        if (iscurrentState !== 'Soccer') {
            setIsSoccer(false);
        }
        if (iscurrentState !== 'Downline') {
            setIsDownline(false);
        }
        if (iscurrentState !== 'Super') {
            setIsSuper(false);
        }
        if (iscurrentState !== 'Reports') {
            setIsReports(false);
        }
        if (iscurrentState !== 'b2c') {
            setIsB2c(false);
        }
    }, [
        iscurrentState,
        isCricket,
        isTennis,
        isSoccer,
        isDownline,
        isSuper,
        isReports,
        isB2c,
        isDashboard,
    ]);
    const [userDetails, setUserDetails] = useState<any>(null);
    useEffect(() => {
        const user = localStorage.getItem("adminDetails");
        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setUserDetails(parsedUser);
            } catch (error) {
                console.error("Failed to parse user details", error);
            }
        }
    }, []);



    const menuItems: any = [
        {
            id: "dashboard",
            label: "Dashboards",
            icon: "ph-gauge",
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                localStorage.removeItem("sportId");
            },
            stateVariables: isDashboard,
            subItems: [
                { id: "Dashboard1", label: "Dashboard", link: "/dashboard", parentId: "dashboard" },
                { id: "Dashboard2", label: "B2C Report", link: "/b2c-report", parentId: "dashboard" },
            ]
        },
        {
            id: "Downline",
            label: "Downline List",
            icon: 'ri-bubble-chart-line',
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsDownline(!isDownline);
                setIscurrentState('Downline');
                updateIconSidebar(e);
            },
            stateVariables: isDownline,
            subItems: [
                ...(userDetails?.user_type_id !== 2 ? [{ id: "Downline1", label: "Add Agent", link: "/add-agent", parentId: "Downline" }] : []),
                ...(userDetails?.user_type_id === 0 ? [{ id: "Downline2", label: "Add Uk Agent", link: "/add-uk-agent", parentId: "Downline" }] : []),
                { id: "Downline3", label: "Add User", link: "/add-user", parentId: "Downline" },
                ...(userDetails?.user_type_id === 0 ? [{ id: "Downline4", label: "All User List", link: "/user-list" }] : []),
                { id: "Downline4", label: "User List", link: "/agent-user-list", parentId: "Downline" },
                ...(userDetails?.user_type_id === 0 ? [{ id: "Downline5", label: "B2C Manager", link: "/b2c-manager", parentId: "Downline" }] : []),
                ...(userDetails?.user_type_id === 0 ? [{ id: "Downline6", label: "Online User", link: "/online-user", parentId: "Downline" }] : []),
                ...(userDetails?.user_type_id === 0 ? [{ id: "Downline7", label: "Closed User", link: "/closed-user", parentId: "Downline" }] : []),
                { id: "Downline8", label: "Current Bets", link: "/current-bets", parentId: "Downline" },
            ]
        },
        {
            id: "Cricket",
            label: "Cricket",
            icon: 'bx bx-cricket-ball',
            link: "#",
            sportId: "4",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsCricket(!isCricket);
                setIscurrentState('Cricket');
                updateIconSidebar(e);
                fetchSeries("4");
            },
            stateVariables: isCricket,
            subItems: sportSeriesData
        },
        {
            id: "Tennis",
            label: "Tennis",
            icon: 'bx bxs-tennis-ball',
            link: "#",
            sportId: "2",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsTennis(!isTennis);
                setIscurrentState('Tennis');
                updateIconSidebar(e);
                fetchSeries("2");
            },
            stateVariables: isTennis,
            subItems: sportSeriesData
        },
        {
            id: "Soccer",
            label: "Soccer",
            icon: 'bx bx-football',
            link: "#",
            sportId: "1",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsSoccer(!isSoccer);
                setIscurrentState('Soccer');
                updateIconSidebar(e);
                fetchSeries("1");
            },
            stateVariables: isSoccer,
            subItems: sportSeriesData
        },

        ...(userDetails?.user_type_id === 0 ? [{
            id: "ImportMarket",
            label: "Import Market",
            icon: "ri-building-2-line",
            link: "/import-market",
        }] : []),
        ...(userDetails?.user_type_id !== 0 ? [{
            id: "ImportMarket",
            label: "Block Market",
            icon: "ri-building-2-line",
            link: "/block-market",
        }] : []),
        {
            id: "market-analisis",
            label: "Market Analysis",
            icon: "ri-building-2-line",
            link: "/market-analysis",
        },
        ...(userDetails?.user_type_id === 0 ? [{
            id: "Super",
            label: "Super",
            icon: 'ri-vip-crown-2-line',
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsSuper(!isSuper);
                setIscurrentState('Super');
                updateIconSidebar(e);
            },
            stateVariables: isSuper,
            subItems: [
                { id: "Super1", label: "Domain Setting", link: "/domain-setting", parentId: "Super" },
                { id: "Super2", label: "Setting", link: "/setting", parentId: "Super" },
                { id: "Super3", label: "Match result", link: "/match-result", parentId: "Super" },
                { id: "Super4", label: "Fancy result", link: "/fancy-result", parentId: "Super" },
                { id: "Super5", label: "Casino result", link: "/casino-result", parentId: "Super" },
                { id: "Super6", label: "Universe Casino result", link: "/universe-casino-result", parentId: "Super" },
                { id: "Super7", label: "Roll Back Match result", link: "/match-rollback", parentId: "Super" },
                { id: "Super8", label: "Roll Back Fancy result", link: "/fancy-rollback", parentId: "Super" },
                { id: "Super13", label: "User Mobile Data", link: "/user-mobile-data", parentId: "Super" },
                { id: "Super14", label: "Fraud Bets", link: "/fraud-bet", parentId: "Super" },
                { id: "Super15", label: "Delete Bets", link: "/delete-bet", parentId: "Super" },
                { id: "Super16", label: "Void Bets", link: "/void-bet", parentId: "Super" },
            ]
        }] : []),
        {
            id: "Reports",
            label: "Reports",
            icon: 'ri-file-list-3-line',
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsReports(!isReports);
                setIscurrentState('Reports');
                updateIconSidebar(e);
            },
            stateVariables: isReports,
            subItems: [
                { id: "Reports1", label: "My statement", link: "/reports/Statement", parentId: "Reports" },
                { id: "Reports2", label: "Chip Summary", link: "/reports/ChipSummary", parentId: "Reports" },
                { id: "Reports3", label: "Casino Bets", link: "/reports/CasinoBets", parentId: "Reports" },
                { id: "Reports4", label: "Market P|L", link: "/reports/Market", parentId: "Reports" },
                { id: "Reports5", label: "Sport P|L", link: "/reports/SportsPL", parentId: "Reports" },
                { id: "Reports6", label: "QTech P|L", link: "/reports/QTechPL", parentId: "Reports" },
                { id: "Reports7", label: "Fancy P|L", link: "/reports/FancyPL", parentId: "Reports" },
                { id: "Reports8", label: "Fancy Stake (M)", link: "/reports/FancyStakeM", parentId: "Reports" },
                { id: "Reports9", label: "Fancy Stake (U)", link: "/reports/FancyStakeU", parentId: "Reports" },
                { id: "Reports10", label: "Open Bets", link: "/reports/OpenBets", parentId: "Reports" },
                { id: "Reports11", label: "Settled Bets", link: "/reports/SettledBets", parentId: "Reports" },
                { id: "Reports12", label: "News Update", link: "/reports/NewsUpdate", parentId: "Reports" },
                ...(userDetails?.user_type_id === 8 ? [{ id: "Super12", label: "Banner Setting", link: "/banners-setting", parentId: "Reports" }] : []),
            ]
        },
        {
            id: "b2c",
            label: "B2C",
            icon: "ri-briefcase-2-fill",
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsB2c(!isB2c);
                setIscurrentState('b2c');
                updateIconSidebar(e);
            },
            stateVariables: isB2c,
            subItems: [
                // { id: "b2c1", label: "B2C Report", link: "/b2c-report", parentId: "b2c" },
                ...(userDetails?.user_type_id === 0 || userDetails?.user_type_id === 8 ? [{ id: "b2c2", label: "Banner Setting", link: "/banners-setting", parentId: "b2c" }] : []),
                ...(userDetails?.user_type_id === 2 || userDetails?.user_type_id === 8 ? [{ id: "b2c3", label: "Add Social Media", link: "/social-media", parentId: "b2c" }] : []),
                ...(userDetails?.user_type_id === 8 && userDetails?.belongs_to_b2c ? [{ id: "b2c4", label: "Bonus Update", link: "/bonus-update", parentId: "b2c" }] : []),
                { id: "b2c4", label: "D/W Report", link: "/transaction-list", parentId: "b2c" },
                // ...(userDetails?.user_type_id === 2 && userDetails?.is_b2c_dealer ? [{ id: "b2c5", label: "B2C Deposit", link: "/deposit", parentId: "b2c" }] : []),
                // ...(userDetails?.user_type_id === 2 && userDetails?.is_b2c_dealer ? [{ id: "b2c6", label: "B2C Withdraw", link: "/withdraw", parentId: "b2c" }] : []),
                ...(userDetails?.user_type_id === 2 && userDetails?.is_b2c_dealer ? [{ id: "b2c5", label: "Deposit & Withdraw", link: "/deposit-withdraw", parentId: "b2c" }] : []),
                ...(userDetails?.user_type_id === 2 && userDetails?.is_b2c_dealer ? [{ id: "b2c7", label: "Deposit Method", link: "/deposit-method", parentId: "b2c" }] : []),
            ]
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;