import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";

interface FrausBetTablePorps {
  data: FraudBets[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
  setFormValuesFilter?:any;
  formValuesFilter?:any;
  setUserName: Dispatch<SetStateAction<string>>
  userName:string
  setDomainId:Dispatch<SetStateAction<string>>
  domainId:string
  handleOddsChange: (key: string, value: any) => void;
  oddsRange: {from: number;to: number}
  handleRateChange?:any;
  rateRange?: {
    from: number;
    to: number;
}
}

const FraudBetTable = ({
  data,
  page,
  limit,
  listTotal,
  handlePageFilter,
  setFormValuesFilter,
  formValuesFilter,
  setUserName,
  userName,
  setDomainId,
  domainId,
  handleOddsChange,
  oddsRange,
  handleRateChange,
  rateRange
}: FrausBetTablePorps) => {
  const [showRollback, setRollback] = useState(false);
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [showBetAddress, setBetAddress] = useState(null);
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [fixedCountryData, setFixedCountryData] = useState<any>(null);

 
  // const [currentPage, setCurrentPage] = useState(1);
 
  const toggleRollback = () => {
    setRollback(!showRollback);
  };

  const ToggleBetAddress = (betId: any) => {
    setBetAddress((prevBetId) => (prevBetId === betId ? null : betId));
  }

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setBetAddress(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const defaultTable = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map((items, index: number) => ({
      DomainName: items?.domain_name || "",
      betid: items?.bet_id || "",
      sport_name: items?.sport_name || "",
      series_name: items?.series_name || "",
      match_name: items?.match_name || "",
      market_name: items?.market_name || "",
      selection_name: items?.selection_name || "",
      betType: items?.is_back === 1 ? "Back" : "Lay",
      rate: items?.odds || "",
      stake: items?.stack || "",
      betPlaceTime: items?.createdAt || "",
      playerName: items?.user_name || "",
      iPAddress: items?.ip_address || "",
      result: items?.winner_name || "",
      comment: items?.deleted_reason || "",
      status: items?.delete_status,
      geolocation: items?.geolocation,
      OddsRate: items?.odds,
      oddsStack: items?.stack,
    }));
  }, [data]);

  const getUserbyUserName = async (user_name: string) => {
    
    const { response } = await settingServices.getUserbyUserName({
      user_name: user_name?.trim()?.toLowerCase(),
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?.domain_name,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  useEffect(()=>{
    getAllWebsite();
  },[])

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };
  
  const handleSelectUser = (selectedOption: any) => {
    if (selectedOption) {
      setUserName(selectedOption.value);
    }
  };

  const handleDomain = (selectedOption: any) => {
    // setCurrentPage(1);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);
  };

  const columns = useMemo(
    () => [
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Button
              className="btn-sm"
              variant={cell?.row?.original?.status !== 1 ? "subtle-warning" : "danger"}
              onClick={toggleRollback}
            >
              {cell?.row?.original?.status === 1 && <i className="fs-md ri ri-delete-bin-fill"></i>}
              {cell?.row?.original?.status === 2 && <>V</>}
              {cell?.row?.original?.status === 0 && <>D</>}
            </Button>
          );
        },
      },
      {
        header: "Player Name",
        accessorKey: "user_name",
        enableColumnFilter: false,
        cell: (cell: any) => {

          return (
            <div
            >

              {cell?.row?.original?.playerName?.replace(/\(\w+\)$/, '').trim()}
            </div>
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "OddsRate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.OddsRate}
            </div>
          );
        },
      },
      {
        header: "Stake",
        accessorKey: "oddsStack",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.oddsStack}
            </div>
          );
        },
      },
      
      // {
      //   header: "Bet Id",
      //   accessorKey: "betid",
      //   enableColumnFilter: false,
      // },
      {
        header: "Sport",
        accessorKey: "sport_name",
        enableColumnFilter: false,
      },
      {
        header: "Series",
        accessorKey: "series_name",
        enableColumnFilter: false,
      },
      {
        header: "Match",
        accessorKey: "match_name",
        enableColumnFilter: false,
      },
      {
        header: "Market",
        accessorKey: "market_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.market_name?.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}
            </div>
          );
        },
      },
      {
        header: "Runner",
        accessorKey: "selection_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.selection_name?.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}
            </div>
          );
        },
      },
      {
        header: "Bet Type",
        accessorKey: "betType",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.betType}
            </div>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "DomainName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >
              {cell?.row?.original?.DomainName}
            </div>
          );
        },
      },

      {
        header: "Bet Place Time",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="m-0">
              {moment(cell?.row?.original?.betPlaceTime).format("DD-MM-YYYY")}
              </p>
              <p className="m-0">
              {moment(cell?.row?.original?.betPlaceTime).format("hh:mm:ss:SSS A")}
              </p>
              </div>
          );
        },
      },
      
      {
        header: "IP Address",
        accessorKey: "ip_address",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const countryData = cell?.row?.original?.geolocation &&
          (typeof cell.row.original.geolocation === "string"
            ? JSON.parse(cell.row.original.geolocation)
            : cell.row.original.geolocation);

            const handleMouseEnter = () => {
              if (!fixedCountryData) {
                const countryData =
                  cell?.row?.original?.geolocation &&
                  (typeof cell.row.original.geolocation === "string"
                    ? JSON.parse(cell.row.original.geolocation)
                    : cell.row.original.geolocation);
  
                setFixedCountryData(countryData);
                setBetAddress(cell?.row?.original?.betid);
              }
            };
  
            const handleMouseLeave = () => {
              setFixedCountryData(null);
            };
          return (

            <div className="position-relative"  >
            <span>
              <i 
            onMouseLeave={handleMouseLeave} 
            onMouseEnter={handleMouseEnter} className="bi bi-globe me-1 cursor-pointer" style={{ verticalAlign: "middle" }}></i>
              {cell?.row?.original?.iPAddress}
            </span>
            {showBetAddress === cell?.row?.original?.betid && fixedCountryData && (
                <div ref={tooltipRef} className="bg-white p-3 rounded position-absolute" style={{ right: '20px', minWidth:"200px", zIndex:"999", boxShadow:"0px 8px 16px 0px rgba(0,0,0,0.2)"}}>
                  <div className="d-flex justify-content-between align-item-center mb-1">
                    <p className="fw-bold m-0">City : </p>
                    <p className="p-0 m-0"> {fixedCountryData.city}</p>
                  </div>
                  <div className="d-flex justify-content-between p-0 align-item-center mb-1">
                    <p className="fw-bold m-0">State :{" "} </p>
                    <p className="m-0"> {fixedCountryData.state}</p>
                  </div>
                  <div className="d-flex justify-content-between p-0 align-item-center">
                    <p className="fw-bold m-0">Country : </p>
                    <p className="m-0"> {fixedCountryData.country}</p>
                  </div>

                  
                </div>
              )}
            </div>
            )
        },
      },
      {
        header: "Result",
        accessorKey: "winner_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.result}
            </div>
          );
        },
      },
      {
        header: "Comment",
        accessorKey: "deleted_reason",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.comment}
            </div>
          );
        },
      },
    ],
     // eslint-disable-next-line
    [defaultTable, showBetAddress, fixedCountryData]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        sorting={true}
        isFilter={true}
        handleUser={handleUser}
        setFormValuesFilter={(value: any) => {
          // setCurrentPage(1);
          setFormValuesFilter(value);
        }}
        formValuesFilter={formValuesFilter}
        userNameData={userNameData}
        userName={userName}
        handleSelectUser={handleSelectUser}
        setUser_name={setUserName}
        domainList={domainList}
        domainId={domainId}
        handleDomain={handleDomain}
        oddsRange={oddsRange}
        handleOddsChange={handleOddsChange}
        handleRateChange={handleRateChange}
        rateRange={rateRange}
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(1, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>
    </>
  );
};

export default FraudBetTable;
