import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "Layout";
import NonAuthLayout from "Layout/NonAuthLayout";

//routes
import { publicRoutes, authProtectedRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import { authServices } from "Utils/auth/services";

const Index = () => {
  const navigate = useNavigate();
  const getLogout = async () => {
    const { response } = await authServices.getLogout();
  };


  // useEffect(() => {
  //     const handleContextMenu = (e: MouseEvent) => {
  //         e.preventDefault();
  //     };

  //     const handleKeyDown = (e: KeyboardEvent) => {
  //         const keyCode = e.keyCode;
  //         if (
  //             keyCode === 123 ||
  //             (e.ctrlKey && e.shiftKey && e.key === "I") ||
  //             (e.ctrlKey && e.shiftKey && e.key === "J") ||
  //             (e.ctrlKey && e.key === "U")
  //         ) {
  //             e.preventDefault();
  //             getLogout();

  //         }
  //     };

  //     document.body.addEventListener("contextmenu", handleContextMenu);
  //     document.addEventListener("keydown", handleKeyDown);

  //     return () => {
  //         document.body.removeEventListener("contextmenu", handleContextMenu);
  //         document.removeEventListener("keydown", handleKeyDown);
  //     };
  // }, []);

  //   window.addEventListener('resize', () => {
  //     const widthThreshold = window.outerWidth - window.innerWidth > 100;
  //     const heightThreshold = window.outerHeight - window.innerHeight > 100;

  //     if (widthThreshold || heightThreshold) {
  //       console.log("DevTools might be open (Network tab or others).");
  //       localStorage.clear();
  //     }
  //   });

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route: any, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={<NonAuthLayout> {route.component} </NonAuthLayout>}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route: any, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>{route.component}</Layout>{" "}
                </AuthProtected>
              }
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
