import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ToggleSwitch from "Common/ToggleSwitch";
import snackbarUtil from "Utils/snackBarUtil";
import { BetLimits } from "pages/MatchDetail";
import { baseURL } from "Utils/baseUrl";
import { validateField } from "Common/Validation/MarketValidation";

interface MatchSettingProps {
  show: boolean;
  clickHandler: any;
  limits: BetLimits | undefined;
  id: string | undefined;
  getLimit: any;
  fancyShow: boolean;
  shoeOdss: boolean;
  marketId: string;
  marketName: string;
  limitValidation: any;
  category?: number | null;
}

const MatchSetting = ({
  show,
  clickHandler,
  limits,
  id,
  getLimit,
  fancyShow,
  shoeOdss,
  marketId,
  marketName,
  limitValidation,
  category,
}: MatchSettingProps) => {
  const [formData, setFormData] = useState<any>({
    market_min_stack: "",
    market_max_stack: "",
    market_min_odds_rate: "",
    market_max_odds_rate: "",
    market_max_profit: "",
    market_advance_bet_stake: "",
    min_volume_limit: "",
    inplay_max_volume_stake_0_10: "",
    inplay_max_volume_stake_10_40: "",
    inplay_max_volume_stake_40: "",
    max_volume_stake_0_10: "",
    max_volume_stake_10_40: "",
    max_volume_stake_40: "",
    session_min_stack: "",
    session_max_stack: "",
    session_max_profit: "",
    market_bookmaker_min_odds_rate: "",
    market_bookmaker_max_odds_rate: "",
    market_back_rate_range: "",
    market_lay_rate_range: "",
  });
  const [changedFields, setChangedFields] = useState<{ [key: string]: any }>(
    {}
  );
  const [tooltipMsg, setTooltipMsg] = useState<{ [key: string]: string }>({});
  const [showTooltip, setShowTooltip] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const isNumber = (value: number | null | undefined) =>
    !isNaN(Number(value)) && value != null;

  useEffect(() => {
    if (limits) {
      setFormData(
        Object.fromEntries(
          Object.entries(limits).map(([key, value]) => {
            let newValue =
              value?.toString() || (key === "min_volume_limit" ? "0" : "");

            if (
              isNumber(category) &&
              [
                "session_min_stack",
                "session_max_stack",
                "session_max_profit",
              ].includes(key)
            ) {
              newValue = "";
            }

            return [key, newValue];
          })
        )
      );
    } else {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        session_min_stack: "",
        session_max_stack: "",
        session_max_profit: "",
      }));
    }
    setErrors({});
  }, [limits, category, show]);

  const [advanceMarket, setAdvanceMarket] = useState(true);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const updatedValue = value.trim() === "" ? "" : Number(value);
    setFormData({ ...formData, [name]: updatedValue });
    setChangedFields((prev) => ({ ...prev, [name]: updatedValue }));
    validateField(
      name,
      value,
      tooltipMsg,
      showTooltip,
      limitValidation,
      formData,
      setErrors,
      setTooltipMsg,
      setShowTooltip
    );
  };

  const token = localStorage.getItem("token");
  const getUpdateLimits = async (data: any) => {
    try {
      const response = await fetch(`${baseURL}/event/updateLimites`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          [marketId]: id || "",
          values: data,
          ...(isNumber(category) && { category: Number(category) }),
        }),
      });

      const result = await response.json();

      if (response.ok && result.status) {
        snackbarUtil.success(result.msg);
        setChangedFields({});
        clickHandler();
        getLimit();
      } else {
        snackbarUtil.error(result.msg || "An error occurred");
      }
    } catch (error) {
      snackbarUtil.error("Network error. Please try again later.");
    }
  };

  const handleSave = () => {
    const fieldNames = Object.keys(formData);
    let hasErrors = false;

    fieldNames.forEach((field) => {
      if (marketId === "market_id" && field.includes("session_")) {
        return;
      }
      if (marketId === "fancy_id" && !field.includes("session_")) {
        return;
      }
      if (!formData[field] && field !== "min_volume_limit") {
        validateField(
          field,
          formData[field],
          tooltipMsg,
          showTooltip,
          limitValidation,
          formData,
          setErrors,
          setTooltipMsg,
          setShowTooltip
        );
        hasErrors = true;
      }

      if (tooltipMsg[field]) {
        hasErrors = true;
      }
    });

    if (!hasErrors && Object.keys(changedFields).length > 0) {
      getUpdateLimits(changedFields);
    } else {
      snackbarUtil.info(
        "No changes detected. Please update some fields before saving."
      );
    }
  };

  const handleClose = () => {
    clickHandler();
    setChangedFields({});
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-3 pb-md-0">
        <div>{marketName}</div>
        <Button variant="light btn-sm" onClick={handleClose}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="g-3">
            {shoeOdss && (
              <>
                <h5 className="text-primary mb-0">Market Setting</h5>
                <OverlayTrigger
                  show={!!showTooltip?.market_min_stack}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.market_min_stack}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="market_min_stack"
                        type="number"
                        id="MinStakeAmount"
                        placeholder="Min. Stake Amount"
                        value={formData.market_min_stack}
                        onChange={handleInputChange}
                        isInvalid={!!errors.market_min_stack}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.market_min_stack}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="MinStakeAmount">
                        Min. Stake Amount
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.market_max_stack}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.market_max_stack}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="market_max_stack"
                        type="number"
                        id="MaxStakeAmount"
                        placeholder="Max. Stake Amount"
                        value={formData.market_max_stack}
                        onChange={handleInputChange}
                        isInvalid={!!errors.market_max_stack}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.market_max_stack}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="MaxStakeAmount">
                        Max. Stake Amount
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>

                <OverlayTrigger
                  show={!!showTooltip?.market_min_odds_rate}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.market_min_odds_rate}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="market_min_odds_rate"
                        type="number"
                        id="MinOddsRate"
                        placeholder="Min. Odds Rate"
                        value={formData.market_min_odds_rate}
                        onChange={handleInputChange}
                        isInvalid={!!errors.market_min_odds_rate}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.market_min_odds_rate}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="MinOddsRate">
                        Min. Odds Rate
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.market_max_odds_rate}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.market_max_odds_rate}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="market_max_odds_rate"
                        type="number"
                        id="MaxOddsRate"
                        placeholder="Max. Odds Rate"
                        value={formData.market_max_odds_rate}
                        onChange={handleInputChange}
                        isInvalid={!!errors.market_max_odds_rate}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.market_max_odds_rate}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="MaxOddsRate">
                        Max. Odds Rate
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                {(marketName == "Bookmaker" || marketId === "match_id") && (
                  <>
                    <OverlayTrigger
                      show={!!showTooltip?.market_bookmaker_min_odds_rate}
                      placement="top"
                      overlay={
                        <Tooltip>
                          {tooltipMsg.market_bookmaker_min_odds_rate}
                        </Tooltip>
                      }
                    >
                      <Col lg={3}>
                        <div className="form-floating">
                          <Form.Control
                            name="market_bookmaker_min_odds_rate"
                            type="number"
                            id="MinStakeAmount"
                            placeholder="Market Bookmaker min odds rate"
                            value={formData.market_bookmaker_min_odds_rate}
                            onChange={handleInputChange}
                            isInvalid={!!errors.market_bookmaker_min_odds_rate}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSave();
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.market_bookmaker_min_odds_rate}
                          </Form.Control.Feedback>
                          <Form.Label htmlFor="MinStakeAmount">
                            Min. Bookmaker odds rate
                          </Form.Label>
                        </div>
                      </Col>
                    </OverlayTrigger>
                    <OverlayTrigger
                      show={!!showTooltip?.market_bookmaker_max_odds_rate}
                      placement="top"
                      overlay={
                        <Tooltip>
                          {tooltipMsg.market_bookmaker_max_odds_rate}
                        </Tooltip>
                      }
                    >
                      <Col lg={3}>
                        <div className="form-floating">
                          <Form.Control
                            name="market_bookmaker_max_odds_rate"
                            type="number"
                            id="MaxStakeAmount"
                            placeholder="Max.  Bookmaker odds rate"
                            value={formData.market_bookmaker_max_odds_rate}
                            onChange={handleInputChange}
                            isInvalid={!!errors.market_bookmaker_max_odds_rate}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSave();
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.market_bookmaker_max_odds_rate}
                          </Form.Control.Feedback>
                          <Form.Label htmlFor="MaxStakeAmount">
                            Max. Bookmaker odds rate
                          </Form.Label>
                        </div>
                      </Col>
                    </OverlayTrigger>

                    <OverlayTrigger
                      show={!!showTooltip?.market_back_rate_range}
                      placement="top"
                      overlay={
                        <Tooltip>{tooltipMsg.market_back_rate_range}</Tooltip>
                      }
                    >
                      <Col lg={3}>
                        <div className="form-floating">
                          <Form.Control
                            name="market_back_rate_range"
                            type="number"
                            id="MinOddsRate"
                            placeholder="Market Back Rate"
                            value={formData.market_back_rate_range}
                            onChange={handleInputChange}
                            isInvalid={!!errors.market_back_rate_range}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSave();
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.market_back_rate_range}
                          </Form.Control.Feedback>
                          <Form.Label htmlFor="MinOddsRate">
                            Market Back Rate
                          </Form.Label>
                        </div>
                      </Col>
                    </OverlayTrigger>
                    <OverlayTrigger
                      show={!!showTooltip?.market_lay_rate_range}
                      placement="top"
                      overlay={
                        <Tooltip>{tooltipMsg.market_lay_rate_range}</Tooltip>
                      }
                    >
                      <Col lg={3}>
                        <div className="form-floating">
                          <Form.Control
                            name="market_lay_rate_range"
                            type="number"
                            id="MaxOddsRate"
                            placeholder="Market Lay Rate"
                            value={formData.market_lay_rate_range}
                            onChange={handleInputChange}
                            isInvalid={!!errors.market_lay_rate_range}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSave();
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.market_lay_rate_range}
                          </Form.Control.Feedback>
                          <Form.Label htmlFor="MaxOddsRate">
                            Market Lay Rate
                          </Form.Label>
                        </div>
                      </Col>
                    </OverlayTrigger>
                  </>
                )}

                <OverlayTrigger
                  show={!!showTooltip?.market_max_profit}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.market_max_profit}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="market_max_profit"
                        type="number"
                        id="MarketMaxProfit"
                        placeholder="Market Max Profit"
                        value={formData.market_max_profit}
                        onChange={handleInputChange}
                        isInvalid={!!errors.market_max_profit}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.market_max_profit}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="MarketMaxProfit">
                        Market Max Profit
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.market_advance_bet_stake}
                  placement="top"
                  overlay={
                    <Tooltip>{tooltipMsg.market_advance_bet_stake}</Tooltip>
                  }
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="market_advance_bet_stake"
                        type="number"
                        id="InplayMatchStake"
                        placeholder="Before Inplay Match Stake"
                        value={formData.market_advance_bet_stake}
                        onChange={handleInputChange}
                        isInvalid={!!errors.market_advance_bet_stake}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.market_advance_bet_stake}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="InplayMatchStake">
                        Before Inplay Match Stake
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.min_volume_limit}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.min_volume_limit}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="min_volume_limit"
                        type="number"
                        id="MinVolumeStake"
                        placeholder="Min Volume Stake"
                        value={formData.min_volume_limit}
                        onChange={handleInputChange}
                        isInvalid={!!errors.min_volume_limit}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.min_volume_limit}
                      </Form.Control.Feedback>
                      <Form.Label htmlFor="MinVolumeStake">
                        Min Volume Stake
                      </Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>

                <div className="d-flex">
                  <h5 className="text-primary mb-0">
                    Advance Market Stake Setting
                  </h5>
                  <ToggleSwitch
                    On="On"
                    Off="Off"
                    checked={advanceMarket}
                    className="ms-2"
                    onChange={() => setAdvanceMarket(!advanceMarket)}
                  />
                </div>
                <OverlayTrigger
                  show={!!showTooltip?.inplay_max_volume_stake_0_10}
                  placement="top"
                  overlay={
                    <Tooltip>{tooltipMsg.inplay_max_volume_stake_0_10}</Tooltip>
                  }
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="inplay_max_volume_stake_0_10"
                        type="number"
                        placeholder="Max Stake (0-10k gbp)"
                        value={formData.inplay_max_volume_stake_0_10}
                        onChange={handleInputChange}
                        isInvalid={!!errors.inplay_max_volume_stake_0_10}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.inplay_max_volume_stake_0_10}
                      </Form.Control.Feedback>
                      <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.inplay_max_volume_stake_10_40}
                  placement="top"
                  overlay={
                    <Tooltip>
                      {tooltipMsg.inplay_max_volume_stake_10_40}
                    </Tooltip>
                  }
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="inplay_max_volume_stake_10_40"
                        type="number"
                        placeholder="Max Stake (10k-40k gbp)"
                        value={formData.inplay_max_volume_stake_10_40}
                        onChange={handleInputChange}
                        isInvalid={!!errors.inplay_max_volume_stake_10_40}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.inplay_max_volume_stake_10_40}
                      </Form.Control.Feedback>
                      <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>

                <OverlayTrigger
                  show={!!showTooltip?.inplay_max_volume_stake_40}
                  placement="top"
                  overlay={
                    <Tooltip>{tooltipMsg.inplay_max_volume_stake_40}</Tooltip>
                  }
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="inplay_max_volume_stake_40"
                        type="number"
                        placeholder="Max Stake (>40k gbp)"
                        value={formData.inplay_max_volume_stake_40}
                        onChange={handleInputChange}
                        isInvalid={!!errors.inplay_max_volume_stake_40}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.inplay_max_volume_stake_40}
                      </Form.Control.Feedback>
                      <Form.Label>Max Stake ({">40k gbp"})</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>

                <h5 className="text-primary mb-0">Market Stake Setting</h5>
                <OverlayTrigger
                  show={!!showTooltip?.max_volume_stake_0_10}
                  placement="top"
                  overlay={
                    <Tooltip>{tooltipMsg.max_volume_stake_0_10}</Tooltip>
                  }
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="max_volume_stake_0_10"
                        type="number"
                        placeholder="Max Stake (0-10k gbp)"
                        value={formData.max_volume_stake_0_10}
                        onChange={handleInputChange}
                        isInvalid={!!errors.max_volume_stake_0_10}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.max_volume_stake_0_10}
                      </Form.Control.Feedback>
                      <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.max_volume_stake_10_40}
                  placement="top"
                  overlay={
                    <Tooltip>{tooltipMsg.max_volume_stake_10_40}</Tooltip>
                  }
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="max_volume_stake_10_40"
                        type="number"
                        placeholder="Max Stake (10k-40k gbp)"
                        value={formData.max_volume_stake_10_40}
                        onChange={handleInputChange}
                        isInvalid={!!errors.max_volume_stake_10_40}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.max_volume_stake_10_40}
                      </Form.Control.Feedback>
                      <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.max_volume_stake_40}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.max_volume_stake_40}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="max_volume_stake_40"
                        type="number"
                        placeholder="Max Stake (>40k gbp)"
                        value={formData.max_volume_stake_40}
                        onChange={handleInputChange}
                        isInvalid={!!errors.max_volume_stake_40}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.max_volume_stake_40}
                      </Form.Control.Feedback>
                      <Form.Label>Max Stake ({">40k gbp"})</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
              </>
            )}
            {fancyShow && (
              <>
                <h5 className="text-primary mb-0">Session Setting</h5>
                <OverlayTrigger
                  show={!!showTooltip?.session_min_stack}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.session_min_stack}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="session_min_stack"
                        type="number"
                        placeholder="Session Min Stake"
                        value={formData.session_min_stack}
                        onChange={handleInputChange}
                        isInvalid={!!errors.session_min_stack}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.session_min_stack}
                      </Form.Control.Feedback>
                      <Form.Label>Session Min Stake</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.session_max_stack}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.session_max_stack}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="session_max_stack"
                        type="number"
                        placeholder="Session Max Stake"
                        value={formData.session_max_stack}
                        onChange={handleInputChange}
                        isInvalid={!!errors.session_max_stack}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.session_max_stack}
                      </Form.Control.Feedback>
                      <Form.Label>Session Max Stake</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  show={!!showTooltip?.session_max_profit}
                  placement="top"
                  overlay={<Tooltip>{tooltipMsg.session_max_profit}</Tooltip>}
                >
                  <Col lg={3}>
                    <div className="form-floating">
                      <Form.Control
                        name="session_max_profit"
                        type="number"
                        placeholder="Session Max Profit"
                        value={formData.session_max_profit}
                        onChange={handleInputChange}
                        isInvalid={!!errors.session_max_profit}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.session_max_profit}
                      </Form.Control.Feedback>
                      <Form.Label>Session Max Profit</Form.Label>
                    </div>
                  </Col>
                </OverlayTrigger>
              </>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top">
        <Button className="mx-2" onClick={handleSave}>
          Save
        </Button>
        <Button variant="light" className="mx-2" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default MatchSetting;
