import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TableContainer from "../TableContainer";
import { Button, Form, Modal } from "react-bootstrap";
import moment from "moment";
import TableEntry from "Common/TableEntry";
import { ListPagination } from "Common/ListPagination";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  betsDetails: BetsData | undefined;
  setPayloadData: Dispatch<
    SetStateAction<{
      bet_id: string;
      user_id: string;
      is_void: boolean;
      is_fancy: number;
      password: string;
    }>
  >;
  paylodData: {
    bet_id: string;
    user_id: string;
    is_void: boolean;
    is_fancy: number;
    password: string;
  };
  getBetsData: any;

  setFormValuesFilter: any;
  formValuesFilter: any;
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
  currentPage: number;
  handleUser: (selectedOption: any) => void;
  userNameData: any[];
  userName: string;
  handleSelectUser: any;
  setUser_name: any;
  useridType: any;
  domainList: DomainListPayload[];
  domainId: string;
  handleDomain: (value: any) => void;
  betcount: number;
}

interface BetItem {
  bet_id: string;
  user_id: string;
  is_void: boolean;
  is_fancy: number; // 0 or 1
}

const MatchDetailTable: FC<Props> = ({
  formValuesFilter,
  setFormValuesFilter,
  getBetsData,
  currentPage,
  setPayloadData,
  paylodData,
  betsDetails,
  handleUser,
  page,
  limit,
  listTotal,
  handlePageFilter,
  userNameData,
  userName,
  handleSelectUser,
  setUser_name,
  useridType,
  domainList,
  domainId,
  handleDomain,
  betcount,
}) => {
  const [active, setActive] = useState(false);
  const [showPassword, setPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = useState("");
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [selectedItems, setSelectedItems] = useState<BetItem[]>([]);
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [showBetAddress, setBetAddress] = useState(null);
  const [oddsRange, setOddsRange] = useState({ from: 0, to: 0 });
  const [rateRange, setRateRange] = useState({ from: 0, to: 0 });
  const [fixedCountryData, setFixedCountryData] = useState<any>(null);
  const ToggleBetAddress = (betId: any) => {
    setBetAddress((prevBetId) => (prevBetId === betId ? null : betId));
  };

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setBetAddress(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDeleteBet = async (is_void: boolean) => {
    const { response } = await authServices.getDeleteBet({
      ...paylodData,
      is_void,
    });
    if (response) {
      if (response?.status) {
        snackbarUtil.success(response?.msg);
        setActive(false);
        getBetsData(limit, currentPage);
      } else {
        snackbarUtil.error(response?.msg);
      }
    }
  };

  useEffect(() => {
    setFormValuesFilter((prev: any) => ({
      ...prev,
      amount_from: oddsRange?.from,
      amount_to: oddsRange?.to,
    }));
  }, [oddsRange]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const filteredData = useMemo(() => {
    if (!betsDetails || !betsDetails.data) return [];

    const lowercasedSearchValue = searchValue.toLowerCase();
    return betsDetails.data.filter(
      (item) =>
        item.user_name.toLowerCase().includes(lowercasedSearchValue) ||
        item.domain_name.toLowerCase().includes(lowercasedSearchValue) ||
        item.market_name.toLowerCase().includes(lowercasedSearchValue) ||
        item.selection_name.toLowerCase().includes(lowercasedSearchValue)
    );
  }, [betsDetails, searchValue]);

  const handleOpenModals = (
    bet_id: string,
    user_id: string,
    is_fancy: number
  ) => {
    setPayloadData((prev) => ({
      ...prev,
      bet_id,
      user_id,
      is_fancy,
    }));
    setActive(true);
    setIsMultiple(false);
  };

  const handlePassValue = (e: any) => {
    setPayloadData((prev) => ({
      ...prev,
      password: e.target.value,
    }));
    setValidated(false);
  };

  const handleDeletedBet = (is_void: boolean) => {

    if (paylodData?.password.trim() === "") {
      setValidated(true);
      return;
    }
    // setPayloadData((prev) => ({
    //   ...prev,
    //   is_void,
    // }));
    getDeleteBet(is_void);
  };

  const handleCheckboxChange = (updatedBet: BetItem) => {
    setSelectedItems((prev) => {
      const exists = prev.some((bet) => bet.bet_id === updatedBet.bet_id);
      if (exists) {
        // If the object exists, remove it
        return prev.filter((bet) => bet.bet_id !== updatedBet.bet_id);
      } else {
        // If the object does not exist, add it
        return [...prev, updatedBet];
      }
    });
  };

  const isSelected = (betId: string): boolean => {
    return selectedItems.some((bet) => bet.bet_id === betId);
  };

  const deleteBets = async (payload: DeleteBetsPayload) => {
    const { response } = await authServices.deleteBets(payload);
    if (response) {
      if (response?.status) {
        snackbarUtil.success(response?.msg);
        setActive(false);
        setSelectedItems([]);
        getBetsData(limit, currentPage);
      } else {
        snackbarUtil.error(response?.msg);
      }
    }
  };

  const user_Detail = localStorage.getItem("adminDetails");
    let userIds = user_Detail && JSON.parse(user_Detail || '')?._id;
  const handleDeletedBets = () => {
    if (paylodData?.password.trim() === "") {
      setValidated(true);
      return;
    }
    
    const updatedItems = selectedItems.map(item => ({
      ...item,
      is_void: false,
    }));

    deleteBets({
      user_id: userIds,
      password: paylodData.password,
      data: updatedItems,
    });
  };
  const handleVoidBets = () => {
    if (paylodData?.password.trim() === "") {
      setValidated(true);
      return;
    }
  
    const updatedItems = selectedItems.map(item => ({
      ...item,
      is_void: true,
    }));
  
    deleteBets({
      user_id: userIds,
      password: paylodData.password,
      data: updatedItems,
    });
  };
  



  const columns = useMemo(
    () => [
      // {
      //   header:
      // selectedItems?.length > 1 ? (
      //   <i
      //     className="cursor-pointer text-danger ri-delete-bin-line ms-2"
      //     onClick={() => {
      //       setActive(true);
      //       setIsMultiple(true);
      //     }}
      //   ></i>
      // ) : (
      //   ""
      // ),
      //   accessorKey: "action",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => {
      //     const bet = {
      //       bet_id: cell?.row?.original?.bet_id,
      //       user_id: cell?.row?.original?.user_id,
      //       is_void: cell?.row?.original?.delete_status === 2,
      //       is_fancy: cell.row.original.is_fancy,
      //     };
      //     return (
      //       <span>
      //         <div className="form-check form-check-dark">
      //           {cell?.row?.original?.delete_status === 0 &&
      //             useridType === 0 && (
      //               <Form.Check
      //                 type="checkbox"
      //                 id="openUser"
      //                 checked={isSelected(bet.bet_id)}
      //                 onChange={() => handleCheckboxChange(bet)}
      //               />
      //             )}
      //         </div>
      //       </span>
      //     );
      //   },
      // },
      {
        header: selectedItems?.length > 1 ? (
          <>
          <i
            className="cursor-pointer text-danger ri-delete-bin-line ms-2"
            onClick={() => {
              setActive(true);
              setIsMultiple(true);
            }}
            ></i><span style={{marginLeft:"2px"}}>No.</span>
          </>
        ) : ("No."),
        accessorKey: "sNo",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const serialNumber = betcount - limit * (page - 1) - cell.row.index;
          const bet = {
            bet_id: cell?.row?.original?.bet_id,
            user_id: cell?.row?.original?.user_id,
            is_void: cell?.row?.original?.delete_status === 2,
            is_fancy: cell.row.original.is_fancy,
          };
          return (
            <span>
              <div className="text-center m-0 p-0">

              {serialNumber}
              </div>
              <div className="d-flex align-items-center gap-0 justify-content-center">
                <span>
                  <div className="form-check form-check-dark">
                    {cell?.row?.original?.delete_status === 0 &&
                      useridType === 0 && (
                        <Form.Check
                          type="checkbox"
                          id="openUser"
                          checked={isSelected(bet.bet_id)}
                          onChange={() => handleCheckboxChange(bet)}
                        />
                      )}
                  </div>
                </span>
                {cell?.row?.original?.delete_status === 0 &&
                  useridType === 0 && (
                    <i
                      id={serialNumber.toString()}
                      onClick={() =>
                        handleOpenModals(
                          cell?.row?.original?.bet_id,
                          cell?.row?.original?.user_id,
                          cell.row.original.is_fancy
                        )
                      }
                      className="cursor-pointer text-danger ri-delete-bin-line ms-2"
                    ></i>
                  )}

                {cell?.row?.original?.delete_status === 1 &&
                  useridType === 0 && <span>D</span>}
                {cell?.row?.original?.delete_status === 2 &&
                  useridType === 0 && <span>V</span>}
              </div>
            </span>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "user_name",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => {
          const name = cell?.row?.original?.user_name
            ?.replace(/\(\w+\)$/, "")
            .trim();
          return (
            <div>
              <span
                className="cursor-pointer text-primary"
                onClick={() => {
                  toggleList(cell?.row?.original?.user_name);
                  getUserAgent(cell?.row?.original?.user_id);
                }}
              >
                {name}
              </span>
              <i
                className="ri-file-copy-line cursor-pointer ms-1"
                onClick={() => {
                  const userName = cell?.row?.original?.user_name;
                  const usernameOnly = userName.split("(")[0].trim();
                  if (userName) {
                    navigator.clipboard
                      .writeText(usernameOnly)
                      .then(() => {
                        snackbarUtil.success("Copy Successfull!");
                      })
                      .catch((err) => {
                        console.error("Error copying text: ", err);
                      });
                  }
                }}
              ></i>{" "}
            </div>
          );
        },
      },
      {
        header: <div>Market | Selection</div>,
        accessorKey: "is_back",
        enableColumnFilter: true,
        enableSorting: true,

        cell: (cell: any) => {
          const name = cell?.row?.original?.market_name
            ?.replace(/\((\d+_\d+|\d+\.\d+)\)$/, "")
            ?.replace(/\(\d+\s*\)$/, "")
            .trim();
          const selectionName = cell?.row?.original?.selection_name
            ?.replace(/\((\d+_\d+|\d+\.\d+)\)$/, "")
            ?.replace(/\(\d+\s*\)$/, "")
            .trim();
          return (
            <>
              <div style={{width:"150px", whiteSpace:"normal"}}>{name}</div>
              <div>{selectionName}</div>
            </>
          );
        },
      },
      {
        header: <div>Rate</div>,
        accessorKey: "OddsRate",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div>
              {cell?.row?.original?.odds}
              <span>{cell?.row?.original?.is_fancy == 1 && `/${cell?.row?.original?.size}`}</span>
              
            </div>
          </>
        ),
      },
      {
        header: <div>Stake</div>,
        accessorKey: "oddsStack",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div>{cell?.row?.original?.stack}</div>
          </>
        ),
      },
      {
        header: <div>Liability</div>,
        accessorKey: "liability",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <span>
              <span
                className={`${
                  cell?.row?.original?.liability < 0
                    ? "text-danger"
                    : "text-secondary"
                } me-2`}
              >
                {cell?.row?.original?.liability?.toFixed(2)}
              </span>
            </span>

            {/* <div>
              {moment(cell?.row?.original?.createdAt).format(
                "DD-MM-YY hh:mm:ss:SSS A"
              )}
            </div> */}
          </>
        ),
      },

     
      // {
      //   header: "Type",
      //   accessorKey: "is_back",
      //   enableColumnFilter: true,
      //   enableSorting: true,
      //   cell: (cell: any) => (
      //     <Button
      //       variant="sm"
      //       className={
      //         cell?.row?.original?.is_back === 1 ? "btn-back" : "btn-lay"
      //       }
      //     >
      //       {cell?.row?.original?.is_back === 1 ? "Back" : "Lay"}
      //     </Button>
      //   ),
      // },
      
      {
        header: <div>Placed Time</div>,
        accessorKey: "createdAt",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div>
              <p className="m-0">
                {moment(cell?.row?.original?.createdAt).format("DD-MM-YYYY")}
              </p>
              <p className="m-0">
                {moment(cell?.row?.original?.createdAt).format(
                  "hh:mm:ss:SSS A"
                )}
              </p>
            </div>
          </>
        ),
      },

      {
        header: "Domain",
        accessorKey: "DomainName",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div>{cell?.row?.original?.domain_name}</div>
          </>
        ),
      },

      {
        header: " IP Address",
        accessorKey: "ip_address",
        enableColumnFilter: true,
        enableSorting: true,
        cell: (cell: any) => {
          const handleMouseEnter = () => {
            if (!fixedCountryData) {
              const countryData =
                cell?.row?.original?.geolocation &&
                (typeof cell.row.original.geolocation === "string"
                  ? JSON.parse(cell.row.original.geolocation)
                  : cell.row.original.geolocation);

              setFixedCountryData(countryData);
              setBetAddress(cell?.row?.original?.bet_id);
            }
          };

          const handleMouseLeave = () => {
            setFixedCountryData(null);
          };

          // const countryData =
          //   cell?.row?.original?.geolocation &&
          //   (typeof cell.row.original.geolocation === "string"
          //     ? JSON.parse(cell.row.original.geolocation)
          //     : cell.row.original.geolocation);
          return (
            <>
              <div>
                <i
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="bi bi-globe me-1 cursor-pointer"
                  style={{ verticalAlign: "middle" }}
                ></i>
                {cell?.row?.original?.ip_address}
              </div>
              {fixedCountryData &&
                showBetAddress === cell?.row?.original?.bet_id && (
                  <div
                    ref={tooltipRef}
                    className="bg-white p-3 rounded position-absolute "
                    style={{
                      right: "20px",
                      minWidth: "200px",
                      zIndex: "999",
                      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                    }}
                  >
                    <div className="d-flex justify-content-between align-item-center mb-1">
                      <p className="fw-bold m-0">City : </p>
                      <p className="p-0 m-0"> {fixedCountryData.city}</p>
                    </div>
                    <div className="d-flex justify-content-between p-0 align-item-center mb-1">
                      <p className="fw-bold m-0">State : </p>
                      <p className="m-0"> {fixedCountryData.state}</p>
                    </div>
                    <div className="d-flex justify-content-between p-0 align-item-center">
                      <p className="fw-bold m-0">Country : </p>
                      <p className="m-0"> {fixedCountryData.country}</p>
                    </div>
                  </div>
                )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [page, limit, betcount, selectedItems, isSelected]
  );

  const handleOddsChange = (key: string, value: any) => {
    setOddsRange((prev) => ({ ...prev, [key]: value }));
  };

  const handleRateChange = (key: string, value: any) => {
    setRateRange((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <React.Fragment>
      <TableContainer
        customPageSize={limit}
        columns={columns}
        data={filteredData || []}
        isPagination={false}
        isBordered={false}
        sorting={false}
        isFilter={true}
        tableClass="table-centered align-middle mt-3 mb-0 react-table table-striped table-nowrap matchDetailAllBet"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        rowClass={"backBorder"}
        showBck={true}
        tdClass="text-dark"
        handleUser={handleUser}
        setFormValuesFilter={setFormValuesFilter}
        formValuesFilter={formValuesFilter}
        userNameData={userNameData}
        userName={userName}
        handleSelectUser={handleSelectUser}
        setUser_name={setUser_name}
        domainList={domainList}
        domainId={domainId}
        handleDomain={handleDomain}
        handleOddsChange={handleOddsChange}
        handleRateChange={handleRateChange}
        oddsRange={oddsRange}
        rateRange={rateRange}
        cName="match_bets"
      />

      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(1, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={active}
        className="zoomIn"
        scrollable
        onHide={() => setActive(false)}
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Delete Bet{isMultiple && "s"}</span>
          <Button variant="light btn-sm" onClick={() => setActive(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center ">
          <Form noValidate>
            <div className="form-floating">
              <Form.Control
                isInvalid={validated && paylodData?.password?.trim() === ""}
                name="password"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={paylodData?.password}
                onChange={handlePassValue}
                required
              />
              <Form.Label>Enter New Password</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPassword(!showPassword)}
              >
                <i
                  className={`${
                    showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                  } align-middle`}
                ></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                Please enter your password.
              </Form.Control.Feedback>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="mx-2"
            onClick={() => setActive(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="mx-2"
            onClick={() =>
              isMultiple ? handleDeletedBets() : handleDeletedBet(false)
            }
          >
            Delete
          </Button>
          
            <Button className="mx-2"  onClick={() =>
              isMultiple ? handleVoidBets() : handleDeletedBet(true)
            }>
              Void
            </Button>
         
        </Modal.Footer>
      </Modal>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">
              ({selectedUser})
            </span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.map((item) => {
            return (
              <p>
                {item.name}({item.user_name})
              </p>
            );
          })}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MatchDetailTable;
