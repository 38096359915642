import DepositModal from "Common/Modals/DepositModal";
import EditCreditReference from "Common/Modals/EditCreditReference";
import ExposureModal from "Common/Modals/ExposureModal";
import ResetPasswordModal from "Common/Modals/ResetPasswordModal";
import ShowPasswordModal from "Common/Modals/ShowPasswordModal";
import WithdrawModal from "Common/Modals/WithdrawModal";
import TableContainer from "Common/TableContainer";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommissionSetting from "./CommissionSetting";
import PartnershipModal from "Common/Modals/PartnershipModal";
import UserMarketLimit from "Common/Modals/UserMarketLimit";
import EditStakeModal from "Common/Modals/EditStakeModal";
import PermissionModal from "Common/Modals/PermissionModal";
import ParentList from "Common/Modals/ParentList";
import TableEntry from "Common/TableEntry";
import { ListPagination } from "Common/ListPagination";
import snackbarUtil from "Utils/snackBarUtil";
import { authServices } from "Utils/auth/services";
import AllowB2CModal from "Common/Modals/AllowB2CModal";
import AllowSocailModal from "Common/Modals/AllowSocialModal";
import AddScreenShot from "Common/Modals/AddScreenShot";

interface Props {
  userData: ChildLevelDetail[] | undefined;
  getUserList: () => Promise<void>;
  setUserId: Dispatch<SetStateAction<string>>;
  setLimit: Dispatch<SetStateAction<number>>;
  totalResult: any;
  limit: number;
  totalPages: any;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  totalData: any;
  setFormValuesFilter: Dispatch<SetStateAction<{}>>;
  formValuesFilter: {};
  domainList: DomainListPayload[];
  domainId: string;
  handleDomain: (selectedOption: any) => void;
  handleLevel: (e: any) => void;
  levelId: string | number;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setUserData: Dispatch<SetStateAction<ChildLevelDetail[] | undefined>>;
  handleNavigate: (user_id: string, user_name: string) => void;
  handleShowCRHistory: (name: string, user_id: string) => void;
  columnsDateState: {
    name: string;
    show: boolean;
  }[];
}

const userTypes: Record<number, string> = {
  0: "Super Admin",
  1: "Client",
  2: "Dealer",
  3: "Master",
  4: "Super",
  5: "Senior Super",
  6: "Hyper",
  7: "Sub Admin",
  8: "White Label",
  9: "Agent-9",
};

const UserListTable: FC<Props> = ({
  domainId,
  handleDomain,
  setFormValuesFilter,
  domainList,
  formValuesFilter,
  totalData,
  userData,
  getUserList,
  setUserId,
  setLimit,
  totalResult,
  limit,
  totalPages,
  setCurrentPage,
  currentPage,
  handleLevel,
  levelId,
  setUserData,
  handleNavigate,
  handleShowCRHistory,
  columnsDateState,
  setIsLoading,
}) => {
  const [showCreditReference, setCreditReference] = useState(false);
  const [showExposure, setExposure] = useState(false);
  const [showDeposit, setDeposit] = useState(false);
  const [showCommSet, setShowCommSet] = useState(false);
  const [showWithdraw, setWithdraw] = useState(false);
  const [showPasswordModal, setPasswordModal] = useState(false);
  // const [showPermissionSetting, setPermissionSetting] = useState(false);
  const [showResetPasswordModal, setshowResetPasswordModal] = useState(false);
  const [userIds, setUserIds] = useState<string>("");
  const [showPartnership, setPartnership] = useState(false);
  const [showMarketLimit, setMarketLimit] = useState(false);
  const [showEditStake, setEditStake] = useState(false);
  const [showPermission, setPermission] = useState(false);
  const [creditRef, setCreditRef] = useState(0);
  const [userName, setUserName] = useState("");
  const [dataUser, setDataUser] = useState();
  const [settingData, setSettingData] = useState("");
  const [showParentList, setShowParentList] = useState(false);
  const [depositWithData, setDepositWithData] = useState(0);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [showB2C, setB2c] = useState(false);
  const [showSocial, setSocial] = useState(false);
  const [addScreen, setAddScreen] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    utr: "",
    image: "",
  });
  const [loginPasswordError, setLoginPasswordError] = useState<string>("");
  const [showPassModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [socialAllowed, setSocialAllowed] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setPassword(value);
    setLoginPasswordError(
      value.trim() === "" ? "Password cannot be empty." : ""
    );
  };

  const validateAndSubmit = (): boolean => {
    if (!password.trim()) {
      setLoginPasswordError("Password cannot be empty.");
      return false;
    }
    setLoginPasswordError("");
    return true;
  };

  const handleChildUserList = (ids: string) => {
    setIsLoading(true);
    setFormValuesFilter({});
    setUserId(ids);
  };

  const toggleCreditReference = (cr: string, id: string, name: string) => {
    setCreditRef(Number(cr));
    setUserIds(id);
    setUserName(name);
    setCreditReference(!showCreditReference);
  };
  // const togglePermissionSetting = (id: string) => {
  //     setUserIds(id);
  //     setPermissionSetting(!showPermissionSetting);
  // };
  const toggleExposure = (id: string, name: string) => {
    setUserIds(id);
    setExposure(!showExposure);
    setUserName(name);
  };
  const toggleDeposit = (id: string, name: string) => {
    setUserName(name);
    setUserIds(id);
    setDeposit(!showDeposit);
  };
  const [domainIds, setDomainId] = useState<string>("");
  const toggleAddScreenShot = (id: string, name: string, ids: string) => {
    setUserName(name);
    setUserIds(id);
    setAddScreen(!addScreen);
    setDomainId(ids);
  };
  const toggleCopmmitionSetting = (id: string, name: string) => {
    setUserIds(id);
    setUserName(name);
    setShowCommSet(!showCommSet);
  };
  const toggleWithdraw = (id: string, name: string) => {
    setUserName(name);
    setUserIds(id);
    setWithdraw(!showWithdraw);
  };
  const toggleShowPassword = (id: string, name: string) => {
    setUserIds(id);
    setUserName(name);
    setPasswordModal(!showPasswordModal);
  };
  const toggleResetPassword = (id: string, name: string) => {
    setUserIds(id);
    setUserName(name);
    setshowResetPasswordModal(!showResetPasswordModal);
  };
  const togglePartnershipSharing = (id: string, name: string) => {
    setPartnership(!showPartnership);
    setUserIds(id);
    setUserName(name);
  };
  const toggleMarketLimit = (id: string, settingId: string, name: string) => {
    setUserIds(id);
    setSettingData(settingId);
    setUserName(name);
    setMarketLimit(!showMarketLimit);
  };
  const toggleEditStake = (id: string, name: string) => {
    setUserIds(id);
    setUserName(name);
    setEditStake(!showEditStake);
  };
  const handleParentList = (id: string, name: string) => {
    setUserIds(id);
    setUserName(name);
    setShowParentList(!showParentList);
  };
  const togglePermission = (id: string, name: string, item: any) => {
    setPermission(true);
    setUserIds(id);
    setUserName(name);
    setDataUser(item);
  };

  const toggleB2C = (id: string, name: string, item: any) => {
    setB2c(true);
    setUserIds(id);
    setUserName(name);
    setDataUser(item);
  };

  const toggleSocial = (id: string, name: string, isAllowed: boolean) => {
    setSocial(true);
    setUserIds(id);
    setUserName(name);
    setSocialAllowed(isAllowed)
  };

  const nav = useNavigate();
  const defaultData = useMemo(() => {
    if (!userData || !Array.isArray(userData)) {
      return [];
    }
    return userData?.map((items, index: number) => ({
      no: limit * (currentPage - 1) + index + 1,
      searchQuery: items?.user_name || "",
      name: items?.name || "",
      DomainName: items?.domain?.site_title || "",
      exp: items?.liability || 0,
      profitLoss: items?.profit_loss || 0,
      balance: items?.balance || 0,
      point: items?.point || 0,
      levelId: items?.user_type_id || "8",
      mobile: items?.mobile || "",
      creditReference: items?.credit_reference,
      pName: items?.parent_user_name,
      type: items?.parent_level_ids[0]?.name,
      lockUser: items?.self_lock_user,
      parentLockUser: items?.parent_lock_user,
      lockBetting: items?.self_lock_betting,
      parentLockBetting: items?.parent_lock_betting,
      userId: items?._id,
      items: items,
      userSetting: items?.userSettingSportsWise,
      p_l: items?.profit_loss,
      partnership: items?.partnership,
      domainId: items?.domain?._id,
      allow_social_media_dealer: items?.allow_social_media_dealer || false,
    }));
    // eslint-disable-next-line
  }, [userData]);

  const handleNameSave = (name: string) => {
    setUserName(name);
  };

  const [showUser, setShowUser] = useState(false);
  const [showBet, setShowBet] = useState(false);

  const [userLock, setUserLock] = useState({
    isLock: 0,
    user_id: "",
    userName: "",
  });
  const handleUserLock = (isLock: boolean, user_id: string, name: string) => {
    const action = isLock ? 0 : 1;
    setUserLock({ isLock: action, user_id, userName: name });
    setShowUser(true);
    setUserIds(user_id);
  };

  const handleUserBet = (user_id: string, lock: boolean, name: string) => {
    const action = lock ? 0 : 1;
    setShowPasswordModal(!showPassModal);
    setUserLock({ user_id, isLock: action, userName: name });
    // setShowBet(true);
  };

  const getUserLock = async (action: number, user: string) => {
    const { response } = await authServices.getUserLock({
      user_id: user,
      action,
      password,
      pass_type: "PASSWORD",
    });
    if (response) {
      if (response?.status) {
        snackbarUtil.success(response?.msg);
        updateUserLock(user, action);
        setShowUser(false);
        setPassword("");
        setShowBet(false);
        setShowPasswordModal(false);
        // getUserList();
      } else {
        snackbarUtil.error(response?.msg);
      }
    }
  };

  const getBetLock = async (user: string, action: number) => {
    const { response } = await authServices.getBetlock({
      user_id: user,
      master_password: password,
      pass_type: "PASSWORD",
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      updateBetLock(user, action);
      setShowBet(false);
      setShowPasswordModal(false);
      setPassword("");
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleCloseUserLock = () => setShowUser(false);
  // const handleCloseBetLock = () => setShowBet(false);

  const handleCancelUpdata = () => {
    setShowPasswordModal(!showPassModal);
    setPassword("");
    setShowBet(!showBet);
  };

  const handleActiveDeactive = () => {
    if (validateAndSubmit()) {
      getUserLock(userLock?.isLock, userLock?.user_id);
    }
  };

  // const handleEnableBet = () => {
  //   getBetLock(userLock?.user_id, userLock?.isLock);
  // };
  const handleUpdate = () => {
    if (validateAndSubmit()) {
      getBetLock(userLock?.user_id, userLock?.isLock);
    }
  };

  const updateUserData = (userId: string, newBalance: number) => {
    setUserData((prevData) =>
      prevData?.map((user) =>
        user._id === userId
          ? { ...user, balance: user?.balance + newBalance }
          : user
      )
    );
  };

  const updateUserCr = (userId: string, newBalance: number) => {
    setUserData((prevData) =>
      prevData?.map((user) =>
        user._id === userId ? { ...user, credit_reference: newBalance } : user
      )
    );
  };
  const updateUserLock = (userId: string, lock: number) => {
    setUserData((prevData) =>
      prevData?.map((user) =>
        user._id === userId ? { ...user, self_lock_user: lock } : user
      )
    );
  };
  const updateBetLock = (userId: string, lock: number) => {
    setUserData((prevData) =>
      prevData?.map((user) =>
        user._id === userId ? { ...user, self_lock_betting: lock } : user
      )
    );
  };

  const getWellet = async (
    image: any,
    user_id: string,
    parent_id: string,
    payment_method_id: string,
    amount: string,
    user_reference_no: string,
    remark: string,
    crdr: string
  ) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("user_id", user_id);
    formData.append("parent_id", parent_id);
    formData.append("payment_method_id", payment_method_id);
    formData.append("amount", amount);
    formData.append("user_reference_no", user_reference_no);
    formData.append("remark", remark);
    formData.append("crdr", crdr);

    try {
      const { response } = await authServices.getWellet(formData);
      if (response) {
        setAddScreen(false);
        if (response?.status) {
          snackbarUtil.success(response?.msg);
          setFormData({
            amount: "",
            utr: "",
            image: "",
          });
        } else {
          snackbarUtil.error(response?.msg);
        }
      }
    } catch (error) {
      console.error("Error submitting wallet request:", error);
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          header: "No.",
          accessorKey: "sNo",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => {
            return <div>{(currentPage - 1) * limit + cell.row.index + 1}</div>;
          },
        },
        {
          header: "User Name",
          accessorKey: "searchQuery",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => {
            const isChildLevelOne = cell.row.original.levelId === 1;
            // const isChildLevel = cell.row.original.levelId;
            return (
              <div>
                <span
                  className={!isChildLevelOne ? "cursor-pointer" : ""}
                  onClick={() =>
                    !isChildLevelOne &&
                    handleChildUserList(cell.row.original.userId)
                  }
                >
                  {cell.row.original.searchQuery}
                </span>
                {/* <br />
                            <span>({userTypes[isChildLevel]})</span> */}
              </div>
            );
          },
        },
        {
          header: "Domain Name",
          accessorKey: "DomainName",
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          header: "Level",
          accessorKey: "levelId",
          enableColumnFilter: true,
          enableSorting: true,
          cell: (cell: any) => {
            const isChildLevel = cell.row.original.levelId;
            return <span>{userTypes[isChildLevel]} </span>;
          },
        },
        {
          header: "C R",
          accessorKey: "creditReference",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => (
            <span>
              {cell.row.original.creditReference}{" "}
              <Button
                variant="sm"
                onClick={() =>
                  toggleCreditReference(
                    cell.row.original.creditReference,
                    cell.row.original.userId,
                    cell.row.original.searchQuery
                  )
                }
              >
                <i className="bx bx-edit-alt fs-lg align-middle"></i>
              </Button>
              <Button
                variant="sm"
                onClick={() =>
                  handleShowCRHistory(
                    cell.row.original.searchQuery,
                    cell.row.original.userId
                  )
                }
              >
                <i className="bx   ri-eye-line fs-lg "></i>
              </Button>
            </span>
          ),
        },
        {
          header: "Exposure",
          accessorKey: "exposure",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => (
            <span
              className={`badge cursor-pointer bg-body-secondary border border-light ${
                cell.row.original.exp < 0 ? "text-danger" : "text-secondary"
              }`}
              onClick={() =>
                toggleExposure(
                  cell.row.original.userId,
                  cell.row.original.searchQuery
                )
              }
            >
              {cell.row.original.exp?.toFixed(2)}
            </span>
          ),
        },
        {
          header: "Partnership",
          accessorKey: "partnership",
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          header: "P|L",
          accessorKey: "profitLoss",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => (
            <span
              className={`badge  bg-body-secondary border border-light ${
                cell.row.original.profitLoss < 0
                  ? "text-danger"
                  : "text-secondary"
              }`}
            >
              {" "}
              {cell.row.original.profitLoss?.toFixed(2)}
            </span>
          ),
        },
        {
          header: "Balance",
          accessorKey: "balance",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => (
            <span
              className={
                cell.row.original.balance < 0 ? "text-danger" : "text-secondary"
              }
            >
              {cell.row.original.balance?.toFixed(2)}
            </span>
          ),
        },
        {
          header:
            userDetails?.user_type_id === 2 && userDetails?.belongs_to_b2c
              ? "Add Screenshot"
              : "Credit",
          // header: "Credit",
          accessorKey: "credit",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => (
            <>
              {userDetails?.user_type_id === 2 &&
              userDetails?.belongs_to_b2c ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Add Screenshot</Tooltip>}
                >
                  <i
                    className="bx bx-image-add fs-4 text-center d-block cursor-pointer"
                    onClick={() =>
                      toggleAddScreenShot(
                        cell.row.original.userId,
                        cell.row.original.searchQuery,
                        cell.row.original?.domainId
                      )
                    }
                  />
                </OverlayTrigger>
              ) : (
                <div className="d-flex btn-group">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top"> Chips Deposit </Tooltip>
                    }
                  >
                    <Button
                      variant="success bg-gradient"
                      className="btn-sm cursor-pointer"
                      style={{ minWidth: "29px" }}
                      onClick={() =>
                        toggleDeposit(
                          cell.row.original.userId,
                          cell.row.original.searchQuery
                        )
                      }
                    >
                      D
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top"> Chips Withdraw </Tooltip>
                    }
                  >
                    <Button
                      variant="danger bg-gradient "
                      className="btn-sm cursor-pointer"
                      onClick={() =>
                        toggleWithdraw(
                          cell.row.original.userId,
                          cell.row.original.searchQuery
                        )
                      }
                    >
                      W
                    </Button>
                  </OverlayTrigger>
                </div>
              )}
            </>
          ),
        },
        {
          header: "User S",
          accessorKey: "userStatus",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => {
            const userLock =
              cell.row?.original?.lockUser !== 0 ||
              cell.row?.original?.parentLockUser !== 0;
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    {" "}
                    User Status {userLock ? "Lock" : "Unlock"}{" "}
                  </Tooltip>
                }
              >
                <div
                  className="text-center cursor-pointer"
                  onClick={() =>
                    handleUserLock(
                      userLock,
                      cell.row.original.userId,
                      cell.row.original.searchQuery
                    )
                  }
                >
                  <i
                    className={`fs-lg ${
                      userLock
                        ? "ri-lock-line text-danger"
                        : "ri-lock-unlock-line text-success"
                    }`}
                  ></i>
                </div>
              </OverlayTrigger>
            );
          },
        },
        {
          header: "Bet S",
          accessorKey: "betStatus",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cell: any) => {
            const lockBetting =
              cell.row?.original?.lockBetting !== 0 ||
              cell?.row?.original?.parentLockBetting !== 0;
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    {" "}
                    Bet Status {lockBetting ? "Lock" : "Unlock"}{" "}
                  </Tooltip>
                }
              >
                <div
                  className="text-center cursor-pointer"
                  onClick={() =>
                    handleUserBet(
                      cell.row.original.userId,
                      lockBetting,
                      cell.row.original.searchQuery
                    )
                  }
                >
                  <i
                    className={`fs-lg ${
                      lockBetting
                        ? "ri-lock-line text-danger"
                        : "ri-lock-unlock-line text-success"
                    }`}
                  ></i>
                </div>
              </OverlayTrigger>
            );
          },
        },
        {
          header: "Action",
          accessorKey: "action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (cell: any) => {
            const isChildLevelOne = cell.row.original.levelId === 1;
            const isLevelOne = cell.row.original.levelId === 8;
            const isDealer = cell.row.original.levelId === 2;
            return (
              <div>
                <Dropdown drop="start">
                  <Dropdown.Toggle
                    variant="subtle-dark"
                    className="e-caret-hide btn-sm"
                  >
                    <i
                      className="fs-lg ri-more-2-fill"
                      onClick={() =>
                        handleNameSave(cell.row.original.searchQuery)
                      }
                    ></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="border-bottom py-2 bg-primary text-light"
                      style={{ cursor: "auto" }}
                    >
                      <i className="fs-lg me-2 ri-user-line align-middle"></i>
                      <span className="fs-md">
                        {cell.row.original.searchQuery}
                      </span>
                    </Dropdown.Item>
                    {userDetails?.user_type_id === 0 && (
                      <Dropdown.Item
                        className="border-bottom text-primary py-2"
                        onClick={() =>
                          toggleShowPassword(
                            cell.row.original.userId,
                            cell.row.original.searchQuery
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-eye-fill align-middle"></i>{" "}
                        Show Password
                      </Dropdown.Item>
                    )}
                    {userDetails?.user_type_id === 2 &&
                    userDetails?.belongs_to_b2c ? (
                      <></>
                    ) : (
                      <Dropdown.Item
                        className="border-bottom text-secondary py-2"
                        onClick={() =>
                          toggleResetPassword(
                            cell.row.original.userId,
                            cell.row.original.searchQuery
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-key-2-fill align-middle"></i>{" "}
                        Change Password
                      </Dropdown.Item>
                    )}

                    {!isChildLevelOne && (
                      <Dropdown.Item
                        className="border-bottom text-success py-2"
                        onClick={() =>
                          togglePartnershipSharing(
                            cell.row.original.userId,
                            cell.row.original.searchQuery
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-hand-coin-fill align-middle"></i>{" "}
                        Partnership Sharing
                      </Dropdown.Item>
                    )}
                    {userDetails?.user_type_id === 2 &&
                    userDetails?.belongs_to_b2c ? (
                      <></>
                    ) : (
                      <Dropdown.Item
                        className="border-bottom text-danger py-2"
                        onClick={() =>
                          togglePermission(
                            cell.row.original.userId,
                            cell.row.original.searchQuery,
                            cell.row?.original?.items
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-shield-user-fill align-middle"></i>{" "}
                        Permission
                      </Dropdown.Item>
                    )}
                    {userDetails?.user_type_id === 2 &&
                    userDetails?.belongs_to_b2c ? (
                      <></>
                    ) : (
                      <Dropdown.Item
                        className="border-bottom text-dark py-2"
                        onClick={() =>
                          nav(
                            `/block-market/${cell.row.original.userId}/${cell.row.original.searchQuery}`
                          )
                        }
                      >
                        <i className="fs-lg me-2 mdi mdi-human-child align-middle"></i>
                        {isChildLevelOne
                          ? "Block Market"
                          : "Child Block Market"}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      className="border-bottom text-success py-2"
                      onClick={() =>
                        handleNavigate(
                          cell.row.original.userId,
                          cell.row.original.searchQuery
                        )
                      }
                    >
                      <i className="fs-lg me-2 ri-file-list-3-fill align-middle"></i>{" "}
                      Report
                    </Dropdown.Item>
                    {userDetails?.user_type_id === 2 &&
                    userDetails?.belongs_to_b2c ? (
                      <></>
                    ) : (
                      <Dropdown.Item
                        className="border-bottom text-info py-2"
                        onClick={() =>
                          toggleMarketLimit(
                            cell.row.original.userId,
                            cell.row.original.userSetting,
                            cell.row.original.searchQuery
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-settings-4-fill align-middle"></i>{" "}
                        Market Limit
                      </Dropdown.Item>
                    )}
                    {isDealer && (
                      <Dropdown.Item
                        className="border-bottom text-info py-2"
                        onClick={() =>
                          toggleB2C(
                            cell.row.original.userId,
                            cell.row.original.searchQuery,
                            cell.row?.original?.items
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-settings-4-fill align-middle"></i>{" "}
                        Allow B2C
                      </Dropdown.Item>
                    )}
                    {isDealer && (
                      <Dropdown.Item
                        className="border-bottom text-info py-2"
                        onClick={() =>
                          toggleSocial(
                            cell.row.original.userId,
                            cell.row.original.searchQuery,
                            cell.row?.original?.allow_social_media_dealer
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-settings-4-fill align-middle"></i>{" "}
                        Allow Social Media
                      </Dropdown.Item>
                    )}

                    {/* <Dropdown.Item className="border-bottom text-dark py-2" onClick={()=>togglePermissionSetting(cell.row.original.userId)}>
                                    <i className="fs-lg me-2 mdi mdi-account-key align-middle"></i> Permission Setting
                                </Dropdown.Item> */}
                    {userDetails?.user_type_id === 2 &&
                    userDetails?.belongs_to_b2c ? (
                      <></>
                    ) : (
                      <Dropdown.Item
                        className="border-bottom text-warning py-2"
                        onClick={() =>
                          toggleCopmmitionSetting(
                            cell.row.original.userId,
                            cell.row.original.searchQuery
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-percent-fill align-middle"></i>{" "}
                        Commission Setting
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      className="border-bottom text-success py-2"
                      onClick={() =>
                        handleParentList(
                          cell.row.original.userId,
                          cell.row.original.searchQuery
                        )
                      }
                    >
                      <i className="fs-lg me-2 ri-parent-fill align-middle"></i>{" "}
                      Parent List
                    </Dropdown.Item>
                    {!isChildLevelOne && (
                      <Dropdown.Item
                        className="border-bottom text-primary py-2"
                        onClick={() => {
                          nav(
                            `/addChild-user/${cell.row.original.userId}/${cell.row.original.levelId}`
                          );
                        }}
                      >
                        <i className="fs-lg me-2 ri-user-add-fill align-middle"></i>{" "}
                        Add Child
                      </Dropdown.Item>
                    )}
                    {isLevelOne && (
                      <Dropdown.Item
                        className="border-bottom text-danger py-2"
                        onClick={() =>
                          nav(
                            `/addDemo-user/${cell.row.original.userId}/${cell.row.original.levelId}`
                          )
                        }
                      >
                        <i className="fs-lg me-2 ri-login-circle-fill align-middle"></i>{" "}
                        Demo Login
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item
                      className="text-info py-2"
                      onClick={() =>
                        toggleEditStake(
                          cell.row.original.userId,
                          cell.row.original.searchQuery
                        )
                      }
                    >
                      <i className="fs-lg me-2 ri-edit-fill align-middle"></i>{" "}
                      Edit Stake
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
      ].filter((col) =>
        columnsDateState?.find(
          (colDate) => colDate.name === col.header && colDate.show
        )
      ),
    // eslint-disable-next-line
    [depositWithData, userData, currentPage, limit, columnsDateState]
  );

  useEffect(() => {
    const user = localStorage.getItem("adminDetails");

    if (user) {
      try {
        const parsedUser = JSON.parse(user);
        setUserDetails(parsedUser);
      } catch (error) {
        console.error("Failed to parse user details", error);
      }
    }
  }, []);

  const totals = useMemo(() => {
    return defaultData.reduce(
      (acc, user) => {
        acc.balance += user.balance;
        acc.exposure_limit += user.exp;
        acc.credit_reference += user.creditReference;
        acc.profit_loss += user.p_l;
        return acc;
      },
      { balance: 0, exposure_limit: 0, credit_reference: 0, profit_loss: 0 }
    );
  }, [defaultData]);

  return (
    <>
      <TableContainer
        isPagination={false}
        columns={columns}
        data={defaultData}
        sorting={true}
        customPageSize={limit}
        tableHeight={"560px"}
        divClassName="table-responsive"
        tableClass="table table-centered align-middle table-custom-effect table-nowrap userListTable mb-0 table-sticky-second table-sticky-head table-sticky-last"
        theadClass="table-light"
        PaginationClassName="align-items-center mt-1 pt-2"
        isBordered={false}
        setLimit={setLimit}
        totalPages={totalPages}
        limit={limit}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalData={totalData}
        isFilter={true}
        setFormValuesFilter={setFormValuesFilter}
        formValuesFilter={formValuesFilter}
        domainList={domainList}
        domainId={domainId}
        handleDomain={handleDomain}
        handleLevel={handleLevel}
        levelId={levelId}
        totals={totals}
        columnsDateState={columnsDateState}
      />

      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => {
            setLimit(value);
            setCurrentPage(1);
          }}
        />
        <ListPagination
          listTotal={{
            total: totalData / limit ? totalData / limit : 1,
            page: currentPage,
          }}
          handlePageFilter={(value: number) => setCurrentPage(value)}
        />
      </nav>

      {/* Modals */}
      <EditCreditReference
        updateUserCr={updateUserCr}
        userIds={userIds}
        userDetails={userDetails}
        userName={userName}
        creditRef={creditRef}
        show={showCreditReference}
        clickHandler={toggleCreditReference}
      />
      <PartnershipModal
        userType={userDetails?.user_type_id}
        userName={userName}
        userIds={userIds}
        show={showPartnership}
        clickHandler={togglePartnershipSharing}
      />
      {/* <PermissionSetting userIds={userIds} show={showPermissionSetting} clickHandler={togglePermissionSetting} /> */}
      <ExposureModal
        userName={userName}
        userIds={userIds}
        show={showExposure}
        clickHandler={toggleExposure}
      />
      <CommissionSetting
        userName={userName}
        userDetails={userDetails}
        getUserList={getUserList}
        userIds={userIds}
        show={showCommSet}
        clickHandler={toggleCopmmitionSetting}
      />
      <DepositModal
        updateUserData={updateUserData}
        depositWithData={depositWithData}
        setDepositWithData={setDepositWithData}
        userName={userName}
        getUserList={getUserList}
        userIds={userIds}
        show={showDeposit}
        clickHandler={toggleDeposit}
      />
      <WithdrawModal
        updateUserData={updateUserData}
        depositWithData={depositWithData}
        setDepositWithData={setDepositWithData}
        userName={userName}
        getUserList={getUserList}
        userIds={userIds}
        show={showWithdraw}
        clickHandler={toggleWithdraw}
      />
      <ShowPasswordModal
        userName={userName}
        userIds={userIds}
        show={showPasswordModal}
        clickHandler={toggleShowPassword}
      />
      <ResetPasswordModal
        userName={userName}
        userDetails={userDetails}
        setshowResetPasswordModal={setshowResetPasswordModal}
        userIds={userIds}
        show={showResetPasswordModal}
        clickHandler={toggleResetPassword}
      />
      <UserMarketLimit
        userName={userName}
        settingData={settingData}
        userIds={userIds}
        show={showMarketLimit}
        clickHandler={toggleMarketLimit}
      />
      <EditStakeModal
        userName={userName}
        userIds={userIds}
        show={showEditStake}
        clickHandler={toggleEditStake}
      />

      {/* <ExposureLimitModal show={showExposureLimit} clickHandler={toggleExposureLimit} /> */}
      <ParentList
        userName={userName}
        userIds={userIds}
        show={showParentList}
        clickHandler={handleParentList}
      />
      <PermissionModal
        setPermission={setPermission}
        getUserList={getUserList}
        userIds={userIds}
        userName={userName}
        userData={dataUser}
        show={showPermission}
        clickHandler={togglePermission}
      />
      <AllowB2CModal
        setPermission={setB2c}
        getUserList={getUserList}
        userIds={userIds}
        userName={userName}
        userData={dataUser}
        show={showB2C}
      />
      <AllowSocailModal
        setPermission={setSocial}
        getUserList={getUserList}
        userIds={userIds}
        userName={userName}
        show={showSocial}
        isAllowed={socialAllowed}
      />

      <Modal show={showUser} onHide={handleCloseUserLock}>
        {/* <Modal.Body>
          <h5 className="lg text-center">
            Do you want to {!userLock?.isLock ? "UnLock" : "Lock"} the User for{" "}
            {userLock?.userName} ?
          </h5>
        </Modal.Body> */}
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>
            {!userLock?.isLock ? "UnLock" : "Lock"} the User for{" "}
            {userLock?.userName}
          </span>
          <Button variant="light btn-sm" onClick={handleCloseUserLock}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <Row className="gap-3">
            <div className="form-floating mt-3">
              <Form.Control
                isInvalid={!!loginPasswordError}
                name="currentPassword"
                type={showPassword ? "text" : "password"}
                placeholder="Logged In Password"
                value={password}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Enter Logged In Password</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={`${
                    showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                  } align-middle`}
                ></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                {loginPasswordError}
              </Form.Control.Feedback>
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={() => handleCloseUserLock()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleActiveDeactive()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPassModal}
        onHide={() => setShowPasswordModal(false)}
        className="zoomIn "
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>
            {!userLock?.isLock ? "UnLock" : "Lock"} the bet for{" "}
            {userLock?.userName}
          </span>
          <Button
            variant="light btn-sm"
            onClick={() => setShowPasswordModal(false)}
          >
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <Row className="gap-3">
            <div className="form-floating mt-3">
              <Form.Control
                isInvalid={!!loginPasswordError}
                name="currentPassword"
                type={showPassword ? "text" : "password"}
                placeholder="Logged In Password"
                value={password}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Enter Logged In Password</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={`${
                    showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                  } align-middle`}
                ></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                {loginPasswordError}
              </Form.Control.Feedback>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="light" onClick={() => handleCancelUpdata()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdate()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <AddScreenShot
        domainIds={domainIds}
        userId={userIds}
        userName={userName}
        setAddScreen={setAddScreen}
        addScreen={addScreen}
        getWellet={getWellet}
        setFormData={setFormData}
        formData={formData}
      />
    </>
  );
};

export default UserListTable;
