import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BetLimits } from "pages/MatchDetail";
import snackbarUtil from "Utils/snackBarUtil";

interface MatchSettingProps {
  limits?: BetLimits | undefined;
  id?: string | undefined;
  getLimit?: any;
  fancyShow?: boolean;
  shoeOdss?: boolean;
  marketId?: string;
  sportName: string;
  combineData: any;
  setFormData: any;
  formData: any;
  errors: any;
  validationError: any;
  tooltipMsg: {
    [key: string]: string;
  };

  showTooltip: {
    [key: string]: boolean;
  };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (name: string) => void;
  submit: () => void;
}

const MarketSettingForm = ({
  handleInputChange,
  handleBlur,
  submit,
  showTooltip,
  sportName,
  combineData,
  setFormData,
  formData,
  errors,
  tooltipMsg,
}: MatchSettingProps) => {
  useEffect(() => {
    setFormData({
      market_advance_bet_stake: combineData?.market_advance_bet_stake,
      market_bet_delay: combineData?.market_bet_delay,
      market_bookmaker_max_odds_rate:
        combineData?.market_bookmaker_max_odds_rate,
      market_bookmaker_min_odds_rate:
        combineData?.market_bookmaker_min_odds_rate,
      market_max_profit: combineData?.market_max_profit,
      market_max_stack: combineData?.market_max_stack,
      market_min_odds_rate: combineData?.market_min_odds_rate,
      market_min_stack: combineData?.market_min_stack,
      session_bet_delay: combineData?.session_bet_delay,
      session_max_profit: combineData?.session_max_profit,
      session_max_stack: combineData?.session_max_stack,
      session_min_stack: combineData?.session_min_stack,
      market_max_odds_rate: combineData?.market_max_odds_rate,
    });
  }, [combineData]);

  return (
    <Form>
      <Row className="g-3">
        <h5 className="text-primary mb-0">Market Setting</h5>

        <Col lg={4}>
          <OverlayTrigger
            show={!!showTooltip?.market_min_stack}
            placement="top"
            overlay={<Tooltip>{tooltipMsg.market_min_stack}</Tooltip>}
          >
            <div className="form-floating">
              <Form.Control
                name="market_min_stack"
                type="number"
                id="MinStakeAmount"
                placeholder="Min. Stake Amount"
                value={formData.market_min_stack}
                onChange={handleInputChange}
                onBlur={() => handleBlur("market_min_stack")}
                isInvalid={!!errors.market_min_stack}
                onKeyDown={(e) => {
                  if (["-", "e", "+", "E", "."].includes(e.key))
                    e.preventDefault();
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents form submission if inside a form
                    submit(); // Call your submit function
                  }
                }}
              />
              <Form.Label htmlFor="MinStakeAmount">
                Min. Stake Amount
              </Form.Label>
              {errors.market_min_stack && (
                <Form.Control.Feedback type="invalid">
                  {errors.market_min_stack}
                </Form.Control.Feedback>
              )}
            </div>
          </OverlayTrigger>
        </Col>

        <Col lg={4}>
          <OverlayTrigger
            show={!!showTooltip?.market_max_stack}
            placement="top"
            overlay={<Tooltip>{tooltipMsg.market_max_stack}</Tooltip>}
          >
            <div className="form-floating">
              <Form.Control
                name="market_max_stack"
                type="number"
                id="MaxStakeAmount"
                placeholder="Max. Stake Amount"
                value={formData.market_max_stack}
                onChange={handleInputChange}
                isInvalid={!!errors.market_max_stack}
                onKeyDown={(e) => {
                  if (
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "+" ||
                    e.key === "E" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents form submission if inside a form
                    submit(); // Call your submit function
                  }
                }}
              />
              <Form.Label htmlFor="MaxStakeAmount">
                Max. Stake Amount
              </Form.Label>
              {errors.market_max_stack && (
                <Form.Control.Feedback type="invalid">
                  {errors.market_max_stack}
                </Form.Control.Feedback>
              )}
            </div>
          </OverlayTrigger>
        </Col>

        <Col lg={4}>
          <OverlayTrigger
            show={!!showTooltip?.market_min_odds_rate}
            placement="top"
            overlay={<Tooltip>{tooltipMsg.market_min_odds_rate}</Tooltip>}
          >
            <div className="form-floating">
              <Form.Control
                name="market_min_odds_rate"
                type="number"
                id="MinOddsRate"
                placeholder="Min. Odds Rate"
                value={formData.market_min_odds_rate}
                onChange={handleInputChange}
                isInvalid={!!errors.market_min_odds_rate}
                onKeyDown={(e) => {
                  if (
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "+" ||
                    e.key === "E" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents form submission if inside a form
                    submit(); // Call your submit function
                  }
                }}
              />
              <Form.Label htmlFor="MinOddsRate">Min. Odds Rate</Form.Label>
              {errors.market_min_odds_rate && (
                <Form.Control.Feedback type="invalid">
                  {errors.market_min_odds_rate}
                </Form.Control.Feedback>
              )}
            </div>
          </OverlayTrigger>
        </Col>

        <Col lg={4}>
          <OverlayTrigger
            show={!!showTooltip?.market_max_odds_rate}
            placement="top"
            overlay={<Tooltip>{tooltipMsg.market_max_odds_rate}</Tooltip>}
          >
            <div className="form-floating">
              <Form.Control
                name="market_max_odds_rate"
                type="number"
                id="MaxOddsRate"
                placeholder="Max. Odds Rate"
                value={formData?.market_max_odds_rate}
                onChange={handleInputChange}
                isInvalid={!!errors.market_max_odds_rate}
                onKeyDown={(e) => {
                  if (
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "+" ||
                    e.key === "E" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents form submission if inside a form
                    submit(); // Call your submit function
                  }
                }}
              />
              <Form.Label htmlFor="MaxOddsRate">Max. Odds Rate</Form.Label>
              {errors.market_max_odds_rate && (
                <Form.Control.Feedback type="invalid">
                  {errors.market_max_odds_rate}
                </Form.Control.Feedback>
              )}
            </div>
          </OverlayTrigger>
        </Col>

        {sportName === "Cricket" && (
          <>
            <Col lg={4}>
              <OverlayTrigger
                show={!!showTooltip?.market_bookmaker_min_odds_rate}
                placement="top"
                overlay={
                  <Tooltip>{tooltipMsg.market_bookmaker_min_odds_rate}</Tooltip>
                }
              >
                <div className="form-floating">
                  <Form.Control
                    name="market_bookmaker_min_odds_rate"
                    type="number"
                    id="BookmakerMinOddsRate"
                    placeholder="Bookmaker Min. Odds Rate"
                    value={formData?.market_bookmaker_min_odds_rate}
                    onChange={handleInputChange}
                    isInvalid={!!errors?.market_bookmaker_min_odds_rate}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e.key === "+" ||
                        e.key === "E" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents form submission if inside a form
                        submit(); // Call your submit function
                      }
                    }}
                  />
                  <Form.Label htmlFor="BookmakerMinOddsRate">
                    Bookmaker Min. Odds Rate
                  </Form.Label>
                  {errors.market_bookmaker_min_odds_rate && (
                    <Form.Control.Feedback type="invalid">
                      {errors.market_bookmaker_min_odds_rate}
                    </Form.Control.Feedback>
                  )}
                </div>
              </OverlayTrigger>
            </Col>

            <Col lg={4}>
              <OverlayTrigger
                show={!!showTooltip?.market_bookmaker_max_odds_rate}
                placement="top"
                overlay={
                  <Tooltip>{tooltipMsg.market_bookmaker_max_odds_rate}</Tooltip>
                }
              >
                <div className="form-floating">
                  <Form.Control
                    name="market_bookmaker_max_odds_rate"
                    type="number"
                    id="BookmakerMaxOddsRate"
                    placeholder="Bookmaker Max. Odds Rate"
                    value={formData?.market_bookmaker_max_odds_rate}
                    onChange={handleInputChange}
                    isInvalid={!!errors.market_bookmaker_max_odds_rate}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e.key === "+" ||
                        e.key === "E" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents form submission if inside a form
                        submit(); // Call your submit function
                      }
                    }}
                  />
                  <Form.Label htmlFor="BookmakerMaxOddsRate">
                    Bookmaker Max. Odds Rate
                  </Form.Label>
                  {errors.market_bookmaker_max_odds_rate && (
                    <Form.Control.Feedback type="invalid">
                      {errors.market_bookmaker_max_odds_rate}
                    </Form.Control.Feedback>
                  )}
                </div>
              </OverlayTrigger>
            </Col>
          </>
        )}

        <Col lg={4}>
          <OverlayTrigger
            show={!!showTooltip?.market_bet_delay}
            placement="top"
            overlay={<Tooltip>{tooltipMsg.market_bet_delay}</Tooltip>}
          >
            <div className="form-floating">
              <Form.Control
                name="market_bet_delay"
                type="number"
                id="BetDelay"
                placeholder="Match Bets Delay"
                value={formData?.market_bet_delay}
                onChange={handleInputChange}
                isInvalid={!!errors.market_bet_delay}
                onKeyDown={(e) => {
                  if (
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "+" ||
                    e.key === "E" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents form submission if inside a form
                    submit(); // Call your submit function
                  }
                }}
              />
              <Form.Label htmlFor="BetDelay">Match Bets Delay</Form.Label>
              {errors.market_bet_delay && (
                <Form.Control.Feedback type="invalid">
                  {errors.market_bet_delay}
                </Form.Control.Feedback>
              )}
            </div>
          </OverlayTrigger>
        </Col>

        <Col lg={4}>
          <OverlayTrigger
            show={!!showTooltip?.market_max_profit}
            placement="top"
            overlay={<Tooltip>{tooltipMsg.market_max_profit}</Tooltip>}
          >
            <div className="form-floating">
              <Form.Control
                name="market_max_profit"
                type="number"
                id="MarketMaxProfit"
                placeholder="Market Max Profit"
                value={formData.market_max_profit}
                onChange={handleInputChange}
                isInvalid={!!errors.market_max_profit}
                onKeyDown={(e) => {
                  if (
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "+" ||
                    e.key === "E" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents form submission if inside a form
                    submit(); // Call your submit function
                  }
                }}
              />
              <Form.Label htmlFor="MarketMaxProfit">
                Market Max Profit
              </Form.Label>
              {errors.market_max_profit && (
                <Form.Control.Feedback type="invalid">
                  {errors.market_max_profit}
                </Form.Control.Feedback>
              )}
            </div>
          </OverlayTrigger>
        </Col>

        {sportName === "Cricket" && (
          <>
            <h5 className="text-primary mb-0">Session Setting</h5>
            <Col lg={4}>
              <OverlayTrigger
                show={!!showTooltip?.session_min_stack}
                placement="top"
                overlay={<Tooltip>{tooltipMsg.session_min_stack}</Tooltip>}
              >
                <div className="form-floating">
                  <Form.Control
                    name="session_min_stack"
                    type="number"
                    id="session_min_stack"
                    placeholder="Session Min Stake"
                    value={formData.session_min_stack}
                    onChange={handleInputChange}
                    isInvalid={!!errors.session_min_stack}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e.key === "+" ||
                        e.key === "E" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents form submission if inside a form
                        submit(); // Call your submit function
                      }
                    }}
                  />
                  <Form.Label>Session Min Stake</Form.Label>
                  {errors.session_min_stack && (
                    <Form.Control.Feedback type="invalid">
                      {errors.session_min_stack}
                    </Form.Control.Feedback>
                  )}
                </div>
              </OverlayTrigger>
            </Col>

            <Col lg={4}>
              <OverlayTrigger
                show={!!showTooltip?.session_max_stack}
                placement="top"
                overlay={<Tooltip>{tooltipMsg.session_max_stack}</Tooltip>}
              >
                <div className="form-floating">
                  <Form.Control
                    name="session_max_stack"
                    type="number"
                    id="session_max_stack"
                    placeholder="Session Max Stake"
                    value={formData.session_max_stack}
                    onChange={handleInputChange}
                    isInvalid={!!errors.session_max_stack}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e.key === "+" ||
                        e.key === "E" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents form submission if inside a form
                        submit(); // Call your submit function
                      }
                    }}
                  />
                  <Form.Label>Session Max Stake</Form.Label>
                  {errors.session_max_stack && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.session_max_stack}
                    </Form.Control.Feedback>
                  )}
                </div>
              </OverlayTrigger>
            </Col>

            <Col lg={4}>
              <OverlayTrigger
                show={!!showTooltip?.session_max_profit}
                placement="top"
                overlay={<Tooltip>{tooltipMsg.session_max_profit}</Tooltip>}
              >
                <div className="form-floating">
                  <Form.Control
                    name="session_max_profit"
                    type="number"
                    id="session_max_profit"
                    placeholder="Session Max Profit"
                    value={formData.session_max_profit}
                    onChange={handleInputChange}
                    isInvalid={!!errors.session_max_profit}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e.key === "+" ||
                        e.key === "E" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents form submission if inside a form
                        submit(); // Call your submit function
                      }
                    }}
                  />
                  <Form.Label>Session Max Profit</Form.Label>
                  {errors.session_max_profit && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.session_max_profit}
                    </Form.Control.Feedback>
                  )}
                </div>
              </OverlayTrigger>
            </Col>

            <Col lg={4}>
              <OverlayTrigger
                show={!!showTooltip?.session_bet_delay}
                placement="top"
                overlay={<Tooltip>{tooltipMsg.session_bet_delay}</Tooltip>}
              >
                <div className="form-floating">
                  <Form.Control
                    name="session_bet_delay"
                    type="number"
                    id="session_bet_delay"
                    placeholder="Session Bet Delay"
                    value={formData.session_bet_delay}
                    onChange={handleInputChange}
                    isInvalid={!!errors.session_bet_delay}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e.key === "+" ||
                        e.key === "E" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents form submission if inside a form
                        submit(); // Call your submit function
                      }
                    }}
                  />
                  <Form.Label>Session Bet Delay</Form.Label>
                  {errors.session_bet_delay && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.session_bet_delay}
                    </Form.Control.Feedback>
                  )}
                </div>
              </OverlayTrigger>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default MarketSettingForm;
