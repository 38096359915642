import { Button, Modal } from "react-bootstrap";
import { pageUrlData } from "Utils/baseUrl";

interface ReferCodeModalsProps {
  show: boolean;
  clickHandler: () => void;
  referData: any;
}

const ReferCodeModals = ({
  show,
  clickHandler,
  referData,
}: ReferCodeModalsProps) => {
  function processURL(url: string) {
    try {
      const urlObj = new URL(url);
      const hostParts = urlObj.hostname.split(".");
      if (hostParts.length > 2) {
        const newHost = hostParts.slice(1).join(".");
        urlObj.hostname = newHost;
      }
      return `${urlObj.origin}${urlObj.pathname}`;
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  }

  const baseUrl = window.location.origin;
  const rootDomain = processURL(baseUrl);

  return (
    <Modal show={show} onHide={clickHandler} className="zoomIn">
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <span>Refer Code</span>
        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <a
            href={`${rootDomain}${pageUrlData}?referCode=${referData?.user_name}`}
            target="_blank"
            rel="noreferrer"
          >
            {`${rootDomain}${pageUrlData}?referCode=${referData?.user_name}`}
          </a>
          <a
            href={`https://api.whatsapp.com/send/?text=${rootDomain}${pageUrlData}?referCode=${referData?.user_name}&type=custom_url&app_absent=0`}
            target="_blank"
            rel="noreferrer"
          >
            <i className=" ri-whatsapp-line fs-4 text-success" />
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReferCodeModals;
