import { Dispatch, SetStateAction, useState, useRef } from "react";
import { Button, Modal, Form, Overlay, Tooltip } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface ResetPasswordModalProps {
  show: boolean;
  clickHandler: any;
  userIds: string;
  setshowResetPasswordModal: any;
  userName: string;
  userDetails: any;
}

interface FormState {
  password: string;
  confirmPassword: string;
  loggedInPassword: string;
}

interface FormErrors {
  password?: string;
  confirmPassword?: string;
  loggedInPassword?: string;
}

const ResetPasswordModal = ({
  show,
  clickHandler,
  userIds,
  setshowResetPasswordModal,
  userName,
  userDetails,
}: ResetPasswordModalProps) => {
  const [formState, setFormState] = useState<FormState>({
    password: "",
    confirmPassword: "",
    loggedInPassword: "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showPassword, setShowPassword] = useState(false);

  const validatePassword = (password: string): string => {
    if (password.length < 8)
      return "Password must be at least 8 characters long.";
    if (!/[a-zA-Z]/.test(password))
      return "Password must contain at least one letter.";
    if (!/[0-9]/.test(password))
      return "Password must contain at least one number.";
    if (!/^[a-zA-Z0-9@_#$-]+$/.test(password))
      return "Password can only contain letters, numbers, and @, _, #, &, -";
    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));

    let error = "";
    if (name === "password") error = validatePassword(value);
    if (name === "confirmPassword" && value !== formState.password)
      error = "Passwords do not match.";
    if (name === "loggedInPassword" && value === "")
      error = "Your password is required.";

    setFormErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handlePasswordReset = async () => {
    const errors: FormErrors = {
      password: validatePassword(formState.password),
      confirmPassword:
        formState.confirmPassword !== formState.password
          ? "Passwords do not match."
          : "",
      loggedInPassword: formState.loggedInPassword
        ? ""
        : "Your password is required.",
    };

    if (Object.values(errors).some((err) => err)) {
      setFormErrors(errors);
      return;
    }

    const { response } = await authServices.getUpdateChildPass({
      user_id: userIds,
      new_password: formState.password,
      password: formState.loggedInPassword,
      confirm_password: formState.confirmPassword,
      pass_type: "PASSWORD",
    });

    if (response?.status) {
      snackbarUtil.success(response?.msg);
      setFormState({ password: "", confirmPassword: "", loggedInPassword: "" });
      setshowResetPasswordModal(false);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleHide = () => {
    clickHandler();
    setFormErrors({});
    setFormState({
      confirmPassword: "",
      loggedInPassword: "",
      password: "",
    });
  };

  return (
    <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <span>Change Password of {userName}</span>
        <Button variant="light btn-sm" onClick={handleHide}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          {Object.entries({
            password: "New Password",
            confirmPassword: "Confirm Password",
            loggedInPassword: "Your Password",
          }).map(([key, label]) => (
            <div className="form-floating mt-3" key={key}>
              <Form.Control
                name={key}
                type={showPassword ? "text" : "password"}
                placeholder={label}
                value={formState[key as keyof FormState]}
                onChange={handleChange}
                isInvalid={!!formErrors[key as keyof FormErrors]}
              />
              <Form.Label>{label}</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-muted password-addon"
                type="button"
                onClick={
                  () =>
                    //   key === "password"
                    //         ?
                    setShowPassword(!showPassword)
                  // : setShowLogPassword(!showLogPassword)
                }
              >
                <i
                  className={`${
                    showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                  }`}
                ></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                {formErrors[key as keyof FormErrors]}
              </Form.Control.Feedback>
            </div>
          ))}
        </Form>
        <div className="text-end mt-3">
          <Button variant="subtle-primary" onClick={handlePasswordReset}>
            Reset Password
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;
