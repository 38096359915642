import { Alert, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { useEffect, useState } from "react";
import snackbarUtil from "Utils/snackBarUtil";
import Loader from "Common/Loader";

interface StatementProps {
  filter?: boolean;
  activeTab: string
}

const NewsUpdate = ({ activeTab }: StatementProps) => {
  const userId = localStorage.getItem("userId");
  const [heading, setHeading] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getNews = async () => {
    const { response } = await authServices.getUserNews({
      full: 1,
      user_id: userId || "",
    });
    if (response?.data) {
      setHeading(response?.data?.heading);
      setDescription(response?.data?.description);
    } else {
        setHeading('');
        setDescription('');
    }
  };

  const createNews = async () => {
    setIsLoading(true);
    const { response } = await authServices.createNews({
      heading: heading,
      description: description,
    });
    setIsLoading(false);
    if (response?.status) {
      getNews();
      snackbarUtil.success(response.msg);
    } else {
      snackbarUtil.error(response?.msg || "An error occurred. Please try again.");
    }
  };

  const deleteNews = async () => {
    setShow(false);
    setIsLoading(true);
    const { response } = await authServices.deleteNews();
    setIsLoading(false);
    if (response?.status) {
      getNews();
      snackbarUtil.success(response.msg);
    } else {
      snackbarUtil.error(response?.msg || "An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    activeTab === 'NewsUpdate' && getNews();
     // eslint-disable-next-line
  }, [activeTab]);

  const handleChange = (e: any, type: string) => {
    let value = e.target.value;

    if (type === "heading") {
      setHeading(value);
    } else {
      setDescription(value);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12}>
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                value={heading}
                placeholder="Enter News Heading"
                onChange={(e: any) => handleChange(e, "heading")}
              />
            </Col>
            <Col lg={12} className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={5}
                value={description}
                placeholder="Enter News Description"
                style={{ height: "auto" }}
                onChange={(e: any) => handleChange(e, "description")}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0 text-end">
          <Button className="me-2" onClick={() => createNews()}>
            Update
          </Button>
          <Button variant="light" onClick={() => setShow(true)}>
            Cancel
          </Button>
        </Card.Footer>
      </Card>
  
        <Modal show={show} onHide={() => setShow(false)}>
          <Alert
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
            className="mb-0"
          >
            <Alert.Heading>Confirm!</Alert.Heading>
            <p>Are you sure want to Delete this News for your child</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => deleteNews()} variant="success">
                Ok
              </Button>
              <Button onClick={() => setShow(false)} variant="danger">
                Cancel
              </Button>
            </div>
          </Alert>
        </Modal>
        {isLoading && <Loader />}
    </>
  );
};

export default NewsUpdate;
