import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useState, useMemo, useRef } from "react";
import { authServices } from "Utils/auth/services";
import {
  clearSearchPayload,
  generateOptions,
  updateSearchPayload,
} from "Utils/searchFilter";
import OpenBetsTable from "Common/Tables/OpenBetsTable";
import { useNavigationType, useParams } from "react-router-dom";
import Loader from "Common/Loader";



interface StatementProps {
  filter?: boolean;
  activeTab: string;
  isReport?: boolean;
}

const OpenBets = ({ filter, activeTab, isReport = false }: StatementProps) => {
  const { userid } = useParams();
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [openBetsData, setopenBetsData] = useState<OpenBetsData[]>([]);
  const [searchList, setSearchList] = useState<SportSearchList>();
  const [searchPayload, setSearchPayload] = useState<SportsSearchPayloadData>(
    {}
  );
  const [betType, setBetType] = useState<string>("open");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("openBetStart", startDate.toString());
    localStorage.setItem("openBetEnd", endDate.toString());
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const eventOptions = useMemo(
    () =>
      generateOptions(
        searchList?.events_m_f || [],
        "event_id",
        "event_name",
        "type"
      ),
    [searchList?.events_m_f]
  );
  const matchOptions = useMemo(
    () => generateOptions(searchList?.matches || [], "match_id", "match_name"),
    [searchList?.matches]
  );
  const seriesOptions = useMemo(
    () => generateOptions(searchList?.series || [], "series_id", "series_name"),
    [searchList?.series]
  );
  const sportsOptions = useMemo(
    () => generateOptions(searchList?.sports || [], "sport_id", "sport_name"),
    [searchList?.sports]
  );

  const geOpenBetsList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.geOpenBetsList({
      limit: limit,
      page: page,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.data) {
      setopenBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata[0]?.total / (filters?.limit || limit),
        page: response?.data?.metadata[0]?.page,
      });
    } else {
      setopenBetsData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "openBets",
      ...filters,
    });
    if (response?.data && response?.data.length > 0) {
      setSearchList(response?.data[0]);
    }
  };

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("openBetStart")
        : startDate;
    let end =
      navigationType === "POP" ? localStorage.getItem("openBetEnd") : endDate;
    start && setStartDate(new Date(start));
    end && setEndDate(new Date(end));

    if (activeTab === "OpenBets") {
      geOpenBetsList({
        ...(!isReport && {
          from_date: new Date(start || ''),
          to_date: new Date(end || ''),
        }),
        ...(userid && userid !== "null" && { search: { user_id: userid } }),
      });
      getSportSearchList();
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const handlefilterSubmit = () => {
    let filterData: SportsSearchPayloadData = searchPayload;
    if (betType === "1" || betType === "0") {
      filterData.is_back = Number(betType);
    } else {
      delete filterData.is_back;
    }
    if (userid && userid !== "null") filterData.user_id = userid;
    setPage(1);

    geOpenBetsList({
      from_date: startDate,
      to_date: endDate,
      search: filterData,
      page: 1,
    });
  };


  useEffect(()=>{
    geOpenBetsList({
      from_date: startDate,
      to_date: endDate,
      search: searchPayload,
      page: 1,
    });
  }, [searchPayload])

  const handleSearchFilter = (type: SearchPayloadType, id: string) => {
    const payload: SportsSearchPayloadData = updateSearchPayload(
      type,
      id,
      searchPayload
    );
    if (betType === "1" || betType === "0") {
      payload.is_back = Number(betType);
    } else {
      delete payload.is_back;
    }
    if (userid && userid !== "null") payload.user_id = userid;
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);
    geOpenBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      search: payload,
      page: 1,
    });
  };

  const handleClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    if (userid && userid !== "null") payload.user_id = userid;
    if (betType === "1" || betType === "0") {
      payload.is_back = Number(betType);
    } else {
      delete payload.is_back;
    }

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);
    geOpenBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      search: payload,
      page: 1,
    });
  };

  const handleAllClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    if (userid && userid !== "null") payload.user_id = userid;
    delete payload.is_back;

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
    setBetType("open");
    setPage(1);

    geOpenBetsList({
      ...(!isReport && {
        from_date: getStartTimeOfDay(sevenDaysAgo),
        to_date: currentDateTime(),
      }),
      search: payload,
      page: 1,
    });
  };

  const handleChange = (e: any) => {
    let betType = e.target.value;
    setBetType(e.target.value);
    setPage(1);

    let filterData: SportsSearchPayloadData = searchPayload;
    if (betType === "1" || betType === "0") {
      filterData.is_back = Number(betType);
    } else {
      delete filterData.is_back;
    }
    if (userid && userid !== "null") filterData.user_id = userid;

    geOpenBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      search: filterData,
      page: 1,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let filterData: SportsSearchPayloadData = searchPayload;
    if (betType === "1" || betType === "0") {
      filterData.is_back = Number(betType);
    } else {
      delete filterData.is_back;
    }
    if (userid && userid !== "null") filterData.user_id = userid;

    geOpenBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      page: page,
      limit: limit,
      search: filterData,
    });
  };

  const [oddsRange, setOddsRange] = useState({ from: 0, to: 0 });
  const handleOddsChange = (key: string, value: any) => {
    setOddsRange((prev) => ({ ...prev, [key]: value }));
  };
  const [rateRange, setRateRange] = useState({ from: 0, to: 0 });
  const handleRateChange = (key: string, value: any) => {
    setRateRange((prev) => ({ ...prev, [key]: value }));
  };


  useEffect(() => {
    setSearchPayload((prev) => ({
      ...prev,
      amount_from: Number(oddsRange?.from),
      amount_to: Number(oddsRange?.to)
    }))
  }, [oddsRange])


  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:s",
                      defaultDate: [startDate],
                      maxDate: endDate,
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(new Date(date));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:s",
                      defaultDate: [endDate],
                      maxDate: currentDateTime(),
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(new Date(date));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Sport Name</Form.Label>
                  <Select
                    isClearable
                    options={sportsOptions}
                    value={
                      sportsOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.sport_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("sport_id", e.value);
                      } else {
                        handleClearSelection("sport_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Series Name</Form.Label>
                  <Select
                    isClearable
                    options={seriesOptions}
                    value={
                      seriesOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.series_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("series_id", e.value);
                      } else {
                        handleClearSelection("series_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Match Name</Form.Label>
                  <Select
                    isClearable
                    options={matchOptions}
                    value={
                      matchOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.match_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("match_id", e.value);
                      } else {
                        handleClearSelection("match_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Market Name</Form.Label>
                  <Select
                    isClearable
                    options={eventOptions}
                    value={
                      eventOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.fancy_id ||
                          option.value === searchPayload?.market_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter(
                          e.type === "1" ? "market_id" : "fancy_id",
                          e.value
                        );
                      } else {
                        handleClearSelection("market_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Bet Type</Form.Label>
                  <Form.Select
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    value={betType}
                    onChange={handleChange}
                  >
                    <option selected>Choose...</option>
                    <option value="">All</option>
                    <option value="1">Back</option>
                    <option value="0">Lay</option>
                  </Form.Select>
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleAllClearSelection("sport_id")}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <OpenBetsTable
            openBetsData={openBetsData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
            getBetsList={geOpenBetsList}
            oddsRange={oddsRange}
            handleOddsChange={handleOddsChange}
            handleRateChange={handleRateChange}
            rateRange={rateRange}

          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default OpenBets;