import { Navigate } from "react-router-dom";

import Fourzerofour from "pages/AuthenticationInner/Errors/404Error";
import Fivezerozero from "pages/AuthenticationInner/Errors/500Error";
import Fivezerothree from "pages/AuthenticationInner/Errors/503Error";
import OfflinePage from "pages/AuthenticationInner/Errors/Offline";

import Dashboard from "pages/Dashboard";

// Authentication
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import UserProfile from "pages/Authentication/user-profile";
import ChangePassword from "pages/Authentication/ChangePassword";
import TransactionCode from "pages/Authentication/TransactionCode";
import SecureAuth from "pages/SecureAuth";
import Verification from "pages/Authentication/Verification";
import MatchDetail from "pages/MatchDetail";
import SeriesData from "pages/SeriesData";
import Styles from "pages/Styles";
import AddAgent from "pages/AddAgent";
import ClosedUser from "pages/ClosedUser";
import UserList from "pages/UserList";
import OnlineUser from "pages/OnlineUser";
import ImportMarket from "pages/ImportMarket";
import Reports from "pages/Reports";
import MarketAnalysis from "pages/MarketAnalysis";
import BetsHistory from "pages/BetsHistory";
import SportWisePL from "pages/SportWisePL";
import {
  BankDetails,
  BannerSetting,
  CasinoResult,
  Deposit,
  DomainSetting,
  FancyResult,
  FraudBet,
  MatchResult,
  MatchRollBack,
  Setting,
  UniverseCasinoResult,
  UserMobileData,
  Withdraw,
} from "pages/Super";
import FancyStake from "pages/FancyStake";
import MyMarket from "pages/MyMarket/MyMarket";
import Sidebar from "pages/Sidebar";
import DownLineReport from "pages/Reports/DownLineReport";
import ImportMarketForChild from "pages/ImportMarket/ImportMarketForChild";
import Casino from "pages/Casino/Casino";
import BlockMarket from "pages/ImportMarket/BlockMarket";
import TransactionList from "pages/Super/TransactionList";
import DepositMethod from "pages/Super/DepositMethod";
import DeletedBet from "pages/Super/DeletedBet";
import CurrentBets from "pages/CurrentBets";
import UserLoginHistory from "pages/Reports/UserLoginHistory";
import B2CReport from "pages/B2CReport";
import SocialMedia from "pages/Super/SocialMedia";
import UpdateBonus from "pages/Super/UpdateBonus";
import DepositWithdraw from "pages/Super/Deposit/DepositWithdraw";

const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/:seriesId/:sportId", component: <SeriesData /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
  {
    path: "/match-detail/:id/:sportid/:fid?",
    exact: true,
    name: "Match Detail",
    component: <MatchDetail />,
  },

  //user prpfile
  { path: "/user-profile", name: "UserProfile", component: <UserProfile /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    name: "Navigate",
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/secure-auth",
    exact: true,
    name: "SecureAuth",
    component: <SecureAuth />,
  },
  { path: "/styles", exact: true, name: "Styles", component: <Styles /> },
  {
    path: "/add-agent/:id?/:typeId?",
    exact: true,
    name: "Add Agent",
    component: <AddAgent type="" name="Agent" />,
  },
  {
    path: "/add-uk-agent",
    exact: true,
    name: "Add Uk Agent",
    component: <AddAgent type="uk" name="UK Agent" />,
  },
  {
    path: "/add-user/:id?/:typeId?",
    exact: true,
    name: "Add User",
    component: <AddAgent type="user" name="User" />,
  },
  {
    path: "/addChild-user/:id/:type",
    exact: true,
    name: "Add User",
    component: <AddAgent type="user" name="User" />,
  },
  {
    path: "/addDemo-user/:id/:type",
    exact: true,
    name: "Add User",
    component: <AddAgent type="user" name="User" demo={true} />,
  },
  {
    path: "/b2c-manager",
    exact: true,
    name: "B2C Manager",
    component: <AddAgent type="b2c" name="B2C Manager" />,
  },
  {
    path: "/closed-user",
    exact: true,
    name: "Closed User",
    component: <ClosedUser />,
  },
  {
    path: "/user-list",
    exact: true,
    name: "User List",
    component: <UserList agentStatus={false} />,
  },
  {
    path: "/agent-user-list",
    exact: true,
    name: "Agent User List",
    component: <UserList agentStatus={true} />,
  },
  {
    path: "/online-user",
    exact: true,
    name: "Online List",
    component: <OnlineUser />,
  },
  {
    path: "/import-market",
    exact: true,
    name: "Import List",
    component: <ImportMarket />,
  },
  {
    path: "/block-market/:id/:userId",
    exact: true,
    name: "Import List",
    component: <ImportMarketForChild />,
  },
  {
    path: "/block-market",
    exact: true,
    name: "Import List",
    component: <BlockMarket />,
  },
  {
    path: "/reports/:nav/:userid?",
    exact: true,
    name: "Reports",
    component: <Reports />,
  },
  {
    path: "/market-analysis",
    exact: true,
    name: "Market Analysis",
    component: <MarketAnalysis />,
  },
  {
    path: "/domain-setting",
    exact: true,
    name: "Domain Setting",
    component: <DomainSetting />,
  },
  {
    path: "/match-result",
    exact: true,
    name: "Match Result",
    component: <MatchResult />,
  },
  {
    path: "/fancy-result",
    exact: true,
    name: "Fancy Result",
    component: <FancyResult />,
  },
  {
    path: "/casino-result",
    exact: true,
    name: "Casino Result",
    component: <CasinoResult />,
  },
  {
    path: "/universe-casino-result",
    exact: true,
    name: "Universe Casino Result",
    component: <UniverseCasinoResult />,
  },
  {
    path: "/match-rollback",
    exact: true,
    name: "Match Roll Back",
    component: (
      <MatchRollBack name="Match" type="matchRollback" isMatch={true} />
    ),
  },
  {
    path: "/fancy-rollback",
    exact: true,
    name: "Fancy Roll Back",
    component: (
      <MatchRollBack name="Fancy" type="settledBets" isMatch={false} />
    ),
  },
  {
    path: "/banners-setting",
    exact: true,
    name: "Banner Setting",
    component: <BannerSetting />,
  },
  {
    path: "/user-mobile-data",
    exact: true,
    name: "User Mobile Data",
    component: <UserMobileData />,
  },
  {
    path: "/fraud-bet",
    exact: true,
    name: "Fraud Bet",
    component: <FraudBet />,
  },
  {
    path: "/delete-bet",
    exact: true,
    name: "Delete Bet",
    component: <DeletedBet />,
  },
  {
    path: "/void-bet",
    exact: true,
    name: "Void Bet",
    component: <DeletedBet />,
  },
  { path: "/setting", exact: true, name: "Void Bet", component: <Setting /> },
  {
    path: "/view-bets/:matchid/:marketid/:type/:userid?/:betType?",
    exact: true,
    name: "View Bets",
    component: <BetsHistory />,
  },
  {
    path: "/sports-wise-pl/:matchid/:marketid/:type/:isCasino/:start?/:end?",
    exact: true,
    name: "View Bets",
    component: <SportWisePL />,
  },
  {
    path: "/deposit/:depositId?",
    exact: true,
    name: "Deposit",
    component: <Deposit />,
  },
  { path: "/withdraw", exact: true, name: "Withdraw", component: <Withdraw /> },
  {
    path: "/bank-details",
    exact: true,
    name: "Bank Details",
    component: <BankDetails />,
  },
  {
    path: "/fancy-stack/:matchid/:marketid/:userid?",
    exact: true,
    name: "View Bets",
    component: <FancyStake />,
  },
  {
    path: "/my-market",
    exact: true,
    name: "My Market",
    component: <MyMarket />,
  },
  { path: "/sidebar", exact: true, name: "sidebar", component: <Sidebar /> },
  {
    path: "/downline-report/:userid/:username?",
    exact: true,
    name: "Down Line Report",
    component: <DownLineReport />,
  },
  // { path: "/downline-report/:userid/:exposure/:balance/:p_l", exact: true, name : "Down Line Report", component: <DownLineReport /> },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: <ChangePassword />,
  },
  { path: "/casino/:id", name: "Casino", component: <Casino /> },
  {
    path: "/transaction-list",
    exact: true,
    name: "Withdraw",
    component: <TransactionList />,
  },
  {
    path: "/deposit-method",
    exact: true,
    name: "Withdraw",
    component: <DepositMethod />,
  },
  {
    path: "/current-bets",
    exact: true,
    name: "Current Bets",
    component: <CurrentBets />,
  },
  {
    path: "/login-history",
    exact: true,
    name: "Login History",
    component: <UserLoginHistory />,
  },
  {
    path: "/b2c-report",
    exact: true,
    name: "B2C Report",
    component: <B2CReport />,
  },
  {
    path: "/social-media",
    exact: true,
    name: "Social Media",
    component: <SocialMedia />,
  },
  {
    path: "/bonus-update",
    exact: true,
    name: "Bonus Update",
    component: <UpdateBonus />,
  },
  {
    path: "/deposit-withdraw/:depositId?",
    exact: true,
    name: "Deposit & Withdraw",
    component: <DepositWithdraw />,
  },
];

const publicRoutes = [
  // Authentication
  { path: "/login", name: "Login", component: <Login /> },
  { path: "/", exact: true, component: <Navigate to="/login" /> },
  { path: "/logout", name: "Logout", component: <Logout /> },
  { path: "/register", name: "Register", component: <Register /> },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: <ForgotPassword />,
  },
  {
    path: "/transaction-code",
    name: "TransactionCode",
    component: <TransactionCode />,
  },
  { path: "/verification", name: "Verification", component: <Verification /> },

  // Authentication Inner
  // Error
  // Error 404
  { path: "/auth-404", name: "Fourzerofour", component: <Fourzerofour /> },

  // Error 500
  { path: "/auth-500", name: "Fivezerozero", component: <Fivezerozero /> },

  // Error 503
  { path: "/auth-503", name: "Fivezerothree", component: <Fivezerothree /> },

  // Offline Page
  { path: "/auth-offline", name: "OfflinePage", component: <OfflinePage /> },
];

export { authProtectedRoutes, publicRoutes };
