import BreadCrumb from 'Common/BreadCrumb';
import ToggleSwitch from 'Common/ToggleSwitch';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Button, Card, Container, Form, Modal, Table } from 'react-bootstrap';
import { getDomainWithoutSubdomain } from 'Utils/datefilter';
import { settingServices } from 'Utils/setting/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface SocialMediaItem {
    title: string;
    url: string;
    is_active: boolean;
    image_url: string;
    place: string;
    color: string;
}

interface SocialMediaIcons {
    [key: string]: SocialMediaItem;
}

const siteData: SocialMediaItem[] = [
    {
        title: "Facebook",
        url: "",
        is_active: false,
        image_url: "ri-facebook-circle-fill",
        place: "https://www.facebook.com/",
        color: "#1877f2"
    },
    {
        title: "Telegram",
        url: "",
        is_active: false,
        image_url: "ri-telegram-fill",
        place: "https://www.telegram.com/",
        color: "#0088cc"
    },
    {
        title: "Instagram",
        url: "",
        is_active: false,
        image_url: "ri-instagram-fill",
        place: "https://www.instagram.com/",
        color: "#de44bd"
    },
    {
        title: "Whatsapp",
        url: "",
        is_active: false,
        image_url: "ri-whatsapp-fill",
        place: "https://www.whatsapp.com/",
        color: "#25d366"
    },
    {
        title: "Gmail",
        url: "",
        is_active: false,
        image_url: "mdi mdi-gmail",
        place: "https://www.gmail.com/",
        color: "#ea4335"
    },
    {
        title: "Twitter",
        url: "",
        is_active: false,
        image_url: "ri-twitter-fill",
        place: "https://www.twitter.com/",
        color: "#1da1f2"
    },
];

const SocialMedia = () => {
    const [localSocialIcons, setLocalSocialIcons] = useState<SocialMediaIcons>({});
    const user_Detail = localStorage.getItem("adminDetails");
    let user_name = user_Detail && JSON.parse(user_Detail || '')?.user_name;

    useEffect(() => {
        getData();
    }, []);

    const handleUrlChange = (title: string, value: string) => {
        setLocalSocialIcons((prev) => ({
            ...prev,
            [title]: {
                ...prev[title],
                url: value,
            },
        }));
    };

    const handleToggleChange = (title: string, isActive: boolean) => {
        setLocalSocialIcons((prev) => ({
            ...prev,
            [title]: {
                ...prev[title],
                is_active: isActive,
            },
        }));
    };

    const getDomain = () => {
        try {
            const domainName =
                window.location.origin?.includes("localhost")
                    ? "https://superbets.in"
                    : window.location.origin;
            const domainData = getDomainWithoutSubdomain(domainName);
            return `${domainData}-${user_name}-social`
        } catch (error) {
            return "";
        }
    }
    const getDomainUp = () => {
        try {
            const domainName =
                window.location.origin?.includes("localhost")
                    ? "https://superbets.in"
                    : window.location.origin;
            const domainData = getDomainWithoutSubdomain(domainName);
            return `${domainData}-social`
        } catch (error) {
            return "";
        }
    }

    const getData = async () => {
        const { response } = await settingServices.getSocilIcon(
            { slug: getDomain() }
        )
        if (response && response?.status) {
            const socialData = JSON.parse(response?.data?.description);
            setLocalSocialIcons(socialData)
        }
    };

    const getCreateSocilaMedia = async (data: any) => {
        const { response } = await settingServices.createSocialMediaIcon(data);
        if (response?.status) {
          snackbarUtil.success(response?.msg);
            getData();
        } else {
          snackbarUtil.error(response?.msg);
        }
      }

    const handleUpdate = () => {
        const payload = {
            title: "social",
            slug: getDomainUp(),
            description: JSON.stringify(
                Object.fromEntries(
                    Object.entries(localSocialIcons).map(([key, value]) => [
                        key,
                        {
                            title: value.title || key,
                            url: value.url || "",
                            is_active: value.is_active || false,
                            image_url: `${key}.svg`,
                        },
                    ])
                )
            ),
        };

        getCreateSocilaMedia(payload);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Add Social Media" pageTitle="Dashboard" back />

                </div>

                <Card>
                    <Card.Body>
                        <div className="table-responsive">
                            <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">Icon</th>
                                        <th scope="col">Domain</th>
                                        <th scope="col">Url</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        siteData.map((item) => (
                                            <tr key={item.title}>
                                                <td>
                                                    <i className={`fs-5xl ${item.image_url}`} style={{ color: item.color }}></i>
                                                </td>
                                                <td>{ }</td>
                                                <td>
                                                    <Form.Control
                                                        value={localSocialIcons[item.title]?.url || ""}
                                                        type='text'
                                                        placeholder={item.place}
                                                        onChange={(e) => handleUrlChange(item.title, e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <ToggleSwitch
                                                        On='On'
                                                        Off='Off'
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(item.title, e.target.checked)}
                                                        checked={localSocialIcons[item.title]?.is_active || false}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <Modal.Footer className='mt-4'>
                            <Button onClick={handleUpdate}>Update</Button>
                        </Modal.Footer>
                    </Card.Body>
                </Card>
                {/* {isLoading && <Loader />} */}
            </Container>
        </div>
    );
};

export default SocialMedia;
