import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { settingServices } from 'Utils/setting/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface ExposureLimitModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    exp: number;
    setExposureLimit: Dispatch<SetStateAction<boolean>>;
    getUserList: () => Promise<void>;
    userDetails:any
    userName:any
}

const ExposureLimitModal = ({ show, clickHandler,userDetails, getUserList, setExposureLimit, exp, userIds, userName }: ExposureLimitModalProps) => {
    const [passwordShow, setPasswordShow] = useState(false);
    const [newExposureLimit, setNewExposureLimit] = useState<string>('');
    const [password, setPassword] = useState<string>(''); 
    const [errors, setErrors] = useState<{ exposureLimit?: string; password?: string }>({}); 

    const getUpdateExposure = async (user_id: string, exposure_limit: number) => {
        const { response } = await settingServices.getUpdateExposure({
            master_password:password,
            pass_type:"PASSWORD",
            filter: {
                user_id,
            },
            update: {
                exposure_limit,
            },
        });
        if (response) {
            if (response?.status) {
                snackbarUtil.success(response?.msg);
                getUserList();
                setNewExposureLimit("");
                setPassword("");
                setExposureLimit(false);
            }else{
                snackbarUtil.error(response?.msg);
            }
        }
    };


    const validateInputs = () => {
        let valid = true;
        const newErrors: { exposureLimit?: string; password?: string } = {};

        if (!newExposureLimit) {
            newErrors.exposureLimit = 'Exposure limit is required.';
            valid = false;
        } 
        if (!password) {
            newErrors.password = 'Password is required.';
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };
    const handleSubmit = () => {
        if (validateInputs()) {
            getUpdateExposure(userIds, parseFloat(newExposureLimit));
        }
    };

    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === 'exposureLimit') {
            setNewExposureLimit(value);
            setErrors((prev) => ({ ...prev, exposureLimit: '' }));
        } else if (name === 'password') {
            setPassword(value);
            setErrors((prev) => ({ ...prev, password: '' }));
        }
    };

    const handleClose = () => {
        setExposureLimit(false);
        setNewExposureLimit("");
        setPassword("");
    };

    return (
        <Modal show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Edit Exposure Limit of {userName}</span>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <div className="bg-light p-1 rounded text-center">
                    Current: <strong>{exp}</strong>
                </div>

               
                <div className="form-floating mt-3">
                    <Form.Control
                        type="number"
                        id="Credit"
                        name="exposureLimit"
                        placeholder="Limit"
                        value={newExposureLimit}
                        onChange={handleInputChange}
                    />
                    <Form.Label htmlFor="Credit">New Exposure Limit</Form.Label>
                </div>
                {errors.exposureLimit && <div className="text-danger">{errors.exposureLimit}</div>} {/* Show exposure limit error */}

               
                <div className="form-floating mt-3 position-relative">
                    <Form.Control
                        name="password"
                        type={passwordShow ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={handleInputChange}
                         autoComplete="new-password"
                         id="password-field"

                    />
                    <Form.Label>Password</Form.Label>
                    <Button
                        variant="link"
                        className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                    >
                        <i className={!passwordShow ? "ri-eye-off-fill align-middle" : "ri-eye-fill align-middle"}></i>
                    </Button>
                </div>
                {errors.password && <div className="text-danger">{errors.password}</div>} {/* Show password error */}
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Button variant="subtle-primary" onClick={handleSubmit}>Update</Button>
                <Button variant="btn-ghost" onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExposureLimitModal;