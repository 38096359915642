import BreadCrumb from "Common/BreadCrumb";
import DepositMethodTable from "Common/Tables/DepositMethodTable";
import { useEffect, useState } from "react";
import { Container, Card, Button, Form, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

const DepositMethod = () => {
  const userId = localStorage.getItem("userId");

  const [depositMethod, setDepositMethod] = useState<DepositMethod[]>([]);
  const [bankingMethod, setBankingMethod] = useState<BankingMethod[]>([]);
  const [type, setType] = useState<string>("Active");
  const [showMethod, setMethod] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [formData, setFormData] = useState({
    bankHolderName: "",
    bankName: "",
    ifscCode: "",
    accountNo: "",
    upi: "",
    image: null,
  });
  const [formErrors, setFormErrors] = useState({
    bankHolderName: "",
    bankName: "",
    ifscCode: "",
    accountNo: "",
    upi: "",
    image: "",
  });

  const [touchedFields, setTouchedFields] = useState({
    bankHolderName: false,
    bankName: false,
    ifscCode: false,
    accountNo: false,
    upi: false,
    image: false,
  });

  const toggleMethod = () => {
    setMethod(!showMethod);
    setFormData({
      bankHolderName: "",
      bankName: "",
      ifscCode: "",
      accountNo: "",
      upi: "",
      image: null,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMethod(event.target.value);
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setFile(event.target.files[0]);
  //   }
  // };

  const getBankingMethod = async () => {
    const { response } = await authServices.getBankingMethod({
      user_id: userId || "",
      status: "2",
      type: "DEPOSIT",
    });
    if (response?.status && response?.data) {
      setBankingMethod(response?.data);
    } else {
      setBankingMethod([]);
    }
  };

  const getDepositMethod = async (filters: any = {}) => {
    const { response } = await authServices.getDepositMethod({
      user_id: userId || "",
      ...filters,
    });
    if (response?.status && response?.data) {
      setDepositMethod(response?.data);
    } else {
      setDepositMethod([]);
    }
  };

  useEffect(() => {
    getDepositMethod({});
    getBankingMethod();
    // eslint-disable-next-line
  }, []);

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value;
    setType(type);

    getDepositMethod({
      ...(type === "Inactive" && { is_delete: true }),
    });
  };

  const validateForm = (data = formData) => {
    let isValid = true;
    const errors: any = {};

    const obj = bankingMethod.find((item) => item._id === selectedMethod);

    if (obj?.category === "BANK") {
      if (!data.bankHolderName.trim()) {
        errors.bankHolderName = "Bank Holder Name is required.";
        isValid = false;
      } else {
        errors.bankHolderName = "";
      }

      if (!data.bankName.trim()) {
        errors.bankName = "Bank Name is required";
        isValid = false;
      } else {
        errors.bankName = "";
      }

      if (!data.accountNo.trim() || data.accountNo.trim().length < 10) {
        errors.accountNo = "Account No is required";
        isValid = false;
      } else {
        errors.accountNo = "";
      }

      if (!data.ifscCode.trim() || data.ifscCode.trim().length !== 11) {
        errors.ifscCode = "IFSC Code is required";
        isValid = false;
      } else {
        errors.ifscCode = "";
      }
    } else {
      if ((!data.upi.trim() || data.upi.trim().length < 6) && !data.image) {
        errors.upi = "UPI/PAYTM/GOOGLEPAY...etc or Image is required";
        errors.image = "UPI/PAYTM/GOOGLEPAY...etc or Image is required";
        isValid = false;
      } else {
        errors.upi = "";
        errors.image = "";
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let dataValue;

    if (e.target instanceof HTMLInputElement && e.target.type === "file") {
      dataValue = e.target.files?.[0] || null;
    } else {
      dataValue = value;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: dataValue,
    }));

    setTouchedFields((prevState) => ({
      ...prevState,
      [name]: true,
    }));

    validateForm({ ...formData, [name]: value });
  };

  const createMethod = async () => {
    const data = new FormData();

    const obj = bankingMethod.find((item) => item._id === selectedMethod);

    data.append("user_id", userId || "");
    data.append("method_id", selectedMethod);

    if (obj?.category === "BANK") {
      data.append("bank_name", formData.bankName);
      data.append("bank_holder_name", formData.bankHolderName);
      data.append("ifsc_code", formData.ifscCode);
      data.append("account_no", formData.accountNo);
    } else {
      formData.upi && data.append("others", formData.upi);
      formData.image && data.append("payment_qr", formData.image);
    }

    const { response } = await authServices.createDepositMethod(data);
    if (response?.status) {
      snackbarUtil.success(response.msg);
      getDepositMethod({});
      toggleMethod();
    } else {
      snackbarUtil.error(
        response?.msg || "An error occurred. Please try again."
      );
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      createMethod();
    } else {
      setTouchedFields({
        bankHolderName: true,
        bankName: true,
        ifscCode: true,
        accountNo: true,
        upi: true,
        image: true,
      });
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
          <BreadCrumb title="Deposit Methods" pageTitle="Dashboard" back />
        </div>

        <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <Form.Select
              className="me-1"
              value={type}
              onChange={handleTypeChange}
            >
              {/* <option value="All">All</option> */}
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Form.Select>
          </div>
          <Button variant="subtle-dark" onClick={toggleMethod}>
            Add New
          </Button>
        </div>
        <Card className="mt-3">
          <Card.Body>
            <DepositMethodTable setType={setType} depositMethod={depositMethod} getDepositMethod={getDepositMethod} />
          </Card.Body>
        </Card>
      </Container>

      <Modal
        show={showMethod}
        onHide={toggleMethod}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold">
          Add New Method
          <Button variant="light btn-sm" onClick={() => toggleMethod()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3 mx-auto">
            <div className="input-group">
              <div className="input-group-text">Select Method</div>
              <Form.Select value={selectedMethod} onChange={handleSelectChange}>
                <option value="">Please Select Method</option>
                {bankingMethod.map((item) => (
                  <option value={item?._id}>{item?.name}</option>
                ))}
              </Form.Select>
            </div>
          </div>

          <>
            {(() => {
              const obj = bankingMethod.find(
                (item) => item._id === selectedMethod
              );

              return (
                <>
                  {!obj?.category ? (
                    <></>
                  ) : obj?.category === "BANK" ? (
                    <>
                      <div className="mt-3 mx-auto">
                        <div className="input-group">
                          <div className="input-group-text">
                            Bank Holder Name:
                          </div>
                          <Form.Control
                            type="text"
                            placeholder="Enter Bank Holder Name"
                            name="bankHolderName"
                            value={formData.bankHolderName}
                            onChange={handleInputChange}
                            isInvalid={
                              !!formErrors.bankHolderName &&
                              touchedFields.bankHolderName
                            }
                          />
                          {formErrors.bankHolderName &&
                            touchedFields.bankHolderName && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors.bankHolderName}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </div>

                      <div className="mt-3 mx-auto">
                        <div className="input-group">
                          <div className="input-group-text">Bank Name:</div>
                          <Form.Control
                            type="text"
                            placeholder="Enter Bank Name"
                            name="bankName"
                            value={formData.bankName}
                            onChange={handleInputChange}
                            isInvalid={
                              !!formErrors.bankName && touchedFields.bankName
                            }
                          />
                          {formErrors.bankName && touchedFields.bankName && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.bankName}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </div>

                      <div className="mt-3 mx-auto">
                        <div className="input-group">
                          <div className="input-group-text">IFSC Code:</div>
                          <Form.Control
                            type="text"
                            placeholder="Enter ifsc code"
                            name="ifscCode"
                            value={formData.ifscCode}
                            onChange={handleInputChange}
                            isInvalid={
                              !!formErrors.ifscCode && touchedFields.ifscCode
                            }
                          />
                          {formErrors.ifscCode && touchedFields.ifscCode && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.ifscCode}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </div>

                      <div className="mt-3 mx-auto">
                        <div className="input-group">
                          <div className="input-group-text">Account No:</div>
                          <Form.Control
                            type="number"
                            placeholder="Enter acc no"
                            name="accountNo"
                            value={formData.accountNo}
                            onChange={handleInputChange}
                            isInvalid={
                              !!formErrors.accountNo && touchedFields.accountNo
                            }
                          />
                          {formErrors.accountNo && touchedFields.accountNo && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.accountNo}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-3 mx-auto">
                        <div className="input-group">
                          <div className="input-group-text">
                            UPI/PAYTM/GOOGLEPAY...etc :
                          </div>
                          <Form.Control
                            type="text"
                            placeholder="Enter UPI/PAYTM/GOOGLEPAY...etc :"
                            name="upi"
                            value={formData.upi}
                            onChange={handleInputChange}
                            isInvalid={!!formErrors.upi && touchedFields.upi}
                          />
                          {formErrors.upi && touchedFields.upi && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.upi}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </div>
                      <div className="mt-3 mx-auto">
                        <div className="input-group">
                          <div className="input-group-text">Image :</div>
                          <Form.Control
                            name="image"
                            type="file"
                            onChange={handleInputChange}
                            isInvalid={
                              !!formErrors.image && touchedFields.image
                            }
                          />
                          {formErrors.image && touchedFields.image && (
                            <Form.Control.Feedback type="invalid">
                              {formErrors.image}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })()}
          </>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button onClick={() => handleSubmit()}>Update</Button>
          <Button variant="subtle-dark" onClick={() => toggleMethod()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DepositMethod;
