import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ToggleSwitch from "Common/ToggleSwitch";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface MatchSettingProps {
  show: boolean;
  clickHandler: () => void;
  limits: any;
  id?: string;
  getLimit: any;
  fancyShow?: boolean;
  name?: string;
  showOdds?: boolean;
  marketId?: string;
  sportIds: string;
  marketName: string;
  seriesIdss?: string;
}

const LimitSetting = ({
  show,
  clickHandler,
  limits,
  getLimit,
  name,
  marketId,
  sportIds,
  marketName,
  seriesIdss,
}: MatchSettingProps) => {
  const initialFormData: Record<string, string> = {
    market_min_stack: "",
    market_max_stack: "",
    market_min_odds_rate: "",
    market_max_odds_rate: "",
    market_max_profit: "",
    market_advance_bet_stake: "",
    min_volume_limit: "",
    inplay_max_volume_stake_0_10: "",
    inplay_max_volume_stake_10_40: "",
    inplay_max_volume_stake_40: "",
    max_volume_stake_0_10: "",
    max_volume_stake_10_40: "",
    max_volume_stake_40: "",
    session_min_stack: "",
    session_max_stack: "",
    session_max_profit: "",
    market_bookmaker_min_odds_rate: "",
    market_bookmaker_max_odds_rate: "",
    market_back_rate_range: "",
    market_lay_rate_range: "",
  };

  const [formData, setFormData] = useState<any>(initialFormData);
  const [errors, setErrors] = useState<any>({});
    const [advanceMarket, setAdvanceMarket] = useState(true);
    
    // console.log(limits?.validations, "limitslimits")

  useEffect(() => {
    if (limits && JSON.stringify(limits) !== JSON.stringify(formData)) {
      setFormData({
        ...initialFormData,
        ...limits.limites,
      });
    }
    // eslint-disable-next-line
  }, [limits]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };

    setFormData(newFormData);
    validateField(name, value, newFormData);
  };

  const validateField = (name: string, value: string, newFormData: any) => {
    const parsedValue = parseFloat(value);

    if (!value) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "This field is required.",
      }));
      return;
    }

    // if (
    //   name.includes("stack") ||
    //   name.includes("stake") ||
    //   name.includes("profit")
    // ) {
    //   const [fieldPrefix, range] = name.split("_");
    //   const oppositeField = `${fieldPrefix}_${
    //     range === "min" ? "max" : "min"
    //   }_${name.split("_")[2]}`;
    //   const oppositeValue = parseFloat(newFormData[oppositeField] || "0");

    //   if (
    //     (range === "min" && parsedValue > oppositeValue) ||
    //     (range === "max" && parsedValue < oppositeValue)
    //   ) {
    //     setErrors((prevErrors: any) => ({
    //       ...prevErrors,
    //       [name]: `Value should be ${
    //         range === "min" ? "less than" : "greater than"
    //       } ${oppositeField.replace(/_/g, " ")}.`,
    //     }));
    //     return;
    //   }
    // }

    const maxValues = {
      market_max_stack: limits?.validations?.market_max_stack_max_limit,
      market_max_odds_rate: limits?.validations?.market_max_odds_rate,
      market_max_profit: limits?.validations?.market_max_profit_max_limit,
      market_advance_bet_stake: limits?.validations?.market_advance_bet_stake_max_limit,
      session_max_profit: limits?.validations?.session_max_profit_max_limit,
      session_max_stack: limits?.validations?.session_max_stack_max_limit,
    };

    if (
      name in maxValues &&
      parsedValue > maxValues[name as keyof typeof maxValues]
    ) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: `Value should not exceed ${maxValues[
          name as keyof typeof maxValues
        ].toLocaleString()}.`,
      }));
    } else {
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const getUpdateLimits = async (data: any) => {
    const filteredData = Object.entries(data)
      .filter(([key, value]) => value !== "" && value !== limits?.limites[key])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (Object.keys(filteredData).length === 0) {
      snackbarUtil.info("No changes detected.");
      return;
    }

    const payload = {
      [marketName]: seriesIdss || "",
      values: filteredData,
    };

    const { response } = await authServices.updateLimits(payload);

    if (response) {
      response?.status
        ? snackbarUtil.success(response?.msg)
        : snackbarUtil.error(response?.msg);
      clickHandler();
    }
  };

  const handleSave = () => {
    if (Object.values(errors).some(Boolean)) {
      return;
    }
    getUpdateLimits(formData);
  };

  const renderFormControl = (
    label: string,
    name: string,
    placeholder: string
  ) => (
    <Col lg={3}>
      <OverlayTrigger
        show={!!errors[name]}
        placement="top"
        overlay={<Tooltip>{errors[name] || ""}</Tooltip>}
      >
        <div className="form-floating">
          <Form.Control
            name={name}
            type="number"
            placeholder={placeholder}
            value={formData[name] || ""}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSave();
              }
            }}
          />
          <Form.Label>{label}</Form.Label>
          {/* {errors[name] && <div className="text-danger">{errors[name]}</div>} */}
        </div>
      </OverlayTrigger>
    </Col>
  );
  return (
    <Modal
      size="xl"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="g-3">
            <h5 className="text-primary mb-0">Market Setting</h5>
            {renderFormControl(
              "Min. Stake Amount",
              "market_min_stack",
              "Min. Stake Amount"
            )}
            {renderFormControl(
              "Max. Stake Amount",
              "market_max_stack",
              "Max. Stake Amount"
            )}
            {renderFormControl(
              "Min. Odds Rate",
              "market_min_odds_rate",
              "Min. Odds Rate"
            )}
            {renderFormControl(
              "Max. Odds Rate",
              "market_max_odds_rate",
              "Max. Odds Rate"
            )}
            {(marketName?.includes("sport_id") ||
              marketName?.includes("match_id") ||
              marketName?.includes("series_id") ||
              name == "Bookmaker") && (
              <>
                {renderFormControl(
                  "Min. Bookmaker odds rate",
                  "market_bookmaker_min_odds_rate",
                  "Min. Bookmaker odds rate"
                )}
                {renderFormControl(
                  "Max. Bookmaker odds rate",
                  "market_bookmaker_max_odds_rate",
                  "Max. Bookmaker odds rate"
                )}
                {renderFormControl(
                  " Market Back Rate",
                  "market_back_rate_range",
                  " Market Back Rate"
                )}
                {renderFormControl(
                  " Market Lay Rate",
                  "market_lay_rate_range",
                  " Market Lay Rate"
                )}
              </>
            )}
            {renderFormControl(
              "Market Max Profit",
              "market_max_profit",
              "Market Max Profit"
            )}
            {renderFormControl(
              "Before Inplay Match Stake",
              "market_advance_bet_stake",
              "Before Inplay Match Stake"
            )}
            {renderFormControl(
              "Min Volume Stake",
              "min_volume_limit",
              "Min Volume Stake"
            )}

            <div className="d-flex">
              <h5 className="text-primary mb-0">
                Advance Market Stake Setting
              </h5>
              <ToggleSwitch
                checked={advanceMarket}
                className="ms-2"
                onChange={() => setAdvanceMarket(!advanceMarket)}
              />
            </div>

            {advanceMarket && (
              <>
                {renderFormControl(
                  "Max Stake (0-10k gbp)",
                  "inplay_max_volume_stake_0_10",
                  "Max Stake (0-10k gbp)"
                )}
                {renderFormControl(
                  "Max Stake (10k-40k gbp)",
                  "inplay_max_volume_stake_10_40",
                  "Max Stake (10k-40k gbp)"
                )}
                {renderFormControl(
                  "Max Stake (>40k gbp)",
                  "inplay_max_volume_stake_40",
                  "Max Stake (>40k gbp)"
                )}
              </>
            )}

            <h5 className="text-primary mb-0">Market Stake Setting</h5>
            {renderFormControl(
              "Max Stake (0-10k gbp)",
              "max_volume_stake_0_10",
              "Max Stake (0-10k gbp)"
            )}
            {renderFormControl(
              "Max Stake (10k-40k gbp)",
              "max_volume_stake_10_40",
              "Max Stake (10k-40k gbp)"
            )}
            {renderFormControl(
              "Max Stake (>40k gbp)",
              "max_volume_stake_40",
              "Max Stake (>40k gbp)"
            )}

            {sportIds === "4" &&
              name !== "Match Odds" &&
              marketName !== "market_id" && (
                <>
                  <h5 className="text-primary mb-0">Session Setting</h5>
                  {renderFormControl(
                    "Session Min Stake",
                    "session_min_stack",
                    "Session Min Stake"
                  )}
                  {renderFormControl(
                    "Session Max Stake",
                    "session_max_stack",
                    "Session Max Stake"
                  )}
                  {renderFormControl(
                    "Session Max Profit",
                    "session_max_profit",
                    "Session Max Profit"
                  )}
                </>
              )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={clickHandler}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LimitSetting;
