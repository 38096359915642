import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

//SimpleBar
import SimpleBar from "simplebar-react";

import { authServices } from "Utils/auth/services";
import ToggleSwitch from "./ToggleSwitch";

const NotificationDropdown = () => {
  const [total, setTotal] = useState<number>(0);
  const [transactionData, setTransactionData] = useState<TransactionData[]>([]);
  const [isNotification, setIsNotification] = useState<boolean | null>(null);
  const [notifications, setNotifications] = useState<string[]>(() => {
    const storedNotifications = localStorage.getItem("notifications");
    return storedNotifications ? JSON.parse(storedNotifications) : [];
  });

  const user_Detail = localStorage.getItem("adminDetails");
  let userDetails = user_Detail && JSON.parse(user_Detail || "");

  const getAllTransaction = async (filters: any = {}) => {
    const { response } = await authServices.getAllRequest({
      statement_type: "ALL",
      status: "ALL",
      limit: 50,
      page: 1,
    });
    if (response?.status && response?.data) {
      const allData = filterTransactions(response?.data?.data);
      setTransactionData(allData);
      setTotal(allData?.length);
      total < allData?.length && beepOnce();
    } else {
      setTransactionData([]);
      setTotal(0);
    }
  };

  useEffect(() => {
    if (
      isNotification &&
      userDetails?.user_type_id !== 0 &&
      userDetails?.is_b2c_dealer
    ) {
      getAllTransaction({});
      const interval = setInterval(getAllTransaction, 10000);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [isNotification, notifications, total]);

  useEffect(() => {
    isNotification != null &&
      localStorage.setItem("isNotification", JSON.stringify(isNotification));
  }, [isNotification]);

  useEffect(() => {
    const storedNotification = localStorage.getItem("isNotification");
    if (storedNotification !== null) {
      setIsNotification(JSON.parse(storedNotification));
    } else {
      setIsNotification(true);
    }
  }, []);

  const timeAgo = (dateString: string) => {
    const date = new Date(dateString); // Convert the input string to a Date object
    const now = new Date();

    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000); // Difference in seconds

    if (diffInSeconds < 60) {
      // Less than a minute ago
      if (diffInSeconds === 0) return "Just now";
      return `${diffInSeconds} sec ago`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60); // Difference in minutes
    if (diffInMinutes < 60) {
      // Less than an hour ago
      return `${diffInMinutes} min ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60); // Difference in hours
    if (diffInHours < 24) {
      // Less than a day ago
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24); // Difference in days
    if (diffInDays < 7) {
      // Less than a week ago
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7); // Difference in weeks
    if (diffInWeeks < 4) {
      // Less than a month ago
      return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
    }

    const diffInMonths = Math.floor(diffInDays / 30); // Difference in months
    if (diffInMonths < 12) {
      // Less than a year ago
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    }

    const diffInYears = Math.floor(diffInDays / 365); // Difference in years
    return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
  };

  const filterTransactions = (transactions: TransactionData[]) => {
    return transactions.filter(
      (transaction) =>
        !notifications.includes(transaction._id) &&
        (transaction.status?.toLowerCase() === "pending" ||
          transaction.status?.toLowerCase() === "progress")
    );
  };

  const handleTransactionClick = (id: string) => {
    const updatedNotifications = [...notifications, id];
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));

    const allData = transactionData.filter(
      (transaction) => !updatedNotifications.includes(transaction._id)
    );
    setTransactionData(allData);
    setTotal(allData?.length);
  };

  useEffect(() => {
    const storedNotifications = localStorage.getItem("notifications");
    if (storedNotifications) {
      const updatedNotifications = JSON.parse(storedNotifications);
      setNotifications(JSON.parse(storedNotifications));

      const allData = transactionData.filter(
        (transaction) => !updatedNotifications.includes(transaction._id)
      );
      setTransactionData(allData);
      setTotal(allData?.length);
    }
  }, []);

  const beepOnce = () => {
    const audio = new Audio("/beep/beep.mp3");
    audio?.play().catch((error) => {
      console.error("Playback failed:", error);
    });
    setTimeout(() => {
      audio.pause();
    }, 2000);
  };

  return (
    <React.Fragment>
      <Dropdown
        className="topbar-head-dropdown ms-1 header-item"
        id="notificationDropdown">
        <Dropdown.Toggle
          id="notification"
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-dark rounded-circle arrow-none">
          <i className="bi bi-bell fs-2xl"></i>
          {total > 0 && isNotification && (
            <span className="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger">
              <span className="notification-badge">{total}</span>
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown">
          <div className="dropdown-head rounded-top">
            <div className="p-3 border-bottom border-bottom-dashed">
              <Row className="align-items-center">
                <Col>
                  <h6 className="mb-0 fs-lg fw-semibold">
                    {" "}
                    Notifications{" "}
                    {total > 0 && isNotification && (
                      <span className="badge bg-danger-subtle text-danger fs-sm notification-badge">
                        {" "}
                        {total}
                      </span>
                    )}
                  </h6>
                  {isNotification && (
                    <p className="fs-md text-muted mt-1 mb-0">
                      You {total > 0 ? "have" : "don't have any"}{" "}
                      <span className="fw-semibold notification-unread">
                        {total}
                      </span>{" "}
                      unread messages
                    </p>
                  )}
                </Col>
                {/* <Dropdown className="col-auto"> */}
                {/* <Dropdown.Toggle as="a" data-bs-toggle="dropdown" className="link-secondary fs-md bg-transparent border-0 arrow-none"> */}
                {/* <i className="bi bi-three-dots-vertical"></i> */}
                <ToggleSwitch
                  On=""
                  Off=""
                  className="ms-2"
                  onChange={() => setIsNotification(!isNotification)}
                  checked={isNotification}
                />
                {/* </Dropdown.Toggle> */}
                {/* <Dropdown.Menu>
                                        
                                        <li><Dropdown.Item href="#!">All Clear</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#!">Mark all as read</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#!">Archive All</Dropdown.Item></li>
                                    </Dropdown.Menu> */}
                {/* </Dropdown> */}
              </Row>
            </div>
          </div>

          {total > 0 && isNotification && (
            <div className="py-2 ps-2" id="notificationItemsTabContent">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                <h6 className="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">
                  New
                </h6>

                {transactionData.map((items, index: number) =>
                  notifications.includes(items?._id) ? (
                    <></>
                  ) : (
                    <div className="text-reset notification-item d-block dropdown-item position-relative unread-message">
                      <div className="d-flex">
                        <div className="avatar-xs me-3 flex-shrink-0">
                          <span className="avatar-title bg-info-subtle text-info rounded-circle fs-lg">
                            <i className="bx bx-badge-check"></i>
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <Link
                            to={`/deposit-withdraw/${items?._id}`}
                            onClick={() => handleTransactionClick(items?._id)}
                            className="stretched-link">
                            <h6 className="mt-0 fs-md mb-2 lh-base">
                              You got a{" "}
                              <b>
                                {items?.statement_type === "DEPOSIT_REQUEST"
                                  ? "Deposit"
                                  : "Withdraw"}{" "}
                                Request
                              </b>{" "}
                              form <b>{items?.user_name}</b> of amount{" "}
                              <b>{items.amount}</b>.
                            </h6>
                          </Link>
                          <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {timeAgo(items.generated_at)}
                            </span>
                          </p>
                        </div>
                        <div className="px-2 fs-md">
                          <div
                            className="form-check notification-check"
                            onClick={() => handleTransactionClick(items?._id)}>
                            <i
                              className="ri-close-line label-icon align-middle fs-lg"
                              style={{ color: "red" }}></i>
                            {/* <input className="form-check-input" type="checkbox" value="" id="all-notification-check01" />
                                                <label className="form-check-label" htmlFor="all-notification-check01"></label> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}

                {/* <div className="text-reset notification-item d-block dropdown-item position-relative unread-message">
                                <div className="d-flex">
                                    <div className="position-relative me-3 flex-shrink-0">
                                        <img src={avatar2} className="rounded-circle avatar-xs" alt="user-pic" />
                                        <span className="active-badge position-absolute start-100 translate-middle p-1 bg-success rounded-circle">
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    </div>
                                    <div className="flex-grow-1">
                                        <Link to="#!" className="stretched-link">
                                            <h6 className="mt-0 mb-1 fs-md fw-semibold">Angela Bernier</h6>
                                        </Link>
                                        <div className="fs-sm text-muted">
                                            <p className="mb-1">Answered to your comment on the cash flow forecast&apos;s graph 🔔.</p>
                                        </div>
                                        <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                            <span><i className="mdi mdi-clock-outline"></i> 48 min ago</span>
                                        </p>
                                    </div>
                                    <div className="px-2 fs-md">
                                        <div className="form-check notification-check">
                                            <input className="form-check-input" type="checkbox" value="" id="all-notification-check02" />
                                            <label className="form-check-label" htmlFor="all-notification-check02"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-reset notification-item d-block dropdown-item position-relative unread-message">
                                <div className="d-flex">
                                    <div className="avatar-xs me-3 flex-shrink-0">
                                        <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                                            <i className='bx bx-message-square-dots'></i>
                                        </span>
                                    </div>
                                    <div className="flex-grow-1">
                                        <Link to="#!" className="stretched-link">
                                            <h6 className="mt-0 mb-2 fs-md lh-base">You have received <b className="text-success">20</b> new messages in the conversation
                                            </h6>
                                        </Link>
                                        <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                            <span><i className="mdi mdi-clock-outline"></i> 2 hrs ago</span>
                                        </p>
                                    </div>
                                    <div className="px-2 fs-md">
                                        <div className="form-check notification-check">
                                            <input className="form-check-input" type="checkbox" value="" id="all-notification-check03" />
                                            <label className="form-check-label" htmlFor="all-notification-check03"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h6 className="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">Read Before</h6>

                            <div className="text-reset notification-item d-block dropdown-item position-relative">
                                <div className="d-flex">

                                    <div className="position-relative me-3 flex-shrink-0">
                                        <img src={avatar8} className="rounded-circle avatar-xs" alt="user-pic" />
                                        <span className="active-badge position-absolute start-100 translate-middle p-1 bg-warning rounded-circle">
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    </div>

                                    <div className="flex-grow-1">
                                        <Link to="#!" className="stretched-link">
                                            <h6 className="mt-0 mb-1 fs-md fw-semibold">Maureen Gibson</h6>
                                        </Link>
                                        <div className="fs-sm text-muted">
                                            <p className="mb-1">We talked about a project on linkedin.</p>
                                        </div>
                                        <p className="mb-0 fs-2xs fw-medium text-uppercase text-muted">
                                            <span><i className="mdi mdi-clock-outline"></i> 4 hrs ago</span>
                                        </p>
                                    </div>
                                    <div className="px-2 fs-md">
                                        <div className="form-check notification-check">
                                            <input className="form-check-input" type="checkbox" value="" id="all-notification-check04" />
                                            <label className="form-check-label" htmlFor="all-notification-check04"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </SimpleBar>
              <div className="notification-actions" id="notification-actions">
                <div className="d-flex text-muted justify-content-center align-items-center">
                  Select{" "}
                  <div
                    id="select-content"
                    className="text-body fw-semibold px-1">
                    0
                  </div>{" "}
                  Result{" "}
                  <Button
                    variant="link-danger"
                    className="btn-link p-0 ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#removeNotificationModal">
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
