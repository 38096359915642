import { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface DepositModalProps {
    show: boolean;
    clickHandler: any;
    userIds: string;
    getUserList: () => Promise<void>;
    userDetails: any;
    userName: string;
}

interface CommissionData {
    match_commission: number;
    session_commission: number;
}

interface FormData {
    match_commission: string;
    session_commission: string;
    password: string;
}

const CommissionSetting = ({ show, clickHandler, userIds, getUserList, userDetails, userName }: DepositModalProps) => {
    const [commissionData, setCommissionData] = useState<CommissionData | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(true);
    const [formData, setFormData] = useState<FormData>({
        match_commission: '',
        session_commission: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState({
        match_commission: '',
        session_commission: '',
        password: 'Please enter password'
    });

    const getCommissionDetails = async (id: string) => {
        const { response } = await authServices.getCommittion({ user_id: id });
        setCommissionData(response?.data);
    };

    useEffect(() => {
        if (userIds && show) getCommissionDetails(userIds);
    }, [userIds, show]);

    useEffect(() => {
        if (commissionData) {
            setFormData({
                match_commission: commissionData?.match_commission?.toString(),
                session_commission: commissionData?.session_commission?.toString(),
                password: ''
            });
        }
    }, [commissionData]);

    useEffect(() => {
        if (!show) {
            setFormData({
                match_commission: '',
                session_commission: '',
                password: ''
            });
            setFormErrors({
                match_commission: '',
                session_commission: '',
                password: ''
            });
        }
    }, [show]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        
        setFormData((prevState: FormData) => ({
            ...prevState,
            [name]: value,
        }));

        // Validate after updating the form data
        validateForm({ [name]: value });
    };

    const validateForm = (changedField?: { [key: string]: string }) => {
        let isValid = true;
        const errors: any = { ...formErrors }; // Start with existing errors

        if (changedField) {
            const fieldName = Object.keys(changedField)[0];
            const fieldValue = changedField[fieldName];

            if (fieldName === 'match_commission' || fieldName === 'session_commission') {
                const value = Number(fieldValue);
                if (!fieldValue || isNaN(value) || value < 1 || value >= 100) {
                    errors[fieldName] = 'Please enter a valid commission between 1 and 99';
                    isValid = false;
                } else {
                    errors[fieldName] = ''; 
                }
            }
          
            
            // if (fieldName === 'password') {
            //     if (!fieldValue || fieldValue.trim() === '') {
            //         errors.password = 'Please enter password';
            //         isValid = false;
            //     } else {
            //         errors.password = ''; // Clear error if valid
            //     }
            // }
        } else {
            if (!formData.match_commission || isNaN(Number(formData.match_commission)) || Number(formData.match_commission) < 1 || Number(formData.match_commission) >= 100) {
                errors.match_commission = 'match commission must be less than or equal to 99';
                isValid = false;
            }

            if (!formData.session_commission || isNaN(Number(formData.session_commission)) || Number(formData.session_commission) < 1 || Number(formData.session_commission) >= 100) {
                errors.session_commission = 'session commission must be less than or equal to 99';
                isValid = false;
            }

            // if (!formData.password || formData.password.trim() === '') {
            //     errors.password = 'Please enter password';
            //     isValid = false;
            // }
        }

        setFormErrors(errors);
        return isValid;
    };

    const updateCommission = async () => {
        const { response } = await authServices.getUpdateCommittion({
            match_commission: Number(formData.match_commission),
            session_commission: Number(formData.session_commission),
            user_id: userIds,
        });

        if (response?.status) {
            snackbarUtil.success(response.msg);
            clickHandler();
            getCommissionDetails(userIds);
        }else{
            snackbarUtil.error(response?.msg);
        }
    };

    const handleSubmit = () => {
        if (validateForm()) {
            // if (formData?.password === userDetails?.password) {
                updateCommission();
            // } else {
            //     snackbarUtil.error("Please Enter Valid Password");
            // }
        }
    };

    const adjustCommission = (field: keyof FormData, value: number) => {
        const newValue = Math.min(100, Math.max(0, Number(formData[field]) + value));
        setFormData((prevState: FormData) => ({
            ...prevState,
            [field]: newValue.toString(),
        }));
        validateForm({ [field]: newValue.toString() });
    };

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Update Commission Setting of {userName}</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Form action="#">
                    <Row className="g-3">
                        <Col>
                            <div className="fs-md mb-2">Market Commission</div>
                            <div className="input-step step-primary">
                                <Button
                                    className="minus"
                                    onClick={() => adjustCommission('match_commission', -1)}
                                >
                                    –
                                </Button>
                                <Form.Control
                                    type="number"
                                    name="match_commission"
                                    className="product-quantity"
                                    value={formData.match_commission}
                                    min="0"
                                    max="100"
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === 'e' ||
                                            e.key === '+' ||
                                            e.key === 'E' ||
                                            e.key === '.'
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <Button
                                    className="plus"
                                    onClick={() => adjustCommission('match_commission', 1)}
                                >
                                    +
                                </Button>
                            </div>
                            {formErrors.match_commission && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {formErrors.match_commission}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Col>
                            <div className="fs-md mb-2">Session Commission</div>
                            <div className="input-step step-primary">
                                <Button
                                    className="minus"
                                    onClick={() => adjustCommission('session_commission', -1)}
                                >
                                    –
                                </Button>
                                <Form.Control
                                    type="number"
                                    className="product-quantity"
                                    name="session_commission"
                                    value={formData.session_commission}
                                    min="0"
                                    max="100"
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === 'e' ||
                                            e.key === '+' ||
                                            e.key === 'E' ||
                                            e.key === '.'
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <Button
                                    className="plus"
                                    onClick={() => adjustCommission('session_commission', 1)}
                                >
                                    +
                                </Button>
                            </div>
                            {formErrors.session_commission && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {formErrors.session_commission}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        {/* <Col md={12}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="password"
                                type={showPassword ? "password" : "text"}
                                placeholder="Enter Login Password"
                                onChange={handleInputChange}
                                value={formData?.password}
                                isInvalid={!!formErrors.password}
                                className="position-relative"
                                required
                            />
                            <Button
                                variant="link"
                                className="position-absolute end-0 top-5 bottom-0 text-decoration-none text-muted password-addon"
                                type="button"
                                id="password-addon"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i className={`${!showPassword ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
                            </Button>
                            {formErrors.password && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {formErrors.password}
                                </Form.Control.Feedback>
                            )}
                        </Col> */}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="text-end border-top">
                <Button variant="subtle-primary" onClick={handleSubmit}>Update</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CommissionSetting;
