import BreadCrumb from "Common/BreadCrumb";
import DepositTable from "Common/Tables/DepositTable";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import Deposit from "../Deposit";
import DepositData from "./DepositData";
import WithdrawData from "./WithdrawData";
import AllDepositWithdraw from "./AllDepositWithdraw";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";

type ChildRef = {
  callChildFunction: () => void;
};

const DepositWithdraw = () => {
  const childRef = useRef<ChildRef>(null);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [filters, setFilters] = useState({
    userName: "",
    parentName: "",
    minAmount: "",
    maxAmount: "",
    type: "",
    status: "ALL",
  });
  const [withdrawData, setWithdrawData] = useState<WithdrawData[]>([]);
  const userId = localStorage.getItem("userId");
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );

  const handleClick = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
  };

  const filterSubmit = () => {};

  const getAllDeposit = async () => {
    const { response } = await authServices.getAllDepositNew({
      user_id: userId || "",
      status: filters?.status,
      fullSearch: {
        ...(filters?.type && { statement_type: filters?.type }),
        // statement_type: filters?.type,
        ...(filters?.userName && { user_name: filters?.userName }),
        ...(filters?.parentName && { parent_user_name: filters?.parentName }),
      },
      limit: limit,
      page: page,
      ...(filters?.maxAmount && { highestAmount: Number(filters?.maxAmount) }),
      ...(filters?.minAmount && { lowestAmount: Number(filters?.minAmount) }),
      ...(endDate && { to_date: endDate }),
      ...(startDate && { from_date: startDate }),
    });
    if (response?.status && response?.data) {
      setWithdrawData(response?.data);
      setListTotal({
        total: response?.metadata?.total / response?.metadata?.limit || 0,
        page: response?.metadata?.page || 1,
      });
    } else {
      setWithdrawData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    getAllDeposit();
  };

  useEffect(() => {
    getAllDeposit();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
          <BreadCrumb
            title="Deposit and Withdraw List"
            pageTitle="Dashboard"
            back
          />
          {/* <Button variant="subtle-dark" onClick={() => handleClick()}>
            <i className="fs-lg align-middle ri-refresh-line"></i> Refresh
          </Button> */}
        </div>
      </Container>
      <DepositData
        ref={childRef}
        filterSubmit={filterSubmit}
        getAllDeposit={getAllDeposit}
      />
      <WithdrawData getAllDeposit={getAllDeposit} />
      <AllDepositWithdraw
        setFilters={setFilters}
        filters={filters}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        limit={limit}
        page={page}
        listTotal={listTotal}
        handlePageFilter={handlePageFilter}
        getAllDeposit={getAllDeposit}
        withdrawData={withdrawData}
      />
    </div>
  );
};

export default DepositWithdraw;
