import BreadCrumb from "Common/BreadCrumb";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

const UpdateBonus = () => {
  const [bonus, setBonus] = useState({
    firstDeposit: "",
    everyDeposit: "",
  });

  const handleChange = (e: any) => {
    setBonus({
      ...bonus,
      [e.target.name]: e.target.value,
    });
  };

  const getUpdateBonus = async (payload: any) => {
    const { response } = await authServices.getUpdateBonus({
      bonus_data: payload,
    });

    console.log(response, "payloadpayload");
    if (response) {
      if (response?.status) {
        snackbarUtil.success(response?.msg);
        setBonus({
          firstDeposit: "",
          everyDeposit: "",
        });
      } else {
        snackbarUtil.error(response?.msg);
      }
    }
  };

  const handleSubmit = () => {
    let bonusData = [];

    if (bonus.firstDeposit) {
      bonusData.push({
        name: "First Deposit",
        bonus_type: "first_deposit",
        is_active: true,
        percentage: bonus.firstDeposit,
      });
    }

    if (bonus.everyDeposit) {
      bonusData.push({
        name: "Every Deposit",
        bonus_type: "every_deposit",
        is_active: true,
        percentage: bonus.everyDeposit,
      });
    }

    if (bonusData.length === 0) {
      snackbarUtil.error("At least one field must be filled.");
      return;
    }

    const payload = bonusData;
    getUpdateBonus(payload);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title="Update Bonus" pageTitle="Dashboard" back />
        </div>

        <Card>
          <Card.Body>
            <Row className="align-items-center">
              <Col md={4}>
                <h5>First & Every Deposit: </h5>
              </Col>
              <Col md={6}>
                <Row className="align-items-center">
                  <Col md={4}>
                    <Form.Control
                      type="number"
                      className="product-quantity mb-2"
                      name="firstDeposit"
                      placeholder="First Deposit"
                      value={bonus.firstDeposit}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="number"
                      className="product-quantity mb-2"
                      name="everyDeposit"
                      placeholder="Every Deposit"
                      value={bonus.everyDeposit}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={4}>
                    <Button onClick={handleSubmit} className="mb-2">Upload Bonus</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default UpdateBonus;
