export const baseURL: string =
  process.env.REACT_APP_PROD === "true"
    ? `${window.location.origin}/api/v1`
    : (process.env.REACT_APP_API_BASEURL as string);

export const pageUrlData: string =
  process.env.REACT_APP_CHIPS_SUMMARY === "true"
    ? "landing"
    : process.env.REACT_APP_DIAMOND === "true"
    ? "register"
    : "default-page";
