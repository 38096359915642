import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface SettledBetsTableProps {
  settledBetsData: OpenBetsData[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const SettledBetsTable = ({
  settledBetsData,
  page,
  limit,
  listTotal,
  handlePageFilter,
}: SettledBetsTableProps) => {
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [fixedCountryData, setFixedCountryData] = useState<any>(null);


  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const [showBetAddress, setBetAddress] = useState(null);
  const ToggleBetAddress = (betId: any) => {
    setBetAddress((prevBetId) => (prevBetId === betId ? null : betId));
  };

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setBetAddress(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const defaultTable = useMemo(() => {
    if (!settledBetsData || !Array.isArray(settledBetsData)) {
      return [];
    }
    return settledBetsData.map((items, index: number) => ({
      no: (page - 1) * limit + index + 1,
      date: items?.createdAt,
      domainName: items?.domain_name || "",
      username: items?.user_name,
      sports: items?.sport_name,
      series: items?.series_name,
      market: items?.market_name,
      match: items?.match_name,
      selection: items?.selection_name,
      isBack: items?.is_back,
      rate: items?.odds,
      size: items?.size,
      isFancy: items?.is_fancy,
      stake: items?.stack,
      pnl: items?.p_l.toFixed(2),
      result: items?.winner_name,
      userId: items?.user_id,
      ipAddress: items?.ip_address,
      geolocation: items?.geolocation,
      betId: items?.bet_id,
    }));
    // eslint-disable-next-line
  }, [settledBetsData]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },

      
      {
        header: "User Name",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className="cursor-pointer text-info"
              onClick={() => {
                toggleList(cell?.row?.original?.username);
                getUserAgent(cell?.row?.original?.userId);
              }}
            >
              {cell?.row?.original?.username.replace(/\(\w+\)$/, '').trim()}
            </span>
          );
        },
      },
      {
        header: "Rate/Size",
        accessorKey: "rate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span>
              {cell?.row?.original?.rate}
              {cell?.row?.original?.isFancy === 1 &&
                ` / ${cell?.row?.original?.size}`}
            </span>
          );
        },
      },
      {
        header: "Stake",
        accessorKey: "stake",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span>
              {cell?.row?.original?.stake &&
                cell?.row?.original?.stake?.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "P | L",
        accessorKey: "pnl",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`cursor-pointer text-${
                cell?.row?.original?.pnl < 0 ? "danger" : "secondary"
              }`}
            >
              {cell?.row?.original?.pnl &&
                Number(cell?.row?.original?.pnl)?.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "Sports | Series",
        accessorKey: "sports",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0 text-success">
                <strong>{cell?.row?.original?.sports}</strong>
              </p>
              <p className="mb-0">{cell?.row?.original?.series.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}</p>
            </div>
          );
        },
      },
      {
        header: "Market | Match",
        accessorKey: "market",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                <strong>{cell?.row?.original?.market.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}</strong>
              </p>
              <p className="mb-0 text-muted">{cell?.row?.original?.match.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}</p>
            </div>
          );
        },
      },
      {
        header: "Selection | Type",
        accessorKey: "isBack",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                <strong>{cell?.row?.original?.selection.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}</strong>
              </p>
              <p
                className={`mb-0 btn-${
                  cell?.row?.original?.isBack ? "back" : "lay"
                } d-inline px-2 rounded-pill`}
              >
                {cell?.row?.original?.isBack ? "Back" : "Lay"}
              </p>
            </div>
          );
        },
      },
    
      {
        header: "Result",
        accessorKey: "result",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.result}
            </span>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "domainName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.domainName}
            </span>
          );
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format("DD-MM-YY")}
              </p>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format(" hh:mm:ss:SSS A")}
              </p>
            </div>
          );
        },
      },
      
      {
        header: "IP Address",
        accessorKey: "ipAddress",
        enableColumnFilter: false,
        cell: (cell: any) => {
              const handleMouseEnter = () => {
                if (!fixedCountryData) {
                  const countryData =
                    cell?.row?.original?.geolocation &&
                    (typeof cell.row.original.geolocation === "string"
                      ? JSON.parse(cell.row.original.geolocation)
                      : cell.row.original.geolocation);
    
                  setFixedCountryData(countryData);
                  setBetAddress(cell?.row?.original?.betId);
                }
              };
              const handleMouseLeave = () => {
                setFixedCountryData(null);
              };
              
          return (
            <div className="position-relative">
              <span>
                <i
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="bi bi-globe me-1 cursor-pointer"
                  style={{ verticalAlign: "middle" }}
                ></i>
                {cell?.row?.original?.ipAddress}
              </span>
              {showBetAddress === cell?.row?.original?.betId && fixedCountryData && (
                <div
                  ref={tooltipRef}
                  className="bg-white p-3 rounded position-absolute"
                  style={{ right: "20px", minWidth: "200px", zIndex: "999" }}
                >
                  <div className="d-flex justify-content-between align-item-center mb-1">
                    <p className="fw-bold m-0">City : </p>
                    <p className="p-0 m-0"> {fixedCountryData.city}</p>
                  </div>
                  <div className="d-flex justify-content-between p-0 align-item-center mb-1">
                    <p className="fw-bold m-0">State : </p>
                    <p className="m-0"> {fixedCountryData.state}</p>
                  </div>
                  <div className="d-flex justify-content-between p-0 align-item-center">
                    <p className="fw-bold m-0">Country : </p>
                    <p className="m-0"> {fixedCountryData.country}</p>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [showBetAddress]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        sorting={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />
      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">
              ({selectedUser})
            </span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.map((item) => {
            return (
              <p>
                {item.name} ({item.user_name})
              </p>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SettledBetsTable;
