import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import TotalWithdrawTableNew from "./TotalWithdrawTableNew";
import BreadCrumb from "Common/BreadCrumb";

interface Props {
  setFilters: React.Dispatch<
    React.SetStateAction<{
      userName: string;
      parentName: string;
      minAmount: string;
      maxAmount: string;
      type: string;
      status: string;
    }>
  >;
  filters: {
    userName: string;
    parentName: string;
    minAmount: string;
    maxAmount: string;
    type: string;
    status: string;
  };
  setEndDate: React.Dispatch<React.SetStateAction<string | Date>>;
  setStartDate: React.Dispatch<React.SetStateAction<string | Date>>;
  startDate: string | Date;
  endDate: string | Date;
  limit: number;
  page: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (page: number, limit: number) => void;
  getAllDeposit: () => Promise<void>;
  withdrawData: WithdrawData[];
}

const AllDepositWithdraw = ({
  setFilters,
  filters,
  setEndDate,
  setStartDate,
  startDate,
  endDate,
  limit,
  page,
  listTotal,
  handlePageFilter,
  getAllDeposit,
  withdrawData,
}: Props) => {
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const clearFilter = () => {
    setFilters({
      userName: "",
      parentName: "",
      minAmount: "",
      maxAmount: "",
      type: "",
      status: "ALL",
    });
    getAllDeposit();
  };

  const filterSubmit = () => {
    getAllDeposit();
  };

  return (
    <div>
      <Card className="mt-3">
        <Card.Header className="border-bottom pb-3">
          <div className="d-flex align-items-center mb-3">
            <BreadCrumb title="All Deposit & Withdraw List" pageTitle="" />
            <Button variant="subtle-dark" onClick={()=>getAllDeposit()}>
              <i className="fs-lg align-middle ri-refresh-line"></i> Refresh
            </Button>
          </div>
          <hr />
          <Row className="align-items-end g-3">
            <Col lg={3}>
              <Form.Label>Start Date</Form.Label>
              <Flatpickr
                className="form-control"
                options={{
                  enableTime: true,
                  dateFormat: "Y-m-d H:i:s",
                  defaultDate: [startDate],
                  maxDate: endDate,
                }}
                value={startDate}
                onChange={([date]: [Date]) => {
                  setStartDate(new Date(date));
                }}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>End Date</Form.Label>
              <Flatpickr
                className="form-control"
                options={{
                  enableTime: true,
                  dateFormat: "Y-m-d H:i:s",
                  defaultDate: [endDate],
                  maxDate: currentDateTime(),
                }}
                value={endDate}
                onChange={([date]: [Date]) => {
                  setEndDate(new Date(date));
                }}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Min Amount</Form.Label>
              <Form.Control
                type="number"
                name="minAmount"
                value={filters.minAmount}
                placeholder="Enter Min Amount"
                onChange={handleInputChange}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Max Amount</Form.Label>
              <Form.Control
                type="number"
                name="maxAmount"
                value={filters.maxAmount}
                placeholder="Enter Max Amount"
                onChange={handleInputChange}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="userName"
                value={filters.userName}
                placeholder="Enter Username"
                onChange={handleInputChange}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Parent Username</Form.Label>
              <Form.Control
                type="text"
                name="parentName"
                value={filters.parentName}
                placeholder="Enter Parent Username"
                onChange={handleInputChange}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Payment Type</Form.Label>
              <Form.Select
                name="type"
                value={filters.type}
                onChange={handleInputChange}
              >
                <option value="">All</option>
                <option value="DEPOSIT_REQUEST">Deposit</option>
                <option value="WITHDRAW_REQUEST">Withdraw</option>
              </Form.Select>
            </Col>
            <Col lg={3}>
              <Form.Label>Payment Status</Form.Label>
              <Form.Select
                name="status"
                value={filters.status}
                onChange={handleInputChange}
              >
                <option value="ALL">All</option>
                <option value="ACCEPTED">Accepted</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </Form.Select>
            </Col>
            <Col lg={3}>
              <Button className="me-2" onClick={filterSubmit}>
                Search
              </Button>
              <Button variant="subtle-primary" onClick={clearFilter}>
                Clear
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <TotalWithdrawTableNew
            withdrawData={withdrawData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default AllDepositWithdraw;
