import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";

interface OpenBetsTableProps {
  openBetsData: OpenBetsData[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
  getBetsList: (filters?: any) => Promise<void>;
  handleOddsChange: (key: string, value: any) => void;
  oddsRange: { from: number; to: number };
  handleRateChange: (key: string, value: any) => void;
  rateRange: { from: number; to: number };
}

const OpenBetsTable = ({
  openBetsData,
  page,
  limit,
  listTotal,
  handlePageFilter,
  getBetsList,
  handleOddsChange,
  oddsRange,
  handleRateChange,
  rateRange
}: OpenBetsTableProps) => {
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [user_name, setUser_name] = useState("");
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [domainId, setDomainId] = useState<string>("");
  const [showBetAddress, setBetAddress] = useState(null);
    const [fixedCountryData, setFixedCountryData] = useState<any>(null);
  const [formValuesFilter, setFormValuesFilter] = useState<{
    [key: string]: string;
  }>({});

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  let location = useLocation();

  const ToggleBetAddress = (betId: any) => {
    setBetAddress((prevBetId) => (prevBetId === betId ? null : betId));
  };

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setBetAddress(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const {
      market_name,
      is_back,
      odds,
      stake,
      ip_address,
      liability,
      selection_name,
      sport_name,
    } = formValuesFilter;
    const searchQuery: any = {
      ...(user_name && { user_name }),
      ...(domainId &&
        domainId.length > 3 && {
          domain_name: domainId?.trim()?.toLowerCase(),
        }),
      ...(market_name && { market_name }),
      ...(selection_name && { selection_name }),
      ...(is_back && { is_back }),
      ...(ip_address && { ip_address }),
      ...(odds && { odds }),
      ...(stake && { stack: stake }),
      ...(liability && { liability }),
      ...(sport_name && { sport_id: sport_name }),
      delete_status: location.pathname === "/delete-bet" ? 1 : 2,
    };
    getBetsList({
      search: { ...searchQuery },
    });
    // eslint-disable-next-line
  }, [formValuesFilter, domainId, user_name]);

  const defaultTable = useMemo(() => {
    if (!openBetsData || !Array.isArray(openBetsData)) {
      return [];
    }
    return openBetsData?.map((items, index: number) => ({
      sNo: (page - 1) * limit + index + 1,
      date: items?.createdAt,
      DomainName: items?.domain_name || "",
      username: items?.user_name,
      sports: items?.sport_name,
      series: items?.series_name,
      market: items?.market_name,
      match: items?.match_name,
      selection: items?.selection_name,
      isBack: items?.is_back,
      rate: items?.odds,
      OddsRate: items?.odds,
      size: items?.size,
      isFancy: items?.is_fancy,
      stake: items?.stack,
      oddsStack: items?.stack,
      pnl: items?.liability,
      roundId: items?.bet_id,
      userId: items?.user_id,
      ip_address: items?.ip_address,
      geolocation: items?.geolocation,
    }));
    // eslint-disable-next-line
  }, [openBetsData]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "sNo",
        enableColumnFilter: false,
      },

      {
        header: "User Name",
        accessorKey: "user_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const name = cell?.row?.original?.username
            ?.replace(/\(\w+\)$/, "")
            .trim();
          return (
            <span
              className="cursor-pointer text-info"
              onClick={() => {
                toggleList(cell?.row?.original?.username);
                getUserAgent(cell?.row?.original?.userId);
              }}
            >
              {name}
            </span>
          );
        },
      },
      {
        header: "Rate/Size",
        accessorKey: "OddsRate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span>
              {cell?.row?.original?.OddsRate}
              {cell?.row?.original?.isFancy === 1 &&
                ` / ${cell?.row?.original?.size}`}
            </span>
          );
        },
      },
      {
        header: "Stake",
        accessorKey: "oddsStack",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{cell?.row?.original?.oddsStack?.toFixed(2)}</span>;
        },
      },
      {
        header: "Liability",
        accessorKey: "liability",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`cursor-pointer text-${
                cell?.row?.original?.pnl < 0 ? "danger" : "secondary"
              }`}
            >
              {cell?.row?.original?.pnl?.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "Sports | Series",
        accessorKey: "sports",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0 text-success">
                <strong>{cell?.row?.original?.sports}</strong>
              </p>
              <p className="mb-0">
                {cell?.row?.original?.series
                  .replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, "")
                  .trim()}
              </p>
            </div>
          );
        },
      },
      {
        header: "Market | Match",
        accessorKey: "market_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                <strong>
                  {cell?.row?.original?.market
                    .replace(/\((\d+_\d+|\d+\.\d+)\)$/, "")
                    .trim()}
                </strong>
              </p>
              <p className="mb-0 text-muted">
                {cell?.row?.original?.match
                  .replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, "")
                  .trim()}
              </p>
            </div>
          );
        },
      },
      {
        header: "Selection | Type",
        accessorKey: "selection_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                <strong>
                  {cell?.row?.original?.selection
                    .replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, "")
                    .trim()}
                </strong>
              </p>
              <p
                className={`mb-0 btn-${
                  cell?.row?.original?.isBack ? "back" : "lay"
                } d-inline px-2 rounded-pill`}
              >
                {cell?.row?.original?.isBack ? "Back" : "Lay"}
              </p>
            </div>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "DomainName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.DomainName}
            </span>
          );
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format("DD-MM-YY")}
              </p>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format(" hh:mm:ss:SSS A")}
              </p>
            </div>
          );
        },
      },
      

      // {
      //   header: "BetId",
      //   accessorKey: "betID",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => {
      //     return <span>{cell?.row?.original?.roundId}</span>;
      //   },
      // },
      {
        header: "IP Address",
        accessorKey: "ip_address",
        enableColumnFilter: false,
        cell: (cell: any) => {
              const handleMouseEnter = () => {
                if (!fixedCountryData) {
                  const countryData =
                    cell?.row?.original?.geolocation &&
                    (typeof cell.row.original.geolocation === "string"
                      ? JSON.parse(cell.row.original.geolocation)
                      : cell.row.original.geolocation);
    
                  setFixedCountryData(countryData);
                  setBetAddress(cell?.row?.original?.roundId);
                }
              };
    
              const handleMouseLeave = () => {
                setFixedCountryData(null);
              };

          return (
            <>
              <span>
                <i
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="bi bi-globe me-1 cursor-pointer"
                  style={{ verticalAlign: "middle" }}
                ></i>
                {cell?.row?.original?.ip_address}
              </span>
              {showBetAddress === cell?.row?.original?.roundId &&
                fixedCountryData && (
                  <div
                    ref={tooltipRef}
                    className="bg-white p-3 rounded position-absolute"
                    style={{ right: "20px", minWidth: "200px", zIndex: "999", boxShadow:"0px 8px 16px 0px rgba(0,0,0,0.2)" }}
                  >
                    <div className="d-flex justify-content-between align-item-center mb-1">
                      <p className="fw-bold m-0">City : </p>
                      <p className="p-0 m-0"> {fixedCountryData.city}</p>
                    </div>
                    <div className="d-flex justify-content-between p-0 align-item-center mb-1">
                      <p className="fw-bold m-0">State : </p>
                      <p className="m-0"> {fixedCountryData.state}</p>
                    </div>
                    <div className="d-flex justify-content-between p-0 align-item-center">
                      <p className="fw-bold m-0">Country : </p>
                      <p className="m-0"> {fixedCountryData.country}</p>
                    </div>
                  </div>
                )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [openBetsData, formValuesFilter, showBetAddress, fixedCountryData]
  );

  const getUserbyUserName = async (user_name: string) => {
    const { response } = await settingServices.getUserbyUserName({
      user_name: user_name?.trim()?.toLowerCase(),
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?.domain_name,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  useEffect(() => {
    getAllWebsite();
    // eslint-disable-next-line
  }, []);

  const handleSelectUser = (selectedOption: any) => {
    setUser_name(selectedOption ? selectedOption.value : "");
  };

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };

  const handleDomain = (selectedOption: any) => {
    setDomainId(selectedOption ? selectedOption.value : "");
  };

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        isFilter={true}
        sorting={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        handleUser={handleUser}
        setFormValuesFilter={setFormValuesFilter}
        formValuesFilter={formValuesFilter}
        userNameData={userNameData}
        userName={user_name}
        handleSelectUser={handleSelectUser}
        setUser_name={setUser_name}
        domainList={domainList}
        domainId={domainId}
        handleDomain={handleDomain}
        oddsRange={oddsRange}
        rateRange={rateRange}
        handleOddsChange={handleOddsChange}
        handleRateChange={handleRateChange}
      />
      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(1, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">
              ({selectedUser})
            </span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.map((item) => {
            return (
              <p>
                {item.name}({item.user_name})
              </p>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OpenBetsTable;
