import { useMemo } from "react";
import { Pagination } from "react-bootstrap";

interface ListPaginationProps {
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number) => void;
}

export const ListPagination = ({
  listTotal,
  handlePageFilter,
}: ListPaginationProps) => {
  const numbersArray = useMemo(() => {
    const total = Math.ceil(listTotal.total);
    return Array.from({ length: total }, (_, index) => index + 1);
  }, [listTotal.total]);

  const totalPages = numbersArray.length;
  const currentPage = listTotal.page;
  const maxVisiblePages = 7;
  const paginationItems = [];

  if (totalPages <= maxVisiblePages) {
    paginationItems.push(
      ...numbersArray.map((num) => (
        <Pagination.Item
          key={num}
          active={num === currentPage}
          onClick={() => handlePageFilter(num)}
        >
          {num}
        </Pagination.Item>
      ))
    );
  } else {
    paginationItems.push(
      <Pagination.Item
        key={1}
        active={1 === currentPage}
        onClick={() => handlePageFilter(1)}
      >
        1
      </Pagination.Item>
    );

    if (currentPage > 2) {
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageFilter(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 3) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    paginationItems.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={() => handlePageFilter(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="ms-sm-auto mt-3 mb-0">
      <Pagination.Item
        className={`${listTotal.page < 2 && "disabled"}`}
        onClick={() =>
          listTotal.page > 1 && handlePageFilter(listTotal.page - 1)
        }
      >
        <i className="mdi mdi-chevron-left"></i>
      </Pagination.Item>
      {paginationItems}
      <Pagination.Item
        className={`${listTotal.page !== numbersArray.length ? "" : "disabled"}`}
        onClick={() =>
          listTotal.page !== numbersArray.length &&
          handlePageFilter(listTotal.page + 1)
        }
      >
        <i className="mdi mdi-chevron-right"></i>
      </Pagination.Item>
    </Pagination>
  );
};
