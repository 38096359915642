export const validateField = (name: string, value: string, tooltipMsg: any, showTooltip: any, limitValidation: any,
    formData: any, setErrors: any, setTooltipMsg:any,
    setShowTooltip:any) => {
    const numericValue = Number(value);
    let errorMessage = "";
    const newTooltipMsg = { ...tooltipMsg };
    const newShowTooltip = { ...showTooltip };

    const addTooltip = (message: string) => {
      newTooltipMsg[name] = message;
      newShowTooltip[name] = true;
    };
    // if (name === "market_max_stack" && numericValue <= limitValidation?.market_max_stack_max_limit) {
    //   delete newTooltipMsg[name];
    //   newShowTooltip[name] = false;
    // }
    // if (name === "market_min_stack" && numericValue <= limitValidation?.market_max_stack_max_limit) {
    //   delete newTooltipMsg[name];
    //   newShowTooltip[name] = false;
    // }
    // if (name === "session_min_stack" && numericValue <= limitValidation?.market_max_stack_max_limit) {
    //   delete newTooltipMsg[name];
    //   newShowTooltip[name] = false;
    // }
    if (name === "market_bookmaker_max_odds_rate" && numericValue <= limitValidation?.market_bookmaker_max_odds_rate) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    if (name === "market_bookmaker_min_odds_rate" && numericValue > limitValidation?.market_bookmaker_min_odds_rate) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    // if (name === "market_min_stack" && numericValue >= limitValidation?.market_max_stack_min_limit) {
    //   delete newTooltipMsg[name];
    //   newShowTooltip[name] = false;
    // }
    if (name === "market_max_odds_rate" && numericValue <= limitValidation?.market_max_odds_rate) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    if (name === "market_min_odds_rate" && numericValue <= limitValidation?.market_min_odds_rate) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    if (name === "market_min_odds_rate" && numericValue > limitValidation?.market_min_odds_rate) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    if (name === "market_max_profit" && numericValue <= limitValidation?.market_max_profit_max_limit) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    if (name === "market_advance_bet_stake" && numericValue <= limitValidation?.market_advance_bet_stake_max_limit) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    // if (name === "session_max_stack" && numericValue <= limitValidation?.session_max_stack_max_limit) {
    //   delete newTooltipMsg[name];
    //   newShowTooltip[name] = false;
    // }
    if (name === "session_max_profit" && numericValue <= limitValidation?.session_max_profit_max_limit) {
      delete newTooltipMsg[name];
      newShowTooltip[name] = false;
    }
    if (!value) {
      errorMessage = "This field is required";
    }else if (
      name === "market_min_odds_rate" &&
      numericValue > Number(formData.market_max_odds_rate)
    ) {
      errorMessage = "Min value cannot be greater than max value";
    } else if (
      name === "market_max_odds_rate" &&
      numericValue < Number(formData.market_min_odds_rate)
    ) {
      errorMessage = "Max value cannot be less than min value";
    } else if (
      name === "inplay_max_volume_stake_40" &&
      numericValue < Number(formData.inplay_max_volume_stake_0_10)
    ) {
      errorMessage = "Max value cannot be less than min value";
    } else if (
      name === "max_volume_stake_40" &&
      numericValue < Number(formData.max_volume_stake_0_10)
    ) {
      errorMessage = "Max value cannot be less than min value";
    } else if (
      name === "market_bookmaker_min_odds_rate" &&
      numericValue > Number(formData.market_bookmaker_max_odds_rate)
    ) {
      errorMessage = "Min value cannot be greater than max value";
    } else if (
      name === "market_bookmaker_max_odds_rate" &&
      numericValue < Number(formData.market_bookmaker_min_odds_rate)
    ) {
      errorMessage = "Max value cannot be less than min value";
    }

  

    const parentChecks = [
      // {
      //   condition:
      //     name === "market_min_stack" &&
      //     numericValue > Number(formData.market_max_stack),
      //   tooltipMsg: `Min value cannot be greater than max value`,
      // },
      {
        condition: name === "market_max_stack" && numericValue > limitValidation?.market_max_stack_max_limit,
        tooltipMsg: `Market Max Stack value should not be greater than  ${limitValidation?.market_max_stack_max_limit}`,
      },
      {
        condition: name === "market_bookmaker_max_odds_rate" && numericValue > limitValidation?.market_bookmaker_max_odds_rate,
        tooltipMsg: `Market Bookmaker Max Odds Rate should not be greater than  ${limitValidation?.market_bookmaker_max_odds_rate}`,
      },
      {
        condition: name === "market_bookmaker_min_odds_rate" && numericValue < limitValidation?.market_bookmaker_min_odds_rate,
        tooltipMsg: `Market Bookmaker Min Odds Rate should not be less than  ${limitValidation?.market_bookmaker_min_odds_rate}`,
      },
      {
        condition: name === "market_max_odds_rate" && numericValue > limitValidation?.market_max_odds_rate,
        tooltipMsg: `Market Max Odds Rate value should not be greater than ${limitValidation?.market_max_odds_rate}`,
      },
      {
        condition: name === "market_min_odds_rate" && numericValue < limitValidation?.market_min_odds_rate,
        tooltipMsg: `Market Min Odds Rate value should not be less than ${limitValidation?.market_min_odds_rate}`,
      },
     
      {
        condition: name === "market_max_profit" && numericValue > limitValidation?.market_max_profit_max_limit,
        tooltipMsg: `Market Max Profit value should not be greater than ${limitValidation?.market_max_profit_max_limit}`,
      },
      {
        condition: name === "market_advance_bet_stake" && numericValue > limitValidation?.market_advance_bet_stake_max_limit,
        tooltipMsg: `Market advance bet stake value should not be greater than ${limitValidation?.market_advance_bet_stake_max_limit}`,
      },
      // {
      //   condition: name === "session_max_stack" && numericValue > limitValidation?.session_max_stack_max_limit,
      //   tooltipMsg: `Session Max Stack value should not be greater than ${limitValidation?.session_max_stack_max_limit}`,
      // },
      {
        condition:
          name === "session_max_profit" && Number(numericValue) > limitValidation?.session_max_profit_max_limit,
        tooltipMsg: `Match Bets Delay should not exceed ${limitValidation?.session_max_profit_max_limit}`,
      },
    ];

    parentChecks.forEach(({ condition, tooltipMsg }) => {
      if (condition) addTooltip(tooltipMsg);
    });

    setErrors((prevErrors:any) => {
      const newErrors = { ...prevErrors };
      if (errorMessage) {
        newErrors[name] = errorMessage;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });

    setTooltipMsg(newTooltipMsg);
    setShowTooltip(newShowTooltip);
  };