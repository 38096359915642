import TableContainer from "Common/TableContainer";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import snackbarUtil from "Utils/snackBarUtil";
import image from "../../assets/images/auth/maintenance.png";
import { authServices } from "Utils/auth/services";
import TableEntry from "Common/TableEntry";
import { ListPagination } from "Common/ListPagination";

interface Props {
  // bannerData: bannerPayload[];
  // getDeleted: (id: string) => Promise<void>;
  getAllDeposit: (filters?: any) => Promise<void>
}

const WithdrawTable = ({getAllDeposit}:Props) => {
  const userId = localStorage.getItem("userId");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [withdrawData, setWithdrawData] = useState<WithdrawData[]>([]);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const [showImage, setImage] = useState(false);
  const [showSetting, setSetting] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showRemark, setRemark] = useState("Accept");
  const [imageLink, setImageLink] = useState<string>("");
  const [selectedDeposit, setSelectedDeposit] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState("");
  const [username, setUsername] = useState<string>("");
  const [parentName, setParentName] = useState<string>("");
  const [amount, setAmount] = useState<string>("");

  const toggleImage = (image?: string) => {
    setImage(!showImage);
    setImageLink(image || "");
  };
  const toggleSetting = (id?: string) => {
    setSetting(!showSetting);
    setSelectedDeposit(id || "");
  };
  const toggleRemark = (label: any) => {
    setRemark(label);
  };

  const handleCopy = () => {
    setCopied(true);
    snackbarUtil.success("Copy Successfull");
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReason(event.target.value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleParentNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParentName(event.target.value);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const getPendingDeposit = async (filters: any = {}) => {
    const { response } = await authServices.getWithdrawDeposit({
      user_id: userId || "",
      status: "PROGRESS",
      search: {
        ...(username && { user_name: username }),
        ...(amount && { amount: amount }),
        ...(parentName && { parent_user_name: parentName }),
      },
      limit: limit,
      page: page,
      ...filters,
    });
    if (response?.status && response?.data?.data) {
      setWithdrawData(response?.data?.data);
      setListTotal({
        total: response?.data?.total / response?.data?.limit || 0,
        page: response?.data?.page || 1,
      });
    } else {
      setWithdrawData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  useEffect(() => {
    getPendingDeposit({});
    const intervalId = setInterval(() => {
      getPendingDeposit({});
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const submitFilter = () => {
    getPendingDeposit({
      search: {
        ...(username && { user_name: username }),
        ...(amount && { amount: amount }),
        ...(parentName && { parent_user_name: parentName }),
      },
    });
  };

  const clearFilter = () => {
    setUsername("");
    setAmount("");
    setParentName("");
    getPendingDeposit({
      search: {},
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getPendingDeposit({
      search: {
        ...(username && { user_name: username }),
        ...(amount && { amount: amount }),
        ...(parentName && { parent_user_name: parentName }),
      },
      page: page,
      limit: limit,
    });
  };

  const rejectDeposit = async () => {
    if (!selectedReason) {
      snackbarUtil.error("Please select Reason ");
      return;
    }
    const { response } = await authServices.rejectWithdraw({
      user_id: userId || "",
      remark: selectedReason,
      statement_id: selectedDeposit,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getAllDeposit({});
      getPendingDeposit();
      toggleSetting();
    } else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  const acceptDeposit = async () => {
    const { response } = await authServices.acceptWithdraw({
      crdr: 2,
      statement_id: selectedDeposit,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getPendingDeposit();
      toggleSetting();
      getAllDeposit({});
    } else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  function formatTimeDifference(targetDateTime: string): string {
    const now = new Date();
    const targetDate = new Date(targetDateTime);
    const diffInMs = Math.abs(targetDate.getTime() - now.getTime());

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return `${days} days, ${hours} hours, ${minutes} Min ${seconds} Sec`;
  }

  function formatDateTime(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
  }

  const deposit = useMemo(() => {
    if (!withdrawData || !Array.isArray(withdrawData)) {
      return [];
    }
    return withdrawData.map((items, index: number) => ({
      id: items?._id,
      username: items?.user_name,
      tDate: formatDateTime(items?.created_at),
      rAmount: items?.amount,
      crypto: "",
      bankName:
        (items?.payment_deatails[0]?.method_name?.toLowerCase()) != "bank"
          ? items?.payment_deatails[0]?.method_name
          : items?.payment_deatails[0]?.bank_name,
      holderName: items?.payment_deatails[0]?.bank_holder_name,
      accountNo:
        (items?.payment_deatails[0]?.method_name?.toLowerCase()) != "bank"
          ? items?.payment_deatails[0]?.others
          : items?.payment_deatails[0]?.account_no,
      status: items?.status,
      updatedTime: items?.created_at && formatTimeDifference(items?.created_at),
      ifsc: items?.payment_deatails[0]?.ifsc_code,
      method: items?.payment_deatails[0]?.method_name,
    }));
  }, [withdrawData]);


  const columns = useMemo(
    () => [
      {
        header: "Username",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>{cell?.row?.original?.username}</span>
              <CopyToClipboard
                text={cell?.row?.original?.username}
                onCopy={handleCopy}
              >
                <i className="ms-1 align-middle ri-file-copy-line cursor-pointer"></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Transaction Date",
        accessorKey: "tDate",
        enableColumnFilter: false,
      },
      {
        header: "Request Amount",
        accessorKey: "rAmount",
        enableColumnFilter: false,
      },
      {
        header: "Crypto",
        accessorKey: "crypto",
        enableColumnFilter: false,
      },
      {
        header: "Bank Name",
        accessorKey: "bankName",
        enableColumnFilter: false,
      },
      {
        header: "Holder Name",
        accessorKey: "holderName",
        enableColumnFilter: false,
      },
      {
        header: "IFSC",
        accessorKey: "ifsc",
        enableColumnFilter: false,
      },
      {
        header: "Account No.",
        accessorKey: "accountNo",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
      },
      {
        header: "Updated Time",
        accessorKey: "updatedTime",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button
                className="btn-sm ms-2"
                variant="subtle-dark"
                onClick={() =>
                  toggleSetting(
                    cell?.row?.original?.id
                  )
                }
              >
                <i className="fs-md align-middle ri-settings-4-line"></i>
              </Button>
              <CopyToClipboard
                text={`User Name: ${cell?.row?.original?.username},
Amount: ${cell?.row?.original?.rAmount},
${cell?.row?.original?.method != "Bank"
? `Name: ${cell?.row?.original?.holderName},
UPI / Number: ${cell?.row?.original?.accountNo} ` : `Bank Name: ${cell?.row?.original?.bankName},
Account Holder: ${cell?.row?.original?.holderName},
Account No.: ${cell?.row?.original?.accountNo},
IFSC: ${cell?.row?.original?.ifsc}`
                }`}
                onCopy={handleCopy}
              >
                <Button className="btn-sm ms-2" variant="subtle-info">
                  <i className="fs-md align-middle ri-file-copy-line"></i>
                </Button>
              </CopyToClipboard>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Card>
        <Card.Header className="border-bottom pb-3">
          <Row className="align-items-end g-3">
            <Col lg={3}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                placeholder="Enter Username"
                onChange={handleUsernameChange}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Parent Name</Form.Label>
              <Form.Control
                type="text"
                value={parentName}
                placeholder="Enter Parent Name"
                onChange={handleParentNameChange}
              />
            </Col>
            <Col lg={3}>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                value={amount}
                placeholder="Enter Amount"
                onChange={handleAmountChange}
              />
            </Col>
            <Col lg={3}>
              <Button className="me-2" onClick={submitFilter}>
                Search
              </Button>
              <Button variant="subtle-primary" onClick={clearFilter}>
                Clear
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <TableContainer
            columns={columns || []}
            data={deposit || []}
            isBordered={false}
            customPageSize={limit}
            isPagination={false}
            tableClass="table-centered align-middle table-nowrap table-striped mb-3"
            theadClass="text-muted table-light"
            SearchPlaceholder="Search Products..."
          />

          <nav aria-label="Page navigation" className="px-3 d-flex">
            <TableEntry
              limit={limit}
              handlelimitFilter={(value: number) =>
                handlePageFilter(page, value)
              }
            />
            <ListPagination
              listTotal={listTotal}
              handlePageFilter={(value: number) =>
                handlePageFilter(value, limit)
              }
            />
          </nav>
        </Card.Body>
      </Card>

      <Modal
        show={showImage}
        onHide={toggleImage}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold pb-2">
          <div>Screen Shot</div>
          <Button variant="light btn-sm" onClick={() => toggleImage()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-1">
          <img src={imageLink} alt="Image" className="w-100" />
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="subtle-dark" onClick={() => toggleImage()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSetting}
        onHide={toggleSetting}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold">
          Username
          <Button variant="light btn-sm" onClick={() => toggleSetting()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Button
              variant="subtle-success"
              className="d-flex flex-column py-3 w-25 mx-3 align-items-center"
              onClick={() => toggleRemark("Accept")}
            >
              <i
                className="ri-check-line align-middle"
                style={{ fontSize: "30px" }}
              ></i>
              <span className="fs-xl">Accept</span>
            </Button>
            <Button
              variant="subtle-danger"
              className="d-flex flex-column py-3 w-25 mx-3 align-items-center"
              onClick={() => toggleRemark("Reject")}
            >
              <i
                className="ri-close-line align-middle"
                style={{ fontSize: "30px" }}
              ></i>
              <span className="fs-xl">Reject</span>
            </Button>
          </div>
          <div className="mt-3 mx-auto w-75">
            {showRemark === "Reject" && (
              <div className="input-group">
                <div className="input-group-text">Remark</div>
                <Form.Select
                  value={selectedReason}
                  onChange={handleSelectChange}
                >
                  <option value="">Please Select</option>
                  <option value="Tech Glitch">Tech Glitch</option>
                  <option value="Reference Number Missing">
                    Reference Number Missing
                  </option>
                  <option value="Edited Payment Slip">
                    Edited Payment Slip
                  </option>
                  <option value="Invalid Bank">Invalid Bank</option>
                  <option value="Payment Late Attached / Old Payment">
                    Payment Late Attached / Old Payment
                  </option>
                  <option value="Payment Not Updated">
                    Payment Not Updated
                  </option>
                  <option value="Used in Same User">Used in Same User</option>
                  <option value="Used in Other User/Site">
                    Used in Other User/Site
                  </option>
                  <option value="UTR & Account Didn't Match">
                    UTR & Account Didn't Match
                  </option>
                  <option value="Insufficient Balance">
                    Insufficient Balance
                  </option>
                </Form.Select>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button
            onClick={() =>
              showRemark === "Reject" ? rejectDeposit() : acceptDeposit()
            }
          >
            Update
          </Button>
          <Button variant="subtle-dark" onClick={() => toggleSetting()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WithdrawTable;
