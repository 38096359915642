import { Badge, Button, Card, Col, Dropdown, Modal, Table } from "react-bootstrap";
import { useState } from "react";
import { settingServices } from "Utils/setting/services";
import moment from "moment";
import Loader from "Common/Loader";
import BeatLoader from "react-spinners/BeatLoader";

type TransactionData = {
  statementType: string;
  user_count: number;
  total_amount: number;
};


interface Props {
  data: any,
  fetchData: (days: number, query: string[]) => Promise<void>
  isLoading: Record<string, boolean>
}

const TransactionStatus = ({ data, fetchData, isLoading }: Props) => {

  const [filterName, setFilterName] = useState("");
  const [selectName, setSelectName] = useState("Today");
  const [showModal, setModal] = useState(false);
  const [listData, setListData] = useState<any>();
  const [nameModals, setNameModals] = useState("");
  const ToggleModal = (dataList: any, name: string, filter: string) => {
    setModal(!showModal);
    setListData(dataList);
    setNameModals(name);
    setFilterName(filter);
  }

  const handleDepositSelection = (days: number, name: string) => {
    fetchData(days, ["statementQuery"]);
    setSelectName(name)
  };

  const clickHandler = () => {
    setModal(!showModal)
  }

  return (
    <>
      {/* Deposit Section */}
      <Col xxl={4} className="position-relative">
        <Card className="card-height-100">
          <Card.Header className="d-flex border-bottom pb-3">
            <h5 className="card-title mb-0 flex-grow-1">Deposit Status
              <Badge onClick={() => ToggleModal(data?.data?.transactional_data, "Deposit Status", "DEPOSIT_REQUEST")} className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1">View All</Badge>
            </h5>
            <div className="flex-shrink-0">
              <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                  <span className="text-muted dropdown-title">{selectName}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                  <Dropdown.Item onClick={() => handleDepositSelection(1, "Today")}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDepositSelection(7, "This Week")}>This Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDepositSelection(365, "This Month")}>This Month</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
          <div className="card-body">
            <div className="table-responsive">
              <Table>
                <thead className="table-light">
                  <tr>
                    <th>Type</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.transactional_data?.length !== 0 ? (
                    data?.data?.transactional_data.map((item: any, index: number) => {
                      if (item?.statementType !== "DEPOSIT_REQUEST") return null
                      return (
                        <tr key={index}>
                          <td>{item?.status}</td>
                          <td className="text-end">{item?.total_amount?.toFixed(2)}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={2} className="text-center">No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
        {isLoading?.["statementQuery"] && <div className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ zIndex: '99999', background: 'rgba(255,255,255,.75)' }}>
          <BeatLoader />
        </div>}
      </Col>

      {/* Withdraw Section */}
      <Col xxl={4} className="position-relative">
        <Card className="card-height-100">
          <Card.Header className="d-flex border-bottom pb-3">
            <h5 className="card-title mb-0 flex-grow-1">Withdraw Status
              <Badge onClick={() => ToggleModal(data?.data?.transactional_data, "Withdraw Status", "WITHDRAW_REQUEST")} className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1">View All</Badge>
            </h5>
            <div className="flex-shrink-0">
              <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                  <span className="text-muted dropdown-title">{selectName}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                  <Dropdown.Item onClick={() => handleDepositSelection(1, "Today")}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDepositSelection(7, "This Week")}>This Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDepositSelection(365, "This Month")}>This Month</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
          <div className="card-body">
            <div className="table-responsive">
              <Table>
                <thead className="table-light">
                  <tr>
                    <th>Type</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.transactional_data?.length !== 0 ? (
                    data?.data?.transactional_data?.map((item: any, index: number) => {
                      if (item?.statementType !== "WITHDRAW_REQUEST") return null;
                      return (
                        <tr key={index}>
                          <td>{item?.status}</td>
                          <td className="text-end">{item?.total_amount?.toFixed(2)}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={2} className="text-center">No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
        {isLoading?.["statementQuery"] && <div className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ zIndex: '99999', background: 'rgba(255,255,255,.75)' }}>
          <BeatLoader />
        </div>}
      </Col>
      <Modal show={showModal} onHide={clickHandler} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>{nameModals}</span>
          <Button variant="light btn-sm" onClick={clickHandler}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className='fs-md'>
          <div className="table-resposive">
            <Table>
              <thead className="table-light">
                <tr>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  listData?.map((item: any) => {
                    if (item?.statementType !== filterName) return null;
                    return (
                      <tr >
                        <td>{item?.status}</td>
                        <td>{item?.total_amount?.toFixed(2)}</td>
                      </tr>
                    )
                  })
                }


              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionStatus;
