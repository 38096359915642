import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface BetHistoryTableProps {
  betHistoryData: BetHistoryList[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
  handleUser: (selectedOption: any) => void;
  userNameData: any[];
  userName: string;
  handleSelectUser: any;
  setUser_name: any;
  marketOptions: any[];
  selectedMarketId: any;
  handleMarketFilter: (e: any) => void;
  handleClearMarket: () => void;
  odds: string;
  stack: string;
  handleOddsStake: (e: any, name: string) => void;
  betType: string;
  handleBetChange: (e: any) => void;
  handleOddsChange:any;
  oddsRange: {
    from: number;
    to: number;
};
handleRateChange?:any;
rateRange?: {
  from: number;
  to: number;
}
}

const BetHistoryTable = ({
  betHistoryData,
  page,
  limit,
  listTotal,
  handlePageFilter,
  handleUser,
  userNameData,
  userName,
  handleSelectUser,
  setUser_name,
  marketOptions,
  selectedMarketId,
  handleMarketFilter,
  handleClearMarket,
  odds,
  stack,
  handleOddsStake,
  betType,
  handleBetChange,
  handleOddsChange,
  oddsRange
}: BetHistoryTableProps) => {
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const [showBetAddress, setBetAddress] = useState(null);
  const ToggleBetAddress = (betId: any) => {
    setBetAddress((prevBetId) => (prevBetId === betId ? null : betId));
  }

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setBetAddress(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

    const [fixedCountryData, setFixedCountryData] = useState<any>(null);
  

  const defaultTable = useMemo(() => {
    if (!betHistoryData || !Array.isArray(betHistoryData)) {
      return [];
    }
    return betHistoryData.map((items, index: number) => ({
      sNo: (page - 1) * limit + index + 1,
      date: items?.createdAt,
      sdomain: items?.domain_name || "",
      user_name: items?.user_name,
      sports: items?.sport_name,
      series: items?.series_name,
      market: items?.market_name,
      match: items?.match_name,
      selection: items?.selection_name,
      type: items?.is_back,
      rate: items?.odds,
      size: items?.size,
      isFancy: items?.is_fancy,
      stake: items?.stack,
      pnl: items?.p_l,
      betID: items?.bet_id,
      userId: items?.user_id,
      ipAddress: items?.ip_address,
      result: items?.winner_name,
      geolocation: items?.geolocation,
    }));
  }, [betHistoryData]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "sNo",
        enableColumnFilter: false,
      },
    
      {
        header: "Name",
        accessorKey: "user_name",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <span
              className="cursor-pointer text-info"
              onClick={() => {
                toggleList(cell?.row?.original?.user_name);
                getUserAgent(cell?.row?.original?.userId);
              }}
            >
              {cell?.row?.original?.user_name.replace(/\(\w+\)$/, '').trim()}
            </span>
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "OddsRate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.rate}
            </span>
          );
        },
      },
      {
        header: "Stake",
        accessorKey: "oddsStack",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.stake.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "P|L",
        accessorKey: "pnl",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`cursor-pointer text-${
                cell?.row?.original?.pnl < 0 ? "danger" : "secondary"
              }`}
            >
              {cell?.row?.original?.pnl.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "Market",
        accessorKey: "matchMarket",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">{cell?.row?.original?.market.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}</p>
            </div>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <div>
              <p
                className={`mb-0 btn-${
                  cell?.row?.original?.type ? "back" : "lay"
                } d-inline px-2 rounded-pill`}
              >
                {cell?.row?.original?.type ? "Back" : "Lay"}
              </p>
            </div>
          );
        },
      },
      {
        header: "Selection",
        accessorKey: "selection",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                <strong>{cell?.row?.original?.selection?.replace(/\((\d+_\d+|\d+\.\d+|\d+)\)$/, '').trim()}</strong>
              </p>
            </div>
          );
        },
      },
      
      {
        header: "Result",
        accessorKey: "result",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.result}
            </span>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "sdomain",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.sdomain}
            </span>
          );
        },
      },
      {
        header: "Placed Time",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="m-0">{moment(cell?.row?.original?.date).format("DD-MM-YY")}</p>
              <p className="m-0">{moment(cell?.row?.original?.date).format("hh:mm:ss:SSS A")}</p>
              
            </div>
          );
        },
      },
      // {
      //   header: "BetId",
      //   accessorKey: "betID",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => {
      //     return (
      //       <span className="cursor-pointer text-info">
      //         {cell?.row?.original?.betID}
      //       </span>
      //     );
      //   },
      // },
      {
        header: "IP Address",
        accessorKey: "ip",
        enableColumnFilter: false,
        cell: (cell: any) => {
          
            const handleMouseEnter = () => {
              if (!fixedCountryData) {
                const countryData =
                  cell?.row?.original?.geolocation &&
                  (typeof cell.row.original.geolocation === "string"
                    ? JSON.parse(cell.row.original.geolocation)
                    : cell.row.original.geolocation);
  
                setFixedCountryData(countryData);
                setBetAddress(cell?.row?.original?.betID);
              }
            };
  
            const handleMouseLeave = () => {
              setFixedCountryData(null);
            };
          return (

            <div className="position-relative">
            <span className="text-info">
              <i onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave} 
              className="bi bi-globe me-1 cursor-pointer" style={{ verticalAlign: "middle" }}></i>
              {cell?.row?.original?.ipAddress}
            </span>
            { fixedCountryData && showBetAddress === cell?.row?.original?.betID  && (
                <div ref={tooltipRef} className="bg-white p-3 rounded position-absolute" style={{ right: '20px', minWidth:"200px", boxShadow:"0px 8px 16px 0px rgba(0,0,0,0.2)", zIndex: 99 }}>
                  <div className="d-flex justify-content-between align-item-center mb-1">
                    <p className="fw-bold m-0">City : </p>
                    <p className="p-0 m-0"> {fixedCountryData.city}</p>
                  </div>
                  <div className="d-flex justify-content-between p-0 align-item-center mb-1">
                    <p className="fw-bold m-0">State :{" "} </p>
                    <p className="m-0"> {fixedCountryData.state}</p>
                  </div>
                  <div className="d-flex justify-content-between p-0 align-item-center">
                    <p className="fw-bold m-0">Country : </p>
                    <p className="m-0"> {fixedCountryData.country}</p>
                  </div>
                </div>
              )}
            </div>
            )
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Button
              variant="outline-warning"
              className="me-2 btn-sm "
              style={{ height: "38px" }}
            >
              <i className="fs-lg align-middle bi-check"></i>
            </Button>
          );
        },
      },
    ],
    [showBetAddress, defaultTable, fixedCountryData]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        isFilter={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        handleUser={handleUser}
        userNameData={userNameData}
        userName={userName}
        handleSelectUser={handleSelectUser}
        setUser_name={setUser_name}
        marketOptions={marketOptions}
        selectedMarketId={selectedMarketId}
        handleMarketFilter={handleMarketFilter}
        handleClearMarket={handleClearMarket}
        odds={odds}
        stack={stack}
        handleOddsStake={handleOddsStake}
        betType={betType}
        handleBetChange={handleBetChange}
        sorting={true}
        handleOddsChange={handleOddsChange}
        oddsRange={oddsRange}
      />
      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.map((item) => {
            return (
              <p>{item.name} ({item.user_name})</p>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BetHistoryTable;
